import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import CircleImg from '../../cicleImage/RoundProductBox';
import style from '../../../styles/footerlinks.module.css';

const MobileProductSlider = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        arrows: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 400,
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
            }
        }]
    };
    return (
        <>
            <div className='mobile-slider-products'>
                <Slider {...settings}>
                    {props.category && props.category.map((data) => (
                        <Link to="/our-products" state={{ data: data.id }}>
                            <div className={style.circleImgCont}>
                                <CircleImg data={data.image} name={data.name && data.name} />
                            </div>
                        </Link>
                    ))}
                </Slider>
            </div>
        </>
    )
}

export default MobileProductSlider;