import React,{useState, useEffect} from 'react';
import style from '../../../styles/footerlinks.module.css';
import axios from 'axios';
import { BASE_URL } from '../../../config';

function TermsAndConditions(){

  const [listing, setListing] = useState([])

  useEffect(() => {
    fetchListing();
  },[])

  const fetchListing = async() => {
    await axios.get(BASE_URL+'/api/policy/listing_policy').then(({data}) => {
      setListing(data.data)
    })
  }

    return(
        <div>
            <div className='col-md-12 col-xs-12 nopadding'>
            <nav aria-label="breadcrumb" >
          <ol className='breadcrumb'>
          <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li className="breadcrumb-item-active" aria-current="page">{listing.title && listing.title}</li>
          </ol>
        </nav>
                </div>
                <br /><br />
        <div className={style.padding30}>
            <h1 className={style.heading}>{listing.title && listing.title} </h1> <br /><br />
            <div dangerouslySetInnerHTML={{__html: listing.content && listing.content}}></div>
        </div>
        </div>
    )
}

export default TermsAndConditions;