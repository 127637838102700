import React, { useState, useEffect,useRef } from 'react';
import ProductBox from '../../ProductBox/itemBox';
import ImageSlider from '../../TopCategorySlider';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import style from '../../../styles/categorybox.module.css'
import { Link } from 'react-router-dom';
import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, EffectCube } from 'swiper';
import 'swiper/swiper-bundle.min.css';
function RecentSearches() {

    const [category, setCategory] = useState([]);

    useEffect(() => {
        fetchCategory();

    }, [])

    const fetchCategory = async () => {
        await axios.get(BASE_URL + '/api/product-categories').then(({ data }) => {
            setCategory(data.data);
        })
    }


    return (
        <>        
        <ImageSlider name="Top" boldname="Category">
        {category.map((data, i) => (
  <Link to="/our-products" state={{ data: data.id }} key={i}>
    <div className={`col-md-3 col-sm-6 col-xs-12 ${style.box}`}>
      <div className={style.boxHeight}>
        <div className={style.imageContainer}>
          <img className={style.boxHeight} src={`${BASE_URL}/storage/${data.image}`} alt={data.name} />
          <div className={style.overlay}></div> {/* Overlay Layer */}
        </div>
        <div className={style.heading2}>{data.name}</div>
      </div>
    </div>
  </Link>
))}

        </ImageSlider>
        </>
    )
}

export default RecentSearches;