import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import style from '../../styles/headerSearch.module.css';
import { BASE_URL } from "../../config";
import { useNavigate } from "react-router-dom";

const searchFilter = (searchValue, list, searchBy = 'name') => {
    let lowerCaseQuery = searchValue.toLowerCase();
    let filteredList = searchValue
        ? list.filter(x => x[searchBy].toLowerCase().includes(lowerCaseQuery))
        : list;
    return filteredList;
};




const DropdownItems = () => {

    useEffect(() => {
        fetchProduct()
    },[])
    const [visible, setVisible] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const dropdownRef = useRef(null);
    const [list, setList] = useState([]);
    const navigate = useNavigate();

    const fetchProduct = async () => {
        await axios.get(BASE_URL + '/api/manage-product-with-category/0').then(({ data }) => {
          setList(data.data);
        //   console.log(data.data)
        })
      }
    

    // click away listener
    useEffect(() => {
        document.addEventListener("mousedown", handleClick, false);
        return () => document.removeEventListener("mousedown", handleClick, false);
    }, []);

    const handleClick = (e) => {
        if (dropdownRef.current.contains(e.target)) {
            return;
        }
        setVisible(false);
    };

    const handleChange = (e) => {
        setSearchValue(e.target.value);
        if (!visible) {
            setVisible(true);
        }
    };

    const selectItem = (item) => {
        setSearchValue(item.name);
        setVisible(false);
    };

    return (
        <div >
            <div className={style.SearchBar}>
                <input placeholder="Enter product to search..."
                    className={style.Search}
                    value={searchValue}
                    onChange={handleChange}
                    onClick={() => {

                        setVisible(true);

                    }} />
                {/* <button className={style.searchButton}>
                    <img src="/Icons/SearchIcon.png" className={style.SearchIcon} />
                </button> */}
            </div>
            <div ref={dropdownRef} className={`dropdownX ${visible ? style.v : ""}`}>
                {visible && (
                    <ul>
                        {!list && (
                            <li key="zxc" className={style.dropdown_item}>
                                no result
                            </li>
                        )}
                        {list &&
                            searchFilter(searchValue, list).map((data) => (
                                <li
                                    key={data.id}
                                    className={style.dropdown_item}
                                >
                                 <a href={'/product-details/' + data.slug}>   <div className="item_text1" >{data.name}</div> </a>
                                </li>
                            ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default DropdownItems;
