import React, { useState, useEffect } from "react";
import '../../../styles/Address.css';
import AddIcon from '@mui/icons-material/Add';
import Modal from 'react-bootstrap/Modal';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { BASE_URL } from "../../../config";
import Sidebar from '../../Sidebar/Sidebar';
import AddressModal from "../PaymentSelection/AddressModal";
import { Navigate } from "react-router-dom";
import AddressModalMobile from '../PaymentSelection/AddAdressMobile'
import EditBillingAddress from "./EditBillingAddress";
import EditSShippingAddress from "./EditShippingAddress";


export default function MyAddress() {
    const [open, setOpen] = useState(false);
    const [openBilling, setOpenBilling] = useState(false);
    const [openShipping, setOpenShipping] = useState(false);
    const [openMobile, setOpenMobile] = useState(false);
    const [billdata, setbilldata] = useState("");
    const [shipdata, setshipdata] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpenBilling = () => setOpenBilling(true);
    const handleCloseBilling = () => setOpenBilling(false);

    const handleOpenShipping = () => setOpenShipping(true);
    const handleCloseShipping = () => setOpenShipping(false);

    const [billingShow, setBillingShow] = useState(false);

    const token = localStorage.getItem('token')
    const userData = JSON.parse(localStorage.getItem('userdata'))
    console.log(userData)
    const [shippingAddress, setShippingAddress] = useState();
    const [billingAddress, setBillingAddress] = useState()

    useEffect(() => {
        Address();
    }, [openShipping,openBilling])

    const Address = async () => {
        await axios.get(BASE_URL + '/api/customer-address', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setShippingAddress(data.data.shipping);
            setBillingAddress(data.data.billing)
        })
    }


    return (
        <div className="col-md-12 col-xs-12 nopadding">
            <div className='col-md-12 col-xs-12 nopadding'>
                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">

                            My Address

                        </li>
                    </ol>
                </nav>
            </div>
            <div className={`col-md-3 col-xs-12 nopadding `}>
                <Sidebar />
            </div>
            <div className="wishCon col-md-9 col-xs-12 col-sm-12">
                <div className="col-md-12 col-xs-12 nopadding header">
                    <div className="heading dash-address">
                        <h3>My Address</h3>
                        <button className='addAddress flex hover hiddenMobile' onClick={handleOpen}><AddIcon /> ADD ADDRESS</button>
                        <button className='addAddress flex hover hiddenDesktop' onClick={() => setOpenMobile(true)}><AddIcon /> ADD ADDRESS</button>
                    </div>
                </div>
                <div className="col-md-12 col-xs-12 marginTop marginBottom nopadding-mobile">
                    <div className="col-md-6 col-xs-12 nopadding">
                        <div className="edit-billing-address">
                            <div className="address-head">Billing Address</div>
                            {/* <i class="fa fa-pencil-square-o" aria-hidden="true" 
                            onClick={handleOpenBilling}></i> */}
                        </div>
                        {billingAddress && billingAddress.map((data,i) => (
                            <div className="address-details  mt-2" key={i}>
                                <i class="fa fa-pencil-square-o" aria-hidden="true"
                                    onClick={()=>{handleOpenBilling();setbilldata(data)}} style={{float: "right", cursor: "pointer"}}></i>
                                <ul>
                                    <li className="my-address-content">
                                        <div className="mt-1">Name: </div>
                                        <div className="textSecondary marginLeft  mt-1">{data.name && data.name}</div>
                                    </li>
                                    <li className="my-address-content">
                                        <div className="mt-1">Address Type: </div>
                                        <div className="textSecondary marginLeft mt-1">{data.address_type && data.address_type}</div>
                                    </li>
                                    <li className="my-address-content">
                                        <div className="mt-1">Address:</div>
                                        <div className="textSecondary marginLeft mt-1">{data.address && data.address}</div>
                                    </li>
                                    <li className="my-address-content">
                                        <div className="mt-1">Country: </div>
                                        <div className="textSecondary marginLeft mt-1">{data.country.name && data.country.name}</div>
                                    </li>
                                    <li className="my-address-content">
                                        <div className="mt-1">State: </div>
                                        <div className="textSecondary marginLeft mt-1">{data.state.name && data.state.name}</div>
                                    </li>
                                    <li className="my-address-content">
                                        <div className="mt-1">City: </div>
                                        <div className="textSecondary marginLeft mt-1">{data.city.name && data.city.name}</div>
                                    </li>
                                    <li className="my-address-content">
                                        <div className="mt-1">Zip Code: </div>
                                        <div className="textSecondary marginLeft mt-1">{data.pincode && data.pincode}</div>
                                    </li>
                                </ul>
                            </div>
                        ))}
                    </div>


                    <div className="col-md-6 col-xs-12 nopadding-mobile mobile-mt-2">
                        <div className="edit-billing-address">
                            <div className="address-head">Shipping Address</div>
                            {/* <i class="fa fa-pencil-square-o" aria-hidden="true" onClick={handleOpenShipping}></i> */}
                        </div>
                        {shippingAddress && shippingAddress.map((data,i) => (
                            <div className="address-details mt-2" key={i}>
                                 <i class="fa fa-pencil-square-o" aria-hidden="true" onClick={()=>{handleOpenShipping();setshipdata(data)}} style={{float: "right", cursor: "pointer"}}></i>
                                <ul>
                                    <li className="my-address-content">
                                        <div className="mt-1">Name: </div>
                                        <div className="textSecondary marginLeft  mt-1">{data.name && data.name}</div>
                                    </li>
                                    <li className="my-address-content">
                                        <div className="mt-1">Address Type: </div>
                                        <div className="textSecondary marginLeft mt-1">{data.address_type && data.address_type}</div>
                                    </li>
                                    <li className="my-address-content">
                                        <div className="mt-1">Address:</div>
                                        <div className="textSecondary marginLeft mt-1">{data.address && data.address}</div>
                                    </li>
                                    <li className="my-address-content">
                                        <div className="mt-1">Country: </div>
                                        <div className="textSecondary marginLeft mt-1">{data.country.name && data.country.name}</div>
                                    </li>
                                    <li className="my-address-content">
                                        <div className="mt-1">State: </div>
                                        <div className="textSecondary marginLeft mt-1">{data.state.name && data.state.name}</div>
                                    </li>
                                    <li className="my-address-content">
                                        <div className="mt-1">City: </div>
                                        <div className="textSecondary marginLeft mt-1">{data.city.name && data.city.name}</div>
                                    </li>
                                    <li className="my-address-content">
                                        <div className="mt-1">Zip Code: </div>
                                        <div className="textSecondary marginLeft mt-1">{data.pincode && data.pincode}</div>
                                    </li>
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div>
                <Modal show={billingShow} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
                    <Modal.Footer>
                        {/* <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            Save Changes
                        </Button> */}
                    </Modal.Footer>
                </Modal>
            </div>
            {openShipping &&(<EditSShippingAddress showAdd={openShipping} data={shipdata} setShowAdd={setOpenShipping} />)

            }
            {openBilling &&(<EditBillingAddress showAdd={openBilling} data={billdata} setShowAdd={setOpenBilling} />)}
            
            
            <AddressModal showAdd={open} setShowAdd={setOpen} />
            <AddressModalMobile showAdd={openMobile} setShowAdd={setOpenMobile} />
        </div>
    )
}