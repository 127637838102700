import React,{useState,useEffect} from 'react';
import { Form, Button, Modal, FormCheck } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../../../config';
const EditSShippingAddress = (props) => {
    const data = props.data;
    const [countrys, setCountrys] = useState([]);
    const [states, setStates] = useState([]);
    const [citys, setCitys] = useState([]);
    const [name, setname] = useState(data && data.name);
    const [address, setAddress] = useState(data && data.address);
    const [city, setCity] = useState(data && data.city.id);
    const [state, setState] = useState(data && data.state.id);
    const [country, setCountry] = useState(data && data.country.id);
    const [zipcode, setZipcode] = useState(data && data.pincode);
    const [addresstype, setAddressType] = useState(data && data.address_type);
    const [phone, setPhone] = useState(data && data.mobile_number);
    const [emailId, setEmailId] = useState(data && data.email);
    const [error, setError] = useState([]);
    const token = localStorage.getItem('token');
    const handleClose = () => {
        props.setShowAdd(false);
    }
    useEffect(()=>{
        fetchCountry();
        getState(data && data.country && data.country.id);
        getCity(data && data.state && data.state.id);
        
    },[data])
    
    const fetchCountry = async () => {
        await axios.get(BASE_URL+'/api/country').then(({ data }) => {
          setCountrys(data.data)
        })
      }
      async function getState(id) {
        await axios.get(BASE_URL+'/api/state/'+id).then(({ data }) => {
          setStates(data.data)
        })
      }
      async function getCity(id) {
        await axios.get(BASE_URL+'/api/city/'+id).then(({ data }) => {
          setCitys(data.data)
        })
      }
      const handlSubmit = async (e) => {
        e.preventDefault();
        setError('');
        await axios.put(BASE_URL + '/api/update-customer-shipping-address/'+data.id, {
          'name': name,
          'address': address,
          'city': city,
          'state': state,
          'country': country,
          'pincode': zipcode,
          'address_type': addresstype,
          'mobile_number': phone,
          'email': emailId,
          
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        }).then(({ data }) => {
    
          if (data.status === 401) {
           
          }
          if (data.status === "success") {
           
            props.setShowAdd(false);
    
          }
        }).catch(({ response }) => {
          if (response.data.status_code === 422) {
            setError(response.data.message)
          }
        })
      }
    return (
        <>
            <Modal onHide={handleClose} className="edit-modal" show={props.showAdd} animation={false} centered>
                <Modal.Header>
                    <h4 className="modal-title billing-address-head">Shipping Address</h4>    
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <input className="input mt-2" placeholder="Name" value={name} onChange={(e)=>setname(e.target.value)}/>
                        {error.name && (
                        <span className="text-danger">{error.name}</span>
                        )}
                    </div>
                    <div>
                        <input className="input mt-2" placeholder="Mobile Number" value={phone} onChange={(e)=>setPhone(e.target.value)}/>
                        {error.mobile_number && (
                        <span className="text-danger">{error.mobile_number}</span>
                        )}
                    </div>
                    <div>
                        <input className="input mt-2" placeholder="Email" value={emailId} onChange={(e)=>setEmailId(e.target.value)}/>
                        {error.email && (
                        <span className="text-danger">{error.email}</span>
                        )}
                    </div>
                    <div className="mt-2">
                        <select className="input mt-2" placeholder="Select Address Type" value={addresstype} onChange={(e) => setAddressType(e.target.value)}>
                        <option value="">Select Business Type</option>
                        <option value="home">Home</option>
                        <option value="office">Office</option>
                        </select>
                        {error.address_type && (
                        <span className="text-danger">{error.address_type}</span>
                        )}
                    </div>
                    <div className='mt-2'>
                        <input className="input mt-2" placeholder="Address" value={address} onChange={(e)=>setAddress(e.target.value)} />
                        {error.address && (
                        <span className="text-danger">{error.address}</span>
                        )}
                    </div>
                  
                    <select className="width20 input mt-2" value={country} placeholder="Select Country" onChange={(e) => { setCountry(e.target.value); getState(e.target.value) }}>
                        <option value="">Select Country</option>
                        {countrys && countrys.map((data) => (
                        <option value={data.id}>{data.name}</option>
                        ))}
                    </select>
                    {error.country && (
                        <span className="text-danger">{error.country}</span>
                        )}
                    <select className="width20 input mt-2" value={state} placeholder="Select State"  onChange={(e) => { getCity(e.target.value); setState(e.target.value) }}>
                    <option valu="">Select State</option>
                        {states && states.map((data) => (
                        <option value={data.id}>{data.name}</option>
                        ))}
                    </select>
                    {error.state && (
                        <span className="text-danger">{error.state}</span>
                        )}
                    <select className="width20 input mt-2" placeholder="Select City" value={city} onChange={(e) => setCity(e.target.value)}>
                    <option value="">Select City</option>
                        {citys && citys.map((data) => (
                        <option value={data.id}>{data.name}</option>
                        ))}
                    </select>
                    {error.city && (
                        <span className="text-danger">{error.city}</span>
                        )}
                    <div>
                        <input className="input mt-2" value={zipcode} placeholder="Zip code" onChange={(e)=>setZipcode(e.target.value)}/>
                        {error.pincode && (
                        <span className="text-danger">{error.pincode}</span>
                        )}
                    </div>

                </Modal.Body>
                <Modal.Footer className=" mb-2">
                    <Button variant="danger" type="submit" className="mt-2 hover" onClick={handlSubmit}>
                        Update Address
                    </Button>
                    <Button variant="danger" onClick={() => props.setShowAdd(false)} className="mt-2 hover">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditSShippingAddress;