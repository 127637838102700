import React from 'react';
import ProductBox from '../../ProductBox/itemBox';
import ImageSlider from '../../SliderwithoutMenu';

const data = [
    {id:1},{id:2},{id:3},{id:4},{id:5},{id:6},{id:7},{id:8},{id:9},{id:10},{id:11},{id:12},{id:13},{id:14},
]

function RecentSearches() {
    return (
        <ImageSlider name="Recent" boldname="Searches">
            {data.map(i => (
                <div className="col-md-3 col-xs-12">
                <ProductBox />
            </div>
            ))}
        </ImageSlider>
    )
}

export default RecentSearches;