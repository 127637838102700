import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import '../../styles/blogpage.css'
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { VscAccount } from "react-icons/vsc";
import { IoHomeOutline } from "react-icons/io5";
import { AiOutlineShopping } from "react-icons/ai";
import { AiOutlineProfile } from "react-icons/ai";
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation

export default function LabelBottomNavigation() {
    const [value, setValue] = React.useState('recents');
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='bottom-navigation'>
            <BottomNavigation sx={{ width: '100%',height:80 }} value={value} onChange={handleChange}>
                <BottomNavigationAction
                className="profile-action" 
                    label="Home"
                    value="home"
                    icon={<IoHomeOutline style={{ fontWeight: "600", fontSize: "28px" }} />}
                    sx={{ '.MuiBottomNavigationAction-label': { fontSize: '18px', fontWeight: 'bold',marginTop:"8px"  } }}
                    onClick={() => navigate('/')}
                />
                <BottomNavigationAction
                className="profile-action" 
                    label="Shop"
                    value="shopping"
                    icon={<AiOutlineShopping style={{ fontWeight: "600", fontSize: "28px" }} />}
                    sx={{ '.MuiBottomNavigationAction-label': { fontSize: '18px', fontWeight: 'bold',marginTop:"8px"  } }}
                    onClick={() => navigate('/our-products')}
                />
                {/* <BottomNavigationAction
                    label="Company"
                    value="profile"
                    icon={<AiOutlineProfile style={{ fontWeight: "600", fontSize: "20px", color: "gray" }} />}
                    sx={{ '.MuiBottomNavigationAction-label': { fontSize: '12px', fontWeight: 'bold' } }}
                    onClick={() => navigate('/company-profile')} // Replace with your desired route
                /> */}
                <BottomNavigationAction
                className="profile-action" 
                    label="Account"
                    value="account"
                    icon={<VscAccount style={{ fontWeight: "600", fontSize: "28px" }} />}
                    sx={{ '.MuiBottomNavigationAction-label': { fontSize: '18px', fontWeight: 'bold', marginTop:"8px" } }}
                    onClick={() => navigate('/settings')}
                />
                <BottomNavigationAction
                className="profile-action" 
                    label="Cart"
                    value="cart"
                    icon={<MdOutlineAddShoppingCart style={{ fontWeight: "600", fontSize: "28px" }} />}
                    sx={{ '.MuiBottomNavigationAction-label': { fontSize: '18px', fontWeight: 'bold',marginTop:"8px"  } }}
                    onClick={() => navigate('/cart')}
                />
            </BottomNavigation>
        </div>
    );
}
