import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import style from '../styles/ProductDetails.module.css';

export default function DetailTab({ Info, ShippingInfo, Discription, Reviews }) {
    const [tabvalue, setTabValue] = useState(0);

    const handleTabsChange = (event, newValue) => {
        setTabValue(newValue);
    };
    return (
        <div className="App">

            <Tabs
                onChange={handleTabsChange}
                value={tabvalue}
                indicatorColor="red"
                textColor="secondary"
                sx={{
                    '& .MuiTabs-flexContainer': {
                        flexWrap: 'wrap',
                    },
                }}
                className={`bold ${style.borderBottom}`}
            >
                <Tab className="tab-headings" label="Description" sx={{ fontSize: 14, fontWeight: 'bold' }} />
                <Tab className="tab-headings" label="Additional Information" sx={{ fontSize: 14, fontWeight: 'bold' }} />
                <Tab className="tab-headings" label="Shipping & Returns" sx={{ fontSize: 14, fontWeight: 'bold' }} />
                <Tab className="tab-headings" label="Reviews" sx={{ fontSize: 14, fontWeight: 'bold' }} />
            </Tabs>

            {tabvalue === 0 && <div className={style.TabContent}>{Discription}</div>}
            {tabvalue === 1 && <div className={style.TabContent}>{Info}</div>}
            {tabvalue === 2 && <div className={style.TabContent}>{ShippingInfo}</div>}
            {tabvalue === 3 && <div className={style.TabContent}>{Reviews}</div>}
        </div>
    );
}

