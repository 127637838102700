import React, { useState, useEffect, useContext } from "react";
import style from "../../../styles/Cart.module.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Checkbox from "@mui/material/Checkbox";
import { useHref, useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../config";
import axios from "axios";
import AddressModal from "./AddressModal";
import { Button, Card, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import useRazorpay from "react-razorpay";
import { UserContext } from "../../../App";
import EditBillingAddress from "../MyAddress/EditBillingAddress";
import EditSShippingAddress from "../MyAddress/EditShippingAddress";
export function AddItem(props) {
  const { handleInc, handleDec, counter } = props;

  return (
    <div>
      <div className={`flex ${style.counter}`}>
        <div
          className={`margin-left-right10 ${style.font}`}
          onClick={handleDec}
        >
          -
        </div>
        <div>{counter}</div>
        <div
          className={`margin-left-right10 ${style.font}`}
          onClick={handleInc}
        >
          +
        </div>
      </div>
    </div>
  );
}

export default function Checkout() {
  const [counter, setCounter] = useState(1);
  const price = 84.0;
  const { item, setItem } = useContext(UserContext);
  const [prize, setPrize] = useState(price);
  const user = JSON.parse(localStorage.getItem("userdata"))
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const token = localStorage.getItem("token");
  let machineId = localStorage.getItem("MachineId");
  const [cartDataOff, setCartDataOff] = useState([]);
  const [cartShipping, setCartShipping] = useState([]);
  const [cartShippingOff, setCartOffShipping] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [billingAddress, setBillingAddress] = useState([]);
  const [shippingAddressdata, setShippingAddressdata] = useState("");
  const [billingAddressdata, setBillingAddressdata] = useState("");
  const [billingId, setBillingId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const history = useHref();
  const [error, setError] = useState();
  const [shippingId, setShippingId] = useState();
  const [payment, setPayment] = useState('');
  const [bookingDetails, setBookingDetails] = useState();
  const [shippingData, setShippingData] = useState([]);
  const [pin, setPin] = useState("");
  const [bilpin, setBilPin] = useState("");
  const [razorpaydata, setrazorpaydata] = useState("");
  const [paymentid, setpaymentid] = useState("");
  const [refrenceid, setrefrenceid] = useState("");
  const [paymentimage, setpaymentimage] = useState("");
  const [bankaccount, setbankaccount] = useState("");
  const [purl, setpurl] = useState("");
  const [iscountryindia, setiscountryindia] = useState(true);

  const date = new Date().toLocaleDateString();
  const time = new Date().toLocaleTimeString();

  const Razorpay = useRazorpay();
  const { state } = useLocation();
  const [openBilling, setOpenBilling] = useState(false);
  const [openShipping, setOpenShipping] = useState(false);
  const [billdata, setbilldata] = useState("");
  const [shipdata, setshipdata] = useState("");
  const [shippingCost, setShippingCost] = useState();
  // console.log(state);

  useEffect(() => {
    FetchItems();
    FetchItemsUnAuth();
    getMachineId();
    FetchCustomerAddress();
    fetchrazorpaydata();
    fetchbankaccountdata();
  }, [open, openBilling, openShipping]);
  const fetchrazorpaydata = async () => {
    // console.log("data")
    axios.get(BASE_URL + "/api/razorpaydata").then(({ data }) => {
      // console.log("data")
      setrazorpaydata(data.data);
    });
  };
  const fetchbankaccountdata = async () => {
    // console.log("data")
    axios.get(BASE_URL + "/api/bankaccount").then(({ data }) => {
      // console.log("data")
      setbankaccount(data.data);
    });
  };
  function getMachineId() {
    let machineId = localStorage.getItem("MachineId");

    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem("MachineId", machineId);
    }
  }

  const BookOrder = () => {
    navigate("/thankyou", {
      state: {
        orderId: bookingDetails.order_id ? bookingDetails.order_id : "",
        contact: bookingDetails.mobile_number
          ? bookingDetails.mobile_number
          : "",
        delivery: bookingDetails.delivery_day
          ? bookingDetails.delivery_day
          : "",
      },
    });
  };

  const FetchItems = async () => {
    // console.log("data")
    axios
      .get(BASE_URL + "/api/get-cart", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        // console.log("data")
        CheckShoppingbyDeliveryn(data.data.cart.quantity, data.data.cart.total_price_after_discount)
        setCartData(data.data.cart_details);
        setCartShipping(data.data.cart);
      });
  };

  const FetchItemsUnAuth = async () => {
    const data = { device_id: machineId };
    await axios
      .post(BASE_URL + "/api/get-cart-unauth", data)
      .then(({ data }) => {
        setCartDataOff(data.data.cart_details);
        setCartOffShipping(data.data.cart);
      });
  };

  const FetchCustomerAddress = async () => {
    axios
      .get(BASE_URL + "/api/customer-address", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setShippingAddress(data.data.shipping);
        if (data.data.billing) {
          setBillingId(data.data.billing[0].id)
          setBillingAddressdata(data.data.billing[0])
        }
        if (data.data.shipping) {
          setShippingId(data.data.shipping[0].id)
          setShippingAddressdata(data.data.shipping[0])
          if (data && data.data && data.data.shipping[0] && data.data.shipping[0].country.name != "India") {
            setiscountryindia(false)
          }
        }
        setBillingAddress(data.data.billing);
      });
  };

  const handlepaymentimage = e => {
    setpaymentimage(e.target.files[0])
  }
  const handlepayment = () => {
    axios
      .post(
        BASE_URL + "/api/submit-order",
        {
          billing_id: billingId,
          shipping_id: shippingId,
          shipping_type: shippingCost.id,
          payment_mode: payment,
          paymentid: paymentid,
          refrence_id: refrenceid,
          iscountryindia: iscountryindia,
          payment_image: paymentimage
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            'Accept': 'application/json',
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(({ data }) => {
        setItem(0)

        // setBookingDetails(data.data);
        if (data.success == true) {
          // alert(true)
          // BookOrder();
          setError("");
          // Swal.fire({
          //   text: data.message,
          //   icon: "success",
          // });
          if (payment == "online") {
            ccavenuerequest(data.data.order_id)
          } else {
            navigate("/thankyou/" + data.data.ordersid);
          }

          // if (payment != "online") {
          //   navigate("/thankyou/" + data.data.ordersid);
          // }
          // navigate("/thankyou-order")

          // document.getElementById("otherService").reset();
        }
        if (data.success === false) {
          // BookOrder();
          Swal.fire({
            text: data.messsge,
            icon: "error",
          });
        }
      })
      .catch(({ response }) => {
        // setError(response.data.errors);
        if (response && response.data && response.data.code === 422) {
          setError(response.data.errors);
        }
      });
  }
  function ProceedPayment() {
    setError("");
    // if (payment == "online") {
    //   const options = {
    //     key: razorpaydata.key, // Enter the Key ID generated from the Dashboard
    //     amount:
    //       parseInt(
    //         Number(state.cartamount && state.cartamount) -
    //          Number(state.promoDiscount && state.promoDiscount) +
    //           Number(state.shippingAmount && state.shippingAmount) +
    //           Number(state.gstAmount && state.gstAmount)
    //       ) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    //     currency: "INR",
    //     name: "izharsonperfumers",
    //     description: "Test Transaction",
    //     image:
    //       "https://izharsonperfumers.com/admin-login/storage/logo/P2b87EM16aFzSq4eWefyzMZi91Ryq6ArS6KQKDTz.jpg",
    //     //   order_id: "yuuyuy", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
    //     handler: function (response) {

    //       setpaymentid(response.razorpay_payment_id);
    //       handlepayment();
    //       console.log(response);
    //       // alert(response.razorpay_payment_id);
    //       // alert(response.razorpay_order_id);
    //       // alert(response.razorpay_signature);
    //     },
    //     prefill: {
    //       name: "",
    //       email: "",
    //       contact: "",
    //     },
    //     notes: {
    //       address: "",
    //     },
    //     theme: {
    //       color: "#3399cc",
    //     },
    //   };
    //   const rzp1 = new Razorpay(options);

    //   rzp1.on("payment.failed", function (response) {
    //     console.log(response);
    //     // alert(response.error.code);
    //     // alert(response.error.description);
    //     // alert(response.error.source);
    //     // alert(response.error.step);
    //     // alert(response.error.reason);
    //     // alert(response.error.metadata.order_id);
    //     // alert(response.error.metadata.payment_id);
    //   });

    //   rzp1.open();
    // }
    // if (payment == "online") {
    // ccavenuerequest();
    handlepayment();
    // }
    // if (payment == "offline" || payment == "cod" || payment == "") {
    //   handlepayment();
    // }

  }

  const ccavenuerequest = async (id) => {
    var d = new Date().getTime();
    axios.post(BASE_URL + "/api/requesthandler", {
      "tid": d,
      "merchant_id": "2621819",
      "order_id": id,
      "amount": Number(cartShipping.total_price_after_discount) + Number(iscountryindia == false ? 3500 : shippingCost && shippingCost.TotalShipCost),
      "currency": "INR",
      "redirect_url": "https://izharsonperfumers.com/admin-login/api/responsehandler",
      "cancel_url": "https://izharsonperfumers.com/admin-login/api/responsehandler",
      "language": "En",
      "billing_name": billingAddressdata.name,
      "billing_address": billingAddressdata.address,
      "billing_city": billingAddressdata.city.name,
      "billing_state": billingAddressdata.state.name,
      "billing_zip": billingAddressdata.pincode,
      "billing_country": billingAddressdata.country.name,
      "billing_tel": billingAddressdata.mobile_number,
      "billing_email": billingAddressdata.email,
      "delivery_name": shippingAddressdata.name,
      "delivery_address": shippingAddressdata.address,
      "delivery_city": shippingAddressdata.city.name,
      "delivery_state": shippingAddressdata.state.name,
      "delivery_zip": shippingAddressdata.pincode,
      "delivery_country": shippingAddressdata.country.name,
      "delivery_tel": shippingAddressdata.mobile_number,
      "delivery_email": shippingAddressdata.email,
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }).then(({ data }) => {
      setpurl(data)
      window.location.href = data
    });
  }
  const CheckShoppingbyDeliveryn = async (quantity, amount) => {
    if (token) {
      setError('');
      axios.post(BASE_URL + '/api/check-pincode-delivery', {
        "pincode": 226026,
        "cart_quantity": quantity,
        "cart_amount": amount,
        "pincode_billing": 226026
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        setShippingData(data)
        setShippingCost(data.shippingCost[0])

        if (data.success === true) {
        }

      }).catch(({ response }) => {


      });
    }
  }
  function CheckShoppingbyDelivery(statePincode, bilpincode) {
    setError("");
    // if (statePincode && bilpincode) {
    //   var billpin = bilpincode;
    //   var shippin = statePincode;
    // } else if (statePincode) {
    //   var billpin = statePincode;
    //   var shippin = statePincode;
    // } else {
    //   var billpin = bilpincode;
    //   var shippin = bilpincode;
    // }

    // axios
    //   .post(
    //     BASE_URL + "/api/check-pincode-delivery",
    //     {
    //       pincode: shippin,
    //       cart_quantity: state.quantity,
    //       cart_amount: state.cartamount,
    //       pincode_billing: billpin,
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + token,
    //       },
    //     }
    //   )
    //   .then(({ data }) => {
    //     setShippingData(data.shippingCost);
    //     //   console.log('daara',data.shippingCost)

    //     if (data.success === true) {
    //       setError("");
    //       // Swal.fire({
    //       //   text: data.message,
    //       //   icon: "success",
    //       // });
    //     }
    //     if (data.success === false) {
    //       Swal.fire({
    //         text: data.errors.pincode,
    //         icon: "error",
    //       });
    //     }
    //   })
    //   .catch(({ response }) => {
    //     setError(response.data);
    //     if (response.status === "422") {
    //       setError(response.data.message);
    //     }
    //   });
  }

  return (
    <div>
      <div className="col-md-12 col-xs-12 nopadding">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li class="breadcrumb-item  breadcrumb-padding">
              <a href="#">
                <span>Home</span>
              </a>
              <img
                src="/Icons/SigleSideArrow.png"
                className="breadcrumb-icon"
              />{" "}
            </li>
            <li class="breadcrumb-item  breadcrumb-padding">
              <a href="#">
                <span>Shop</span>
              </a>
              <img
                src="/Icons/SigleSideArrow.png"
                className="breadcrumb-icon"
              />{" "}
            </li>
            <li className="breadcrumb-item-active" aria-current="page">
              Payment method selections
            </li>
          </ol>
        </nav>
      </div>
      <div className={`col-md-12 col-xs-12 ${style.container}`}>
        <div className="col-md-8 col-xs-12">
          <div className="table-responsive">
            <table class="table">
              <thead className="table-header">
                <tr className="checkout-table">
                  <th scope="col">Product </th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Price</th>
                  <th scope="col">Dicount</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody className="table-body checkout-table-content">
                {cartData &&
                  cartData.map((data) => (
                    <tr>
                      <th>
                        <div className="flex">
                          <img
                            src={BASE_URL + "/storage/" + data.products.image}
                            className={style.img}
                          />{" "}
                          {/* <div className={style.productName}>
                            {data.products.name && data.products.name}
                          </div> */}
                        </div>
                      </th>
                      <th><div className='flex'> {data.products.name && data.products.name}</div></th>
                      <td>
                        <div className={`flex`}>
                          <div className={`textSeconday ${style.price}`}>
                            ₹{" "}
                            {data.product_options && data.product_options.mrp}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={`flex`}>
                          <div className={`textSeconday ${style.price}`}>
                            ₹{" "}
                            {data.product_options &&
                              data.product_options.discount_amount}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={`textSeconday ${style.priceCheck}`}>
                          {data.quantity && data.quantity}
                        </div>
                      </td>
                      <td>
                        <div className={`flex`}>
                          <div className={`textSeconday ${style.price}`}>
                            ₹{" "}
                            {data.product_options.price * data.quantity &&
                              data.product_options.price * data.quantity}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="col-md-12 col-xs-12 nopadding container">
            <div className="checkout-address">
              <h3>Select Address</h3>
              <button
                className="flex hover"
                onClick={() => setOpen(true)}
              >
                <AddIcon sx={{}} />ADD ADDRESS
              </button>
            </div>
            <div className="col-md-12 col-xs-12 nopadding header">

            </div>
            <div className="padding20 checkout-address-section">
              <div className="col-md-6">
                <h4>Billing Address</h4>
                <div className="">
                  {billingAddress &&
                    billingAddress.map((address, i) => (
                      <div
                        className={`col-md-12 nopadding margin-top-bottom  ${style.addrsCont}`}
                        key={i}
                      >
                        <div className="col-md-12 nopadding space-between">
                          <div className="checkout-address-head">{address.name && address.name}</div>
                          <div>
                            <i class="fa fa-pencil-square-o" onClick={() => { setOpenBilling(true); setbilldata(address) }} aria-hidden="true" style={{ float: "right" }}></i>
                            <Form.Check
                              className={style.right}
                              name="Billing address"
                              type="radio"
                              value={address.id}
                              checked={address.id == billingId ? true : false}
                              onChange={(e) => {
                                setBillingId(e.target.value);
                              }}
                              onClick={() => {
                                CheckShoppingbyDelivery(pin, address.pincode);
                                setBilPin(address.pincode);
                                setBillingAddressdata(address)
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12 nopadding ">
                          <div className="col-md-12 mt-2 text-muted nopadding checkout-address-head1">
                            <b>Address</b> : {address.address && address.address}
                          </div>
                          <div className="col-md-12 mt-2  nopadding">
                            <div className="col-md-6 nopadding checkout-address-head1">
                              <b>Country</b> :
                              {address.country.name && address.country.name}{" "}
                            </div>
                            <div className="col-md-6 nopadding checkout-address-head1">
                              <b>State</b> : {address.city.name && address.city.name}{" "}
                            </div>
                          </div>
                          <div className="col-md-12 nopadding mt-1">
                            <div className="col-md-6 nopadding checkout-address-head1">
                              <b>City</b> : {address.state.name && address.state.name}
                            </div>
                            <div className="col-md-6 nopadding checkout-address-head1">
                              <b>Zipcode</b> : {address.pincode && address.pincode}{" "}
                            </div>
                          </div>
                          <div className="col-md-12 mt-1 nopadding checkout-address-head1">
                            <b>Mobile Number</b> :{" "}
                            {address.mobile_number && address.mobile_number}{" "}
                          </div>
                          <div className="col-md-12 mt-1 nopadding checkout-address-head1">
                            <b>Email</b> : {address.email && address.email}{" "}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-md-6">
                <h4>Shipping Address</h4>
                <div className="">
                  {shippingAddress &&
                    shippingAddress.map((address, i) => (
                      <div
                        className={`col-md-12 nopadding margin-top-bottom ${style.addrsCont}`}
                        key={i}
                      >
                        <div className="col-md-12 nopadding space-between ">
                          <div className="checkout-address-head ">{address.name && address.name}</div>
                          <div>
                            <i class="fa fa-pencil-square-o" onClick={() => { setOpenShipping(true); setshipdata(address) }} aria-hidden="true" style={{ float: "right" }}></i>
                            <Form.Check
                              className={style.right}
                              name="Shipping address"
                              type="radio"
                              checked={address.id == shippingId ? true : false}
                              value={address.id}
                              onChange={(e) => {
                                setShippingId(e.target.value);
                              }}
                              onClick={() => {
                                CheckShoppingbyDelivery(
                                  address.pincode,
                                  bilpin
                                );
                                setShippingAddressdata(address);
                                setPin(address.pincode);
                                setiscountryindia(address.country.name == "India" ? true : false)
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12 nopadding ">
                          <div className="col-md-12 mt-2 text-muted nopadding checkout-address-head1">
                            <b>Address</b> : {address.address && address.address}
                          </div>
                          <div className="col-md-12 mt-2  nopadding">
                            <div className="col-md-6 nopadding checkout-address-head1">
                              <b>Country</b> :
                              {address.country.name && address.country.name}{" "}
                            </div>
                            <div className="col-md-6 nopadding checkout-address-head1">
                              <b>State</b> : {address.city.name && address.city.name}{" "}
                            </div>
                          </div>
                          <div className="col-md-12 nopadding mt-1">
                            <div className="col-md-6 nopadding checkout-address-head1">
                              <b>City</b> : {address.state.name && address.state.name}
                            </div>
                            <div className="col-md-6 nopadding checkout-address-head1">
                              <b>Zipcode</b> : {address.pincode && address.pincode}{" "}
                            </div>
                          </div>
                          <div className="col-md-12 mt-1 nopadding checkout-address-head1">
                            <b>Mobile Number</b> :{" "}
                            {address.mobile_number && address.mobile_number}{" "}
                          </div>
                          <div className="col-md-12 mt-1 nopadding checkout-address-head1">
                            <b>Email</b> : {address.email && address.email}{" "}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`col-md-4 col-xs-12`}>
          <div className={`${style.cartTotal} checkout-summary`}>
            <h4>Checkout Summary</h4>
            <div className={style.border}></div>
            <br />
            <div className={`flex space-between ${style.black}`}>
              <h6>Cart Total:</h6>
              <div className="checkout-summary-data">₹ {Number(cartShipping.total_price) + Number(cartShipping.pre_discount)}</div>
            </div>

            {console.log(state)}
            <div className={`flex space-between ${style.black}`}>
              <h6>Pre Discount :</h6>
              <div className="checkout-summary-data">₹ {cartShipping.pre_discount}</div>
            </div>

            <div className={`flex space-between ${style.black}`}>
              <h6>Promo Discount :</h6>
              <div className="checkout-summary-data">₹ {cartShipping.discount_amount}</div>
            </div>

            <div className={`flex space-between ${style.black}`}>
              <h6>Sub Total:</h6>
              <div className="checkout-summary-data">
                ₹{" "}
                {cartShipping.total_price_after_discount}{" "}
              </div>
            </div>

            {/* {iscountryindia == true &&( */}
            <>
              <div className={`flex space-between ${style.black}`}>
                <h6>Shipping Cost:</h6>
                <div className="checkout-summary-data">
                  {iscountryindia == false ? "₹ " + 3500 : shippingCost && "₹ " + shippingCost.TotalShipCost}
                </div>

              </div>

            </>
            <div className={`mb-3 flex space-between ${style.black}`}>
              <h6>Total Amount:</h6>
              <b>
                ₹{" "} {
                  Number(cartShipping.total_price_after_discount) + Number(iscountryindia == false ? 3500 : shippingCost && shippingCost.TotalShipCost)
                  // pin
                  //     ? shippingData &&
                  //       shippingData.map(
                  //         (i) => i.name == state.shipping && i.totalCartAmount
                  //       )
                  //     : 
                  // Number(state.cartamount && state.cartamount) -
                  // - Number(state.promoDiscount && state.promoDiscount) +
                  // Number(iscountryindia == false ? 3500 : state.shippingAmount && state.shippingAmount) +
                  // Number(state.gstAmount && state.gstAmount)

                }
              </b>
            </div>

            <div className={style.border}></div>
            <h4>Select Payment Mode</h4>
            <FormControl className="nopadding" style={{ textAlign: 'left' }}>
              {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
              <RadioGroup
                className="nopadding"
                aria-labelledby="demo-radio-buttons-group-label"
                name="payment"
                onChange={(e) => setPayment(e.target.value)}
              >
                <FormControlLabel
                  value="online"
                  className="nopadding mt-1"
                  // disabled
                  control={<Radio sx={{ padding: 1 }} />}
                  label={<h5>Pay Online</h5>}
                />{" "}
                {/* <br /> */}
                <FormControlLabel
                  value="offline"
                  control={<Radio sx={{ padding: 1 }} />}
                  label={<h5>Bank Transfer or UPI</h5>}
                />
              </RadioGroup>
            </FormControl>
            {error && error.payment_mode && (
              <div className="text-danger">{error.payment_mode}</div>
            )}
            {payment == "offline" && (
              <div
                className={`col-md-12 col-xs-12 mt-2 nopadding ${style.margin}`}
              >
                <h4>
                  <img src={bankaccount.payment_image} className="mb-2 qr-image" width={250} height={400} />
                  <p className="textleft"><b>A/C Name</b>: {bankaccount.ac_name}</p>
                  <p className="textleft"><b>A/C Number</b>: {bankaccount.ac_number}</p>
                  <p className="textleft"><b>Bank Name</b>: {bankaccount.bank_name}</p>
                  <p className="textleft"><b>IFSC Code</b>: {bankaccount.ifsc_code}</p>
                  <p className="textleft"><b>Bank Branch</b>: {bankaccount.bank_branch}</p>
                </h4>
                <div className="col-md-12 col-xs-12">
                  <label>Refrence Id</label>
                  <input
                    placeholder="Enter Refrence Id"
                    className="input"
                    onChange={(e) => setrefrenceid(e.target.value)}
                  />
                  {error && error.refrence_id && (
                    <div className="text-danger">{error.refrence_id}</div>
                  )}
                </div>
                <div className="col-md-12 col-xs-12 mt-1">
                  <label>Payment Proof</label>
                  <input
                    type="file"
                    className="input"
                    onChange={handlepaymentimage}
                  />
                  {error && error.payment_image && (
                    <div className="text-danger">{error.payment_image}</div>
                  )}
                </div>
              </div>
            )}

            <div className={style.border}></div>

            {error && error.billing_id && (
              <div className="text-danger">The billing Address field is required.</div>
            )}
            {error && error.shipping_id && (
              <div className="text-danger">The Shipping Address field is required.</div>
            )}

            <Button
              className={`hover ${style.checkoutButton}`}
              onClick={() => {
                shippingData == null
                  ? Swal.fire("Kindly Select Different Delivery Location")
                  : handlepayment();
              }}
            >
              Confirm Payment
            </Button>
          </div>
        </div>
      </div>
      <AddressModal showAdd={open} setShowAdd={setOpen} />
      {openShipping && (<EditSShippingAddress showAdd={openShipping} data={shipdata} setShowAdd={setOpenShipping} />)

      }
      {openBilling && (<EditBillingAddress showAdd={openBilling} data={billdata} setShowAdd={setOpenBilling} />)}
    </div>
  );
}
