import React, { useEffect, useState } from "react";
import { Form, Button, Modal, FormCheck } from 'react-bootstrap';
import { BASE_URL } from '../../../config';
import axios from 'axios';
import Swal from "sweetalert2";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const AddressModal = ({ showAdd, setShowAdd }) => {


  const [countrys, setCountrys] = useState([]);
  const [states, setStates] = useState([]);
  const [citys, setCitys] = useState([]);
  const [countryship, setCountryship] = useState([]);
  const [stateship, setStateship] = useState([]);
  const [cityship, setCityship] = useState([]);
  const [error, setError] = useState([]);
  const [firstNameship, setFirstName] = useState('');
  const [lastNameship, setLastName] = useState('');
  const [addressship, setAddress] = useState('');
  const [cityshipp, setCity] = useState('');
  const [stateshipp, setState] = useState('');
  const [countryshipp, setCountry] = useState('');
  const [zipcodeship, setZipcode] = useState('');
  const [addresstypeship, setAddressType] = useState('');
  const [phoneship, setPhone] = useState('');
  const [emailIdship, setEmailId] = useState('');

  const [firstNamebil, setFirstNamebil] = useState('');
  const [lastNamebil, setLastNamebil] = useState('');
  const [addressbil, setAddressbil] = useState('');
  const [citybil, setCitybil] = useState('');
  const [statebil, setStatebil] = useState('');
  const [countrybil, setCountrybil] = useState('');
  const [zipcodebil, setZipcodebil] = useState('');
  const [addresstypebil, setAddressTypebil] = useState('');
  const [phonebil, setPhonebil] = useState('');
  const [emailIdbil, setEmailIdbil] = useState('');

  const [isDefault, setIsDefault] = useState('no');
  const [errors, setErrors] = useState([]);
  const [buttonText, setButtonText] = useState('Submit');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const token = localStorage.getItem('token');
  const handleClose = () => {
    setShowAdd(false);
  }
  useEffect(() => {
    fetchCountry();
    fetchCountryship();
  }, []);

  const fetchCountry = async () => {
    await axios.get(BASE_URL + '/api/country').then(({ data }) => {
      setCountrys(data.data)
    }).catch(({ response }) => {

    })
  }
  async function getState(id) {
    await axios.get(BASE_URL + '/api/state/' + id).then(({ data }) => {
      setStates(data.data)
    }).catch(({ response }) => {

    })
  }
  async function getCity(id) {
    await axios.get(BASE_URL + '/api/city/' + id).then(({ data }) => {
      setCitys(data.data)
    }).catch(({ response }) => {

    })
  }
  const fetchCountryship = async () => {
    await axios.get(BASE_URL + '/api/country').then(({ data }) => {
      setCountryship(data.data)
    }).catch(({ response }) => {

    })
  }
  async function getStateship(id) {
    await axios.get(BASE_URL + '/api/state/' + id).then(({ data }) => {
      setStateship(data.data)
    }).catch(({ response }) => {

    })
  }
  async function getCityship(id) {
    await axios.get(BASE_URL + '/api/city/' + id).then(({ data }) => {
      setCityship(data.data)
    }).catch(({ response }) => {

    })
  }

  async function checkpincode(pincode1) {
    if (pincode1.length >= 6) {
      await axios.get(BASE_URL + '/api/getpincodedata/' + pincode1).then(({ data }) => {
        setCountrybil(data.data.state.country_id);
        getState(data.data.state.country_id);
        getCity(data.data.state_id);
        setStatebil(data.data.state_id)
        setCitybil(data.data.city_id)
        // getStateship(data.data.state_id)
        // getCityship(data.data.state_id)
        // setCountry(data.data.state.country_id)
        // setState(data.data.state_id)
        // setCity(data.data.city_id)
      }).catch(({ response }) => {

      })
    }

  }
  async function checkpincodeship(pincode1) {
    if (pincode1.length >= 6) {
      await axios.get(BASE_URL + '/api/getpincodedata/' + pincode1).then(({ data }) => {
        setCountry(data.data.state.country_id)
        getStateship(data.data.state.country_id)
        setState(data.data.state_id)
        getCityship(data.data.state_id)
        setCity(data.data.city_id)
      }).catch(({ response }) => {

      })
    }

  }
  const handlSubmit = async (e) => {
    e.preventDefault();
    setError('');
    await axios.post(BASE_URL + '/api/add-customer-address', {
      'first_name_ship': firstNameship,
      'last_name_ship': lastNameship,
      'address_ship': addressship,
      'city_ship': cityshipp,
      'state_ship': stateshipp,
      'country_ship': countryshipp,
      'pincode_ship': zipcodeship,
      'address_type_ship': addresstypeship,
      'mobile_number_ship': phoneship,
      'email_ship': emailIdship,
      'first_name_bil': firstNamebil,
      'last_name_bil': lastNamebil,
      'address_bil': addressbil,
      'city_bil': citybil,
      'state_bil': statebil,
      'country_bil': countrybil,
      'pincode_bil': zipcodebil,
      'address_type_bil': addresstypebil,
      'mobile_number_bil': phonebil,
      'email_bil': emailIdbil,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {

      if (data.status === 401) {
        Swal.fire({
          text: data.message,
          icon: "success"
        })
      }
      if (data.status === "success") {
        Swal.fire({
          text: data.message,
          icon: "success"
        })
        setShowAdd(false);
        document.getElementById("adressadd").reset();

      }
    }).catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.message)
      }
    })
  }
  const handlesameasbil = (e) => {
    if (e.target.checked) {
      setFirstName(firstNamebil)
      setLastName(lastNamebil)
      setAddress(addressbil)
      getStateship(countrybil)
      getCityship(statebil)
      setCountry(countrybil)
      setState(statebil)
      setCity(citybil)
      setPhone(phonebil)
      setEmailId(emailIdbil)
      setZipcode(zipcodebil)
      setAddressType(addresstypebil)
    }
  }
  return (
    <Modal onHide={handleClose} className="address-model-block" show={showAdd} animation={false} centered>
      <div className="col-md-6">
        <Modal.Header>
          <h4 className="modal-title w-100">Billing Address</h4>
          
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12 nopadding">
            <div className="col-md-12 nopadding">
              <div className="col-md-6 paddingLeft">
                <input className="input" placeholder="Enter First Name" onChange={(e) => setFirstNamebil(e.target.value)} />
                {error.first_name_bil && (
                  <span className="text-danger">{error.first_name_bil}</span>
                )}
              </div>
              <div className="col-md-6 paddingRight">
                <input className="input" placeholder="Enter Last Name" onChange={(e) => setLastNamebil(e.target.value)} />
                {error.last_name_bil && (
                  <span className="text-danger">{error.last_name_bil}</span>
                )}
              </div>
              <div className="col-md-6 paddingLeft">
                <input className="input" type="text" placeholder="Mobile Number" onChange={(e) => setPhonebil(e.target.value)} />
                {error.mobile_number_bil && (
                  <span className="text-danger">{error.mobile_number_bil}</span>
                )}
              </div>
              <div className="col-md-6 paddingRight">
                <input className="input" type="text" placeholder="Email" onChange={(e) => setEmailIdbil(e.target.value)} />
                {error.email_bil && (
                  <span className="text-danger">{error.email_bil}</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12 nopadding">
            <select className="input " placeholder="Select Address Type" onChange={(e) => setAddressTypebil(e.target.value)}>
              <option >Select Business Type</option>
              <option value="home">Home</option>
              <option value="office">Office</option>
            </select>
          </div>

          <div className="col-md-12 nopadding">
            <input className="input" as="textarea" rows={3} type="text" placeholder="Address" onChange={(e) => setAddressbil(e.target.value)} />
            {error.address_bil && (
              <span className="text-danger">{error.address_bil}</span>
            )}

          </div>

          <div className="col-md-12 nopadding address-inputs">
            <div className="col-md-6 nopadding">
              <input className="input add-height" as="textarea" rows={3} type="text" placeholder="Pincode" onChange={(e) => { setZipcodebil(e.target.value); checkpincode(e.target.value) }} />
              {error.pincode_bil && (
                <span className="text-danger">{error.pincode_bil}</span>
              )}
            </div>
            <div className="col-md-6 nopadding">
              <select className="width45 input" value={countrybil} placeholder="Select Country" onChange={(e) => { setCountrybil(e.target.value); getState(e.target.value) }}>
                <option >Select Country</option>
                {countrys && countrys.map((data) => (
                  <option value={data.id}>{data.name}</option>

                ))}

              </select>
            </div>


          </div>
          <div className="col-md-12 nopadding address-inputs">
            <div className="col-md-6 nopadding">
              <select className="width45 input" value={statebil} placeholder="Select Country" onChange={(e) => { getCity(e.target.value); setStatebil(e.target.value) }}>
                <option >Select State</option>
                {states && states.map((data) => (
                  <option value={data.id}>{data.name}</option>

                ))}

              </select>
            </div>
            <div className="col-md-6 nopadding">
              <select className="width20 input" value={citybil} placeholder="Select City" onChange={(e) => setCitybil(e.target.value)}>
                <option >Select City</option>
                {citys && citys.map((data) => (
                  <option value={data.id}>{data.name}</option>

                ))}
              </select>
            </div>
          </div>
        </Modal.Body>
      </div>

      <div className="col-md-6">
        <Modal.Header>
          <div className="shipping-address1">
            <h4>Shipping Address</h4>
            <div>
              <input type="checkbox" onChange={(e) => handlesameasbil(e)} />
              <label >Same as Billing Address</label>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body >
          <div className="col-md-12 nopadding">
            <div className="col-md-12 nopadding">
              <div className="col-md-6 paddingLeft">
                <input className="input" placeholder="Enter First Name" value={firstNameship} onChange={(e) => setFirstName(e.target.value)} />
                {error.first_name_ship && (
                  <span className="text-danger">{error.first_name_ship}</span>
                )}
              </div>
              <div className="col-md-6 paddingRight">
                <input className="input" placeholder="Enter Last Name" value={lastNameship} onChange={(e) => setLastName(e.target.value)} />
                {error.last_name_ship && (
                  <span className="text-danger">{error.last_name_ship}</span>
                )}
              </div>
              <div className="col-md-6 paddingLeft">
                <input className="input" type="text" placeholder="Mobile Number" value={phoneship} onChange={(e) => setPhone(e.target.value)} />
                {error.mobile_number_ship && (
                  <span className="text-danger">{error.mobile_number_ship}</span>
                )}
              </div>
              <div className="col-md-6 paddingRight">
                <input className="input" type="text" placeholder="Email" value={emailIdship} onChange={(e) => setEmailId(e.target.value)} />
                {error.emailIdship && (
                  <span className="text-danger">{error.emailIdship}</span>
                )}
              </div>
            </div>


          </div>
          <div className="col-md-12 nopadding">
            <select className="input " placeholder="Select Address Type" value={addresstypeship} onChange={(e) => setAddressType(e.target.value)}>
              <option >Select Business Type</option>
              <option value="home">Home</option>
              <option value="office">Office</option>
            </select>
          </div>

          <div className="col-md-12 nopadding">
            <input className="input" as="textarea" rows={3} type="text" placeholder="Address" value={addressship} onChange={(e) => setAddress(e.target.value)} />
            {error.address_ship && (
              <span className="text-danger">{error.address_ship}</span>
            )}
          </div>
          <div className="col-md-12 nopadding address-inputs">
            <div className="col-md-6 nopadding">
              <input className="input add-height" as="textarea" rows={3} type="text" value={zipcodeship} placeholder="Pincode" onChange={(e) => { setZipcode(e.target.value); checkpincodeship(e.target.value) }} />
              {error.pincode_ship && (
                <span className="text-danger">{error.pincode_ship}</span>
              )}
            </div>
            <div className="col-md-6 nopadding">
              <select className="width45 input" placeholder="Select Country" value={countryshipp} onChange={(e) => { setCountry(e.target.value); getStateship(e.target.value) }}>
                <option >Select Country</option>
                {countryship && countryship.map((data) => (
                  <option value={data.id}>{data.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-12 nopadding address-inputs">
            <div className="col-md-6 nopadding">
              <select className="width45 input" placeholder="Select State" value={stateshipp} onChange={(e) => { getCityship(e.target.value); setState(e.target.value) }}>
                <option >Select State</option>
                {stateship && stateship.map((data) => (
                  <option value={data.id}>{data.name}</option>
                ))}
              </select>
            </div>
            <div className="col-md-6 nopadding">
              <select className="width20 input" placeholder="Select City" value={cityshipp} onChange={(e) => setCity(e.target.value)}>
                <option >Select City</option>
                {cityship && cityship.map((data) => (
                  <option value={data.id}>{data.name}</option>
                ))}
              </select>
            </div>
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer className=" mb-2">
        <Button variant="danger" type="submit" onClick={handlSubmit} className="mt-2 hover">
          Add Address

        </Button>
        <Button variant="danger" onClick={() => setShowAdd(false)} className="mt-2 hover">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddressModal;
