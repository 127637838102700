import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {Container, Button} from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import style from '../styles/thankyou.module.css'
import { BASE_URL } from "../config";


const ThankYou = () => {
    
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [state, setState] = useState();
    const [num, setNum] = useState();
    const [mail, setMail] = useState();
    const token = localStorage.getItem("token");
    const [msg, setmsg] = useState("");
    useEffect(() => {
        fetchHeaderData();
        OrderDetails();
    })
   const {url} = useParams();
    const OrderDetails = async () => {
        axios.get(BASE_URL + '/api/orderdata/' + url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setState(data.data);
            setmsg(data.message)
        })
    }
    function fetchHeaderData() {
        axios.get(BASE_URL + '/api/header-settings').then(({ data }) => {
            setData(data.data.header_logo);
            setMail(data.data.email)
            setNum(data.data.tollfree_number)
        })
    }
    const date = new Date().toLocaleDateString();
    const time = new Date().toLocaleTimeString();
    const ccavenuerequest=()=>{
        var d = new Date().getTime();
        var t = Math.floor(Math.random() * 10000);
        axios.post(BASE_URL + "/api/requesthandler",{
          "tid":d,
          "merchant_id":"2621819",
          "order_id":state.order_number,
          "amount": state.order_amount_with_shipping,
          "currency":"INR",
          "redirect_url":"https://izharsonperfumers.com/admin-login/api/responsehandler",
          "cancel_url":"https://izharsonperfumers.com/admin-login/api/responsehandler",
          "language":"En",
          "billing_name": state.billingaddress.name,
        "billing_address": state.billingaddress.address,
        "billing_city": state.billingaddress.citys.name,
        "billing_state": state.billingaddress.states.name,
        "billing_zip": state.billingaddress.pincode,
        "billing_country": state.billingaddress.countries.name,
        "billing_tel": state.billingaddress.mobile_number,
        "billing_email": state.billingaddress.email,
        "delivery_name": state.shippingaddress.name,
        "delivery_address": state.shippingaddress.address,
        "delivery_city": state.shippingaddress.citys.name,
        "delivery_state": state.shippingaddress.states.name,
        "delivery_zip": state.shippingaddress.pincode,
        "delivery_country": state.shippingaddress.countries.name,
        "delivery_tel": state.shippingaddress.mobile_number,
        "delivery_email": state.shippingaddress.email,
      }, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          })
          .then(({ data }) => {
            window.location.href = data
           
          });
      }

    
    return (
        <div className="overflow">
           
                    <div className="thankYouCont">
                     
                        <img src={BASE_URL+'/storage/'+ data} className={style.thankImg} />
                        <div  className="thankuInnerCon">

            
                <h5 className="flex center" >Hello <h5 className={`bold ${style.name}`}>{state && state.name}!</h5></h5>

                <div className={`mt-4 ${style.thankCon}`}>
                    <div className={style.font60}> Thank You!</div>

                </div>
                <h3 className="mt-4">{msg}</h3>
                <h3 className="mt-4">Reason:- <b>{state && state.payment_message}</b></h3>
                <div className={`mt-4 ${style.border}`}></div>
                <div className={`mt-4 ${style.left}`}>
                <h6 className="mt-2 ">Order ID : <span className={`bold ${style.order_number}`}>#{state && state.order_number}</span></h6>
                <h6 className="mt-2">Order Date & Time : <span  className={`bold ${style.dateTime}`}>{date} {time}</span></h6>
                <h6 className="mt-2 ">Billed Amount :  <span  className={`bold ${style.amount}`}>₹ {state && state.order_amount_with_shipping} </span></h6>
            
                     <h6 className="mt-2 ">Delivery Option : <span className={`bold ${style.payMode}`}>{state && state.payment_method}</span></h6>
                     {state && state.payment_method === "online" && state.payment_status === "failed" ?(
    <h6 className="mt-2 ">Note : <span  className={`bold ${style.deliver}`}>Your Order is pending with us, please complete your payment so we can process your order</span></h6>             
                     ):(
                        <h6 className="mt-2 ">Expected Delivery : <span  className={`bold ${style.deliver}`}>{state && state.shipping_type_maximum_days}</span></h6>             
                     )}
                    
                     </div>          
                     <div className={`mt-4 ${style.border}`}></div> 
                <h6 className="mt-4">For any query Please feel free to contact us on: {num} or write us {mail}</h6>

                <div className="mt-4">
                    <Button className="hover" onClick={() => navigate("/dashboard")} variant="danger">Go To Dashboard</Button>
                 <a href={state && BASE_URL+'/storage'+state.invoice_url} target="_blank" className="hover">   <Button variant="danger" className={style.ml}> Download Invoice </Button> </a>
                 {state && state.payment_method === "online" && state.payment_status === "failed" &&(
                    <Button className="hover" onClick={() => ccavenuerequest()} variant="danger">Re-try Payment</Button>
                 )}
                 
                </div>
                </div>
            </div>
        </div>
    );
};

export default ThankYou;

