import React, { useState, useEffect, useContext } from 'react';
import style from '../../../styles/ProductDetails.module.css';
import { Button, Input, FormControl, MenuItem, Select, InputLabel, Box, Modal, Icon } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ProductDetailsTab from '../../ProductDetailsTab';
import RelatedProducts from '../Homepage/RelatedProducts';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Dropdown, Form } from "react-bootstrap";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BASE_URL } from '../../../config';
import axios from 'axios';
import Swal from 'sweetalert2';
import { UserContext } from '../../../App';
import ImageZoomer from '../../ImageZoomer';
import dateFormat from "dateformat";
import Loader from '../../Loader';
const Style = {
  position: 'absolute',
  top: '300px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const LongText = ({ content, limit }) => {
  const [showAll, setShowAll] = useState(false);
  

  const showMore = () => setShowAll(true);
  const showLess = () => setShowAll(false);

  if (content && content.length <= limit) {
    // there is nothing more to show
    return <div>{content}</div>
  }
  if (showAll) {
    // We show the extended text and a link to reduce it
    return <div>
      {content}
      <button onClick={showLess} className='show-more hover'>Read less <img src='/Icons/up-arrow.jpg' height="15px" width="15px" style={{ marginLeft: 5 }} /> </button>
    </div>
  }
  // In the final case, we show a text with ellipsis and a `Read more` button
  const toShow = content ? content.substring(0, limit) + "..." : '';
  return <div>
    {toShow}
    <button onClick={showMore} className='show-more hover'>Read more <img src='/Icons/748063.png' height="15px" width="15px" style={{ marginLeft: 5 }} /></button>
  </div>
}

export default function ProductDetails() {

  const [counter, setCounter] = useState(1);

  const handleDec = () => {
    if (counter > 0) {
      setCounter(counter - 1)
    }
    else {
      setCounter(counter)
    }
  }

  const handleInc = () => {
    if (counter > 0) {
      setCounter(counter + 1)
    }
    else {
      setCounter(counter)
    }
  }


  let { slug } = useParams();
  console.log(slug)
  const [productData, setProductData] = useState('');
  const [variant, setVariant] = useState([]);
  const [fregrance, setFregrance] = useState([])
  const [freg, setFreg] = useState();
  const [variantData, setVariantData] = useState();
  const [featured, setFeatured] = useState([]);
  const [relatedProduct, setRelatedProduct] = useState([]);
  const [error, setError] = useState();
  const token = localStorage.getItem('token');
  let machineId = localStorage.getItem('MachineId');
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const { item, setItem, setWishCount, wishCount } = useContext(UserContext);
  const [countproduct, setCountProduct] = useState(1);
  const [homeData, setHomeData] = useState([]);
  const navigate = useNavigate();
  const [mrp, setmrp] = useState("");
  const [price, setprice] = useState("");
  const [discount, setdiscount] = useState("");
  const [productimage, setproductimage] = useState("");
  const [productimage1, setproductimage1] = useState("");
  console.log(freg, variantData)
  const [variantimage, setvariantimage] = useState([]);
  const [variantimage1, setvariantimage1] = useState([]);
  const [cartdata, setcartdata] = useState([]);
  const [wishlistdata, setwishlistdata] = useState([]);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    fetchproduct();
    getMachineId();
    fetchVariant();
    fetchCount();
    FetchItemsAuth();
    FetchWishCounts();
    fetchHomeWidgets();
    FetchWishlistUnauth()
  }, [slug])

  const fetchCount = async () => {
    if (!token) {
      const data = { "device_id": machineId }
      await axios.post(BASE_URL + '/api/get-cart-unauth', data).then(({ data }) => {
        setItem(data.data.cart.item)
        setcartdata(data.data.cart_details)
      })
    }
  }

  const fetchHomeWidgets = async () => {
    await axios.get(BASE_URL + '/api/homepagewidget').then(({ data }) => {
      setHomeData(data.data.data[9]);
      // console.log(data.data)
    })
  }


  const FetchItemsAuth = async () => {
    if (token) {
      axios.get(BASE_URL + '/api/get-cart', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        setItem(data.data.cart.item)
        setcartdata(data.data.cart_details)
      })
    }
  }

  const FetchWishCounts = async () => {
    if (token) {
      axios.get(BASE_URL + '/api/get-wishlist', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        setWishCount(data.data.length)
        setwishlistdata(data.data)
        // console.log('hyd', data.data)

      })
    }
  }

  const FetchWishlistUnauth = async () => {
    if (!token) {
      const data = { "device_id": machineId }
      await axios.post(BASE_URL + '/api/get-wishlist-unauth', data).then(({ data }) => {
        setWishCount(data.data.length)
        setwishlistdata(data.data)
      })
    }
  }
  const removeWishlistUnauth = async () => {
    if (!token) {
      const data = { "device_id": machineId, 'product_id': productData.id }
      await axios.post(BASE_URL + '/api/remove-wishlist-unauth', data).then(({ data }) => {
        FetchWishlistUnauth();
      })
    }
  }
  const removeWishlist = async () => {
    if (token) {
      const data = { 'product_id': productData.id }
      await axios.post(BASE_URL + '/api/remove-from-wishlist', data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        FetchWishCounts();
      })
    }
  }
  const fetchproduct = async () => {
    if (slug) {
      await axios.get(BASE_URL + '/api/manage-product/' + slug).then(({ data }) => {
        setProductData(data.data);
        if (data.data.product_options[0].product_variant_images[0]) {
          setproductimage(BASE_URL + '/storage/' + data.data.product_options[0].product_variant_images[0].image);
          setvariantimage(data.data.product_options[0].product_variant_images);
        } else {
          setproductimage(BASE_URL + '/storage/' + data.data.image);
          setvariantimage(data.data.product_option_images);
          setproductimage1(BASE_URL + '/storage/' + data.data.image);
          setvariantimage1(data.data.product_option_images);
        }

        setFregrance(data.data.fragrance)
        setmrp(data.data.product_options[0].mrp);
        setprice(data.data.product_options[0].price);
        setdiscount(data.data.product_options[0].discount_percentage);
        console.log(data)
        if (data.status == true) {
          // alert('bdh')
          axios.get(BASE_URL + '/api/manage-product-with-category/' + data.data.category_id).then(({ data }) => {
            setFeatured(data.data);
          });
        }
        setloading(false)
      });
    }
  }

  const fetchVariant = async () => {
    await axios.get(BASE_URL + '/api/manage-volume').then(({ data }) => {
      setVariant(data.data);
    });
  }

  function AddWishlist(id) {
    if (token) {
      setError('');
      axios.post(BASE_URL + '/api/add-to-wishlist', {
        "product_id": id,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        FetchWishCounts();
        if (data.status === true) {
          setError('')
          // Swal.fire({
          //   text: data.message,
          //   icon: "success"
          // })
          document.getElementById("ThankYou").reset();
        }
        if (data.status === false) {
          Swal.fire({
            text: data.message,
            icon: "error"
          })
        }
      }).catch(({ response }) => {
        setError(response.data)
        if (response.status === "422") {
          setError(response.data.message)
        }
      });
    } else { Swal.fire("Kindly Login First") }
  }

  function AddWishlistUnauth(id) {
    if (!token) {
      setError('');
      axios.post(BASE_URL + '/api/add-to-wishlist-unauth', {
        "product_id": id,
        "device_id": machineId
      }).then(({ data }) => {
        FetchWishlistUnauth();
        if (data.status === true) {
          setError('')
          // Swal.fire({
          //   text: data.message,
          //   icon: "success"
          // })
          document.getElementById("ThankYou").reset();
        }
        if (data.status === false) {
          Swal.fire({
            text: data.message,
            icon: "error"
          })
        }
      }).catch(({ response }) => {
        setError(response.data)
        if (response.status === "422") {
          setError(response.data.message)
        }
      });
    } else { Swal.fire("Kindly Login First") }
  }

  function handleSubmit(id, optionId) {
    setOpen(true)
    setError('');
    axios.post(BASE_URL + '/api/store-cart', { "product_id": id, "product_option_id": optionId, "quantity": countproduct },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        FetchItemsAuth()
        if (data.success === true) {
          setError('')
          Swal.fire({
            text: data.meassge,
            icon: "success"
          })
          document.getElementById("loginpage").reset();
        }
        if (data.success === false) {
          Swal.fire({
            text: data.meassge,
            icon: "error"
          })
        }
      }).catch(({ response }) => {
        setError(response.data.message)
        if (response.status_code === "422") {
          setError(response.data.message)
        }
      });
  }

  function getMachineId() {

    let machineId = localStorage.getItem('MachineId');

    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem('MachineId', machineId);
    }
  }

  function handleSubmitOffline(id, optionId) {
    setOpen(true)
    setError('');
    axios.post(BASE_URL + '/api/store-cart-unauth', { "product_id": id, "product_option_id": optionId, "device_id": machineId, "quantity": countproduct }, {}).then(({ data }) => {
      fetchCount()
      if (data.success === true) {
        setError('')
        Swal.fire({
          text: data.meassge,
          icon: "success"
        })
        document.getElementById("loginpage").reset();
      }
      if (data.success === false) {
        Swal.fire({
          text: data.meassge,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data.message)
      if (response.status_code === "422") {
        setError(response.data.message)
      }
    });
  }
  const checkvariant = (id) => {
    const data = productData.product_options.filter((e) => e.id == id);
    console.log("----", data)
    setmrp(data[0].mrp);
    setprice(data[0].price);
    setdiscount(data[0].discount_percentage);
  }
  function AddCart(id, optionId) {

    setOpen(true)
    setError('');
    axios.post(BASE_URL + '/api/store-cart', { "product_id": id, "product_option_id": optionId, "quantity": countproduct },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        FetchItemsAuth()
        if (data.success === true) {
          setError('')
          Swal.fire({
            text: data.meassge,
            icon: "success"
          })
          navigate('/cart')
        }
        if (data.success === false) {
          Swal.fire({
            text: data.meassge,
            icon: "error"
          })
        }
      }).catch(({ response }) => {
        setError(response.data.message)
        if (response.status_code === "422") {
          setError(response.data.message)
        }
      });
  }

  function AddCartOffline(id, optionId) {
    setOpen(true)
    setError('');
    axios.post(BASE_URL + '/api/store-cart-unauth', { "product_id": id, "product_option_id": optionId, "device_id": machineId, "quantity": countproduct }, {}).then(({ data }) => {
      fetchCount()
      if (data.success === true) {
        setError('')
        Swal.fire({
          text: data.meassge,
          icon: "success"
        })
        navigate('/cart')
      }
      if (data.success === false) {
        Swal.fire({
          text: data.meassge,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data.message)
      if (response.status_code === "422") {
        setError(response.data.message)
      }
    });
  }

  const changevariantimage = (id) => {
    console.log(id)
    const data = productData.product_options.filter((e) => e.id == id);
    const e = data[0];
    if (e.product_variant_images && e.product_variant_images[0]) {
      setvariantimage(e.product_variant_images);
      setproductimage(e.product_variant_images && BASE_URL + '/storage/' + e.product_variant_images[0].image)
    } else {
      setproductimage(productimage1)
      setvariantimage(variantimage1)
    }

  }
  const filtercartdata = (id) => {
    let d = cartdata.filter((e) => e.product_id === id);
    if (d.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  const filterwishlistdata = (id) => {
    let d = wishlistdata.filter((e) => e.product_id === id);
    if (d.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  console.log(productData.product_review, "review");
  return (
    <>
     {loading ?(
      <Loader />
    ):(
      <div>
      <div className='col-md-12 col-xs-12 nopadding'>
        <nav aria-label="breadcrumb" >
          <ol className='breadcrumb'>
            <li class="breadcrumb-item  breadcrumb-padding"><a href="/"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li className="breadcrumb-item-active" aria-current="page">{productData && productData.name}</li>
          </ol>
        </nav>
      </div>

      <div className={`col-md-12 col-sm-12 col-xs-12 ${style.container}`}>
        <div className='col-md-9 col-xs-12'>
          <div className='col-md-6 col-sm-6 col-xs-12 nopadding'>
            <div className={`col-md-12 col-xs-12 col-sm-12 nopadding ${style.ImgCon}`}>
              <img src={productimage} alt="perfume" className={style.mainImg} />
            </div>

            <div className={`col-md-12 col-sm-12 col-xs-12 nopadding ${style.marginTop10}`}>

              <div className="col-md-3 nopadding flex" >
                {variantimage && variantimage.map((data) => (
                  <div className={style.prodSmall} onClick={() => setproductimage(BASE_URL + '/storage/' + data.image)}>
                    <img src={BASE_URL + '/storage/' + data.image} alt="perfume" className={`pointer ${style.prodSmallImg}`} />
                  </div>
                ))}

              </div>

            </div>
          </div>
          <div className='col-md-6 col-sm-6 col-xs-12'>
            <div className=' space-between' >
              <div className='product-name'>{productData && productData.name}</div>
             
              <div className={style.wishCont}> 
                        {filterwishlistdata(productData.id)==true ? (
                          <i className="fa fa-heart heart-icon" aria-hidden="true"
                          onClick={() => token ? removeWishlist(productData.product_options[0].product_id) : removeWishlistUnauth(productData.product_options[0].product_id)}
                        ></i>
                          
                        ):(
                          <img src="/Icons/wishlist.svg" alt="Add to wishList" className={`pointer ${style.wishIcon}`} onClick={() => token ? (filterwishlistdata(productData.id)==true ? removeWishlist(productData.product_options[0].product_id) : AddWishlist(productData.product_options[0].product_id)) : (filterwishlistdata(productData.id)==true ? removeWishlistUnauth(productData.product_options[0].product_id) : AddWishlistUnauth(productData.product_options[0].product_id))} /> 
                        )}
              
              
              </div>
            </div>
            {productData.rating == '0' ?
              '' : productData.rating == '1' ?
                <div className="flex">
                  <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                  <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                  <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                  <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                  <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                </div>
                : productData.rating == '2' ?
                  <div className="flex">
                    <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                    <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                    <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                    <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                    <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                  </div> : productData.rating == '3' ?
                    <div className="flex">
                      <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                      <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                      <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                      <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                      <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                    </div> : productData.rating == '4' ?
                      <div className="flex">
                        <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                        <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                        <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                        <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                        <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                      </div> : productData.rating == '5' ?
                        <div className="flex">
                          <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                          <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                          <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                          <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                          <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                        </div> : ''

            }
            {productData.product_options && discount == '0' ?
              <div className={style.prizedisc}> ₹ {productData.product_options && price} </div>
              :

              <div className="flex mt-1">
                {productData.product_options && discount != '0' &&
                  <div className={style.disPrize}> ₹ {productData.product_options && mrp} </div>
                }
                <div className={style.prize}> ₹ {productData.product_options && price} </div>
                {productData.product_options && discount != '0' &&
                  <div className={style.discount}>{productData.product_options && discount} %</div>
                }
              </div>
            }

            <div className="mt-1 product-details-content"> <LongText content={productData.short_description} limit={160} /> </div>
            <br />
            <div >
              <div className='flex product-details-content'>Category : <div className={`bold ${style.ml5}`}>  {productData.categories && productData.categories.name}</div> </div>
              <div className='mt-2 flex product-details-content'>Product Id : <div className={`bold ${style.ml5}`}>  {productData.sku && productData.sku}</div> </div>
            </div>

            <div className=' '>

              <div className='mt-2'>

                <div className='flex'>
                  <div className='product-details-content'>{productData && productData.product_options.length > 1 ? (<>Select</>) : ("")} Weight : </div>
                  <div className={style.marginLeft}>
                    {productData && productData.product_options.length > 1 ? (
                      <Form.Select onChange={(e) => { setVariantData(e.target.value); checkvariant(e.target.value); changevariantimage(e.target.value) }}>
                        {/* <option >
                        <div value={variant[0] && variant[0].quantity && variant[0] && variant[0].quantity_in}> {variant[0] && variant[0].quantity} </div>
                        <div className='ml2'>  {variant[0] && variant[0].quantity_in} </div>
                      </option> */}
                        {productData && productData.product_options.map((data) => (

                          <option value={data.id}>
                            <div> {data.packaging.quantity && data.packaging.quantity} </div>
                            <div className='ml2'>  {data.packaging.quantity_in && data.packaging.quantity_in} </div>
                          </option>
                        ))}

                      </Form.Select>
                    ) : (
                      <>
                        <div className={`bold ${style.ml5}`}>  {productData && productData.product_options[0].packaging.quantity_in && productData.product_options[0].packaging.quantity + " " + productData.product_options[0].packaging.quantity_in} </div>
                      </>
                    )}


                  </div>
                </div>
              </div>
              <div className='col-md-12 nopadding mt-2' style={{}}>
                <div className='flex cartbuy'>
                  <div className={`flex ${style.counter}`}>
                    <div className={`margin-left-right5 pointer  ${style.counterFont}`} onClick={() => setCountProduct(countproduct == 1 ? countproduct : countproduct - 1)}>-</div>
                    <div className={style.counterNum}>{countproduct}</div>
                    <div className={`margin-left-right5 pointer ${style.counterFont}`} onClick={() => setCountProduct(countproduct + 1)}>+</div>
                  </div>
                  <button style={{ cursor: 'pointer' }} className={`hover ${style.CartBtn}`} onClick={() => token ? handleSubmit(productData.product_options[0].product_id && productData.product_options[0].product_id, productData.product_options[0].id && variantData ? variantData : productData.product_options[0].id) : handleSubmitOffline(productData.product_options[0].product_id && productData.product_options[0].product_id, productData.product_options[0].id && variantData ? variantData : productData.product_options[0].id)}>{filtercartdata(productData && productData.product_options[0].product_id) == true ? "Added to Cart" : "Add to Cart"}</button>
                  <button className={`hover ${style.buyBtn}`} onClick={() => token ? AddCart(productData.product_options[0].product_id && productData.product_options[0].product_id, variantData ? variantData : productData.product_options[0].id && productData.product_options[0].id) : AddCartOffline(productData.product_options[0].product_id && productData.product_options[0].product_id, productData.product_options[0].id && variantData ? variantData : productData.product_options[0].id)}>Buy Now</button>
                </div>
              </div>
            </div>


            <div className='col-md-12 col-xs-12 col-sm-12 nopadding mt-2'>
            </div>

            <div className='col-md-12 nopadding '>
              <div className={`mt-2 ${style.borderTop}`}></div>
              {productData.has_cash_on_delivery == "yes" && (
                <div className='col-md-6 nopadding'> <div className={`mt-2 ${style.cod}`}><img src="/Icons/true.png" alt="" className={style.codImg} /> Cash On Delivery</div> </div>

              )}
              {productData.replacement_waranty == "yes" && (
                <div className='col-md-6 nopadding'> <div className={`mt-2 ${style.cod}`}><img src="/Icons/true.png" alt="" className={style.codImg} /> Replacement Waranty  </div> </div>
              )}
              {productData.cancellation_allowed == "yes" && (
                <div className='col-md-6 nopadding'> <div className={`mt-2 ${style.cod}`}><img src="/Icons/true.png" alt="" className={style.codImg} /> Cancellation Allowed</div> </div>
              )}
              {productData.express_sheeping == "yes" && (
                <div className='col-md-6 nopadding'> <div className={`mt-2 ${style.cod}`}><img src="/Icons/true.png" alt="" className={style.codImg} /> Express Shipping </div> </div>
              )}

            </div>
            {/* <div className='col-md-12 nopadding'>
              <div className='col-md-6 nopadding'> <div className={`mt-2 ${style.cod}`}><img src="/Icons/true.png" alt="" className={style.codImg} /> Cancellation Allowed</div> </div>
              <div className='col-md-6 nopadding'> <div className={`mt-2 ${style.cod}`}><img src="/Icons/true.png" alt="" className={style.codImg} /> Free Shipping </div> </div>
            </div> */}

            <div className={`mt-2 col-md-12 col-xs-12 ${style.borderTop}`}></div>
          </div>
          <div className={`col-md-12 col-xs-12 ${style.Tabs}`}>
            <ProductDetailsTab
              Discription={
                <div className="product-details" dangerouslySetInnerHTML={{ __html: productData.description && productData.description }}></div>
              }
              Info={
                <div dangerouslySetInnerHTML={{ __html: productData.additional_information && productData.additional_information }}></div>
              }
              ShippingInfo={
                <div dangerouslySetInnerHTML={{ __html: productData.shipping_information && productData.shipping_information }}></div>
              }
              Reviews={
                productData.product_review && productData.product_review.map((data) => (
                  <>
                    <div className='review-section'>
                      <div className='review-header'>
                        <button>{data.rating} <i class="fa fa-star" aria-hidden="true"></i></button>
                        <div className="ml2"> <b>{data.customer_name && data.customer_name}</b></div>
                      </div>
                      <p className='mt-1'>{data.review && data.review}</p>
                    
                      <ul>
                        {/* <li>{data.customer_name && data.customer_name}</li> */}
                        <li><i class="fa fa-check-circle" aria-hidden="true"></i> Certified Buyer</li>
                        <li>{data.address}</li>
                        <li>{dateFormat(data.created_at, "d mmm yyyy")}</li>
                      </ul>
                    </div>
                    {/* <div className='flex marginTop'>
                      <div className='bold'>Customer Name : </div>
                      <div sclassName="ml2"> {data.customer_name && data.customer_name}</div>
                    </div>
                    <div className='flex marginTop'>
                      <div className='bold'>Review : </div>
                      <div sclassName="ml2"> {data.review && data.review}</div>
                    </div>
                    <div className='marginTop'>
                      {data.rating == '0' ?
                        '' : data.rating == '1' ?
                          <div className="flex">
                            <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                            <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                            <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                            <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                            <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                          </div>
                          : data.rating == '2' ?
                            <div className="flex">
                              <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                              <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                              <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                              <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                              <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                            </div> : data.rating == '3' ?
                              <div className="flex">
                                <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                                <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                              </div> : data.rating == '4' ?
                                <div className="flex">
                                  <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                  <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                  <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                  <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                  <img src="/Icons/star.png" alt="rating" className="margin-left-right5" />
                                </div> : data.rating == '5' ?
                                  <div className="flex">
                                    <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                    <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                    <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                    <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                    <img src="/Icons/entypo_star.png" alt="rating" className="margin-left-right5" />
                                  </div> : ''

                      }
                    </div> */}
                  </>
                ))
              }
            />
          </div>
        </div>
        <div className='col-md-3 col-xs-12'>
          {/* <div className='col-md-12 col-xs-12'>
            <div className={style.discountBanner}>
              <img src={BASE_URL + '/storage/' + homeData.image} className="banner-image-details" />
              <div className={style.innerCard}>
                <div className={style.cardContent}>
                  <div className={style.cardMargin}>
                    <h1> {homeData && homeData.heading}</h1><br />
                    <h3 dangerouslySetInnerHTML={{ __html: homeData && homeData.content }}></h3>
                    <br />
                    {homeData.url_txt && homeData.url && (
                      <a href={homeData.url}><button className={`hover ${style.shopButton}`}>{homeData.url_txt} <ArrowForwardIcon /> </button> </a>

                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> */}


          <div className='col-md-12 col-xs-12'>

            <div className={style.FeatureBox}>
              <div className="featured-heading">Featured Product</div>
              <div className={style.borderTop}></div>
              {featured && featured.map((i) => (
                <>
                  <div className={style.borderTop}></div>

                  <div className={`flex ${style.marginTop30} pointer`} onClick={() => navigate('/product-details/' + i.slug)}>
                    <div>
                      <img src={BASE_URL + '/storage/' + i.image} className={style.featureImg} />
                    </div>
                    <div className={style.marginLeft}>
                      <div className={style.featureImgHeading}>{i.name && i.name}</div>
                      <div className={style.featureImgText}>{i.short_description && i.short_description.length > 25 ? i.short_description.substring(0, 25) + '...' : i.short_description}</div>
                      <div className={style.featureImgPrice}>₹ {i.product_options && i.product_options[0].mrp}</div>
                    </div>
                    <div></div>
                  </div>
                </>
              ))}
            </div>

          </div>
        </div>

      </div>
    </div>
    )}
    </>
   
    
  )
}
