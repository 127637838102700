import React from 'react';
import {Button} from '@mui/material';
import style from '../../styles/itemBox.module.css';
import { BASE_URL } from '../../config';

function CircleImage({img, name,data}){
    return(
        <div className={`hover ${style.roundouterBox}`}>
            <div >
                <img className={style.innerBox} src={BASE_URL+'/storage/'+data} />
            
            <div className={`center ${style.mt20}`}>
            <button className={`${style.button}`}>{name}</button>
            </div>    
            
            </div>
        </div>
    )
}

export default CircleImage;