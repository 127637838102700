import React from 'react';
import { Button } from '@mui/material'
import { Link } from 'react-router-dom';
import style from '../../styles/itemBox.module.css'
import { useNavigate } from 'react-router-dom';

function ItemBox({ discount,exist, name, actualPrice, discountPrice, img, onClick, ProductDetail,discountpercentage }) {

    const navigate = useNavigate();

    return (
        <div >
            <div className={`card hover ${style.card}`}>
                <div className={style.innerCard}>
                    <div className={style.outerCard}>
                        {/* <div className={style.mobileHidden}>
                            {discount == '0' ? <br /> :
                                <div className={style.discount}>{discount} %</div>
                            }
                        </div> */}
                        <div className={style.imgContainer} onClick={ProductDetail}>
                            <img src={img} className={style.img} />
                        </div>
                        <div className={style.name}>{name}</div>
                        <div className={style.mobilename}>{name.length > 10 ? name.substring(0, 20) + "..." : name}</div>
                        <div className={style.price}>
                            <h5 className={style.actualPrice}>₹ {actualPrice}</h5>
                            <h5 className={style.discountPrice}>₹ {discountPrice}</h5>
                        </div>
                        <h5 className={style.discountPrice1} >You Save {discountpercentage}% </h5>
                        <div className={style.mobilePro}>
                            <div className={style.discountPrice}>₹ {discountPrice}</div>
                            <div className={style.mobilePrice}>MRP: <span className={style.actualPrice}>₹ {actualPrice}</span> </div>
                        </div>
                        
                        <button className={`hover ${style.cartButton}`} onClick={onClick}>{exist==true ? "Added to Cart":"Add to Cart"}</button>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default ItemBox;