import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { BASE_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";
import { Rating } from "react-simple-star-rating";
import { useNavigate } from "react-router-dom";

const Star = ({ starId, marked }) => {
    return (
        <span star-id={starId} role="button" className="star-style">
            {marked ? "\u2605" : "\u2606"}
        </span>
    );
};

const CancelOrderDetails = (props) => {
    const token = localStorage.getItem("token");
    const [selectedImages, setSelectedImages] = useState([]);
    const [rating, setRating] = useState();
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    // useEffect(() => {
    //     featchCancelData();
    // }, []);

    console.log(props, "hello cancel details");

    const handleRating = (rate) => {
        setRating(rate);
        // other logic
    };
   
    return (
        <>
            <div className="order-date order-id">
                <p className="hide-in-mobile">
                    Ordered on {props.cancelDetailsData.order_date_time}
                </p>
                <p> Order# {props.cancelDetailsData.order_number}</p>
                <p>
                    Order Status:{" "}
                    <span style={{ color: "#1CA301" }}>
                        {props.cancelDetailsData.order_status}
                    </span>
                </p>
                {/* <button
                    onClick={() => {
                        setShowModal(true);
                        setOrderId(props.orderDetailsData.id);
                    }}
                >
                    Cancel order
                </button> */}
            </div>
            <div className="shipping-address">
                <div className="right-border address-pd">
                    <h6>Shipping Address</h6>
                    <ul>
                        <li>{props.cancelDetailsData.name}</li>
                        <li style={{ color: "#555" }}>
                            {props.cancelDetailsData.address},{" "}
                            <span>{props.cancelDetailsData.citys.name}</span>
                        </li>
                        <li style={{ color: "#555" }}>
                            {props.cancelDetailsData.states.name},{" "}
                            <span>{props.cancelDetailsData.countries.name}</span>,{" "}
                            <span>{props.cancelDetailsData.pincode}</span>
                        </li>
                        <li style={{ color: "#555" }}>
                            {props.cancelDetailsData.mobile_number},{" "}
                            <span>{props.cancelDetailsData.email}</span>
                        </li>
                    </ul>
                </div>
                <div className="right-border address-pd">
                    <h6>Payment</h6>
                    <div className="order-date">
                        <ul>
                            <li>Payment Status</li>
                          
                            <li>Invoice Number</li>
                        </ul>
                        <ul>
                            <li style={{ color: "#1CA301" }} className="payment-pera">
                                {props.cancelDetailsData.payment_status}
                            </li>
                        
                            <li className="payment-pera">
                                {props.cancelDetailsData.invoice_number}
                            </li>
                        </ul>
                    </div>
                    <a
                        href={BASE_URL + "/storage/" + props.cancelDetailsData?.invoice_url}
                        download={
                            BASE_URL + "/storage/" + props.cancelDetailsData?.invoice_url
                        }
                        target="_blank"
                    >
                        <h5 className="download-invoice">
                            <img src="/Icons/download-file.png" />
                            Download Invoice
                        </h5>
                    </a>
                </div>
                <div className="address-pd">
                    <h6>Order Summary</h6>
                    <div className="order-date order-details-mt-1">
                        <div>
                            <p>Item(s) Subtotal:</p>
                            <p>Shipping:</p>
                            <p>Promotion Applied</p>
                            <p>Total:</p>
                            <p className="grant-total">Grand Total:</p>
                        </div>
                        <div>
                            <p>
                                <span className="rupees-font">₹</span>{" "}
                                {props.cancelDetailsData.subtotal}
                            </p>
                            <p>
                                <span className="rupees-font">₹</span>{" "}
                                {props.cancelDetailsData.shipping_price}
                            </p>
                            <p>
                                <span className="rupees-font">₹</span>{" "}
                                {props.cancelDetailsData.discount_amount}
                            </p>
                            <p>
                                <span className="rupees-font">₹</span>{" "}
                                {props.cancelDetailsData.order_amount_with_shipping}
                            </p>
                            <p className="grant-total">
                                <span className="rupees-font">₹</span>{" "}
                                {props.cancelDetailsData.order_amount}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {props.cancelDetailsData.order_details.map((item, index) => (
                <div>
                    <div
                        className="shipping-address hide-in-mobile"
                        style={{ marginTop: "20px" }}
                    >
                        <div className="address-pd">
                            <h6>{item.product.name}</h6>
                            <ul>
                                <li>
                                    <b>Size:</b>{" "}
                                    <span style={{ color: "#555" }}> {item.size}</span>
                                </li>
                                <li>
                                    <b>Quantity:</b> <span style={{ color: "#555" }}> </span>
                                    {item.quantity} x <span className="rupees-font">₹</span>{" "}
                                    <span className="product-price">{item.price}</span>
                                </li>
                               
                            </ul>
                        </div>
                        <div className="address-pd product-image">
                            <img
                                src={BASE_URL + "/storage/" + item.product.image}
                                width={110}
                            />
                        </div>
                        {item.order_product_review ? (
                            <div className="address-pd">
                                <h6>Your Review</h6>
                                <div>
                                    <Rating
                                        value={item.order_product_review.rating}
                                        initialValue={item.order_product_review.rating}
                                        readonly={true}
                                    />
                                    
                                </div>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                    style={{ marginTop: "10px" }}
                                >
                                    <Form.Control
                                        type="text"
                                        value={item.order_product_review.review}
                                        placeholder="Write your review"
                                        disabled={true}
                                    />
                                </Form.Group>
                            </div>
                        ) : (
                            <div className="address-pd">
                                {/* <h6>Submit Your Review</h6>
                                <div>
                                    <Rating onClick={handleRating} initialValue={5} />
                                </div>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                    style={{ marginTop: "10px" }}
                                >
                                    <Form.Control
                                        type="text"
                                        onChange={(e) => setMessage(e.target.value)}
                                        placeholder="Write your review"
                                    />
                                </Form.Group>
                                <button
                                    className="review-submit"
                                    onClick={() => OrderReviewSubmit(item.id)}
                                >
                                    Submit
                                </button> */}
                            </div>
                        )}
                    </div>
                    <div className="mobile-order-border hide-in-desktop">
                        <div className="mobile-order-details">
                            <div className="address-pd">
                                <h6>{item.product.name}</h6>
                                <ul>
                                    <li>
                                        <b>Size:</b>{" "}
                                        <span style={{ color: "#555" }}> {item.size}</span>
                                    </li>
                                    <li>
                                        <b>Category:</b>{" "}
                                        <span style={{ color: "#555" }}>Attar</span>
                                    </li>
                                    <li>
                                        <b>Quantity:</b> <span style={{ color: "#555" }}> </span>
                                        {item.quantity} x <span className="rupees-font">₹</span>{" "}
                                        <span className="product-price">{item.price}</span>
                                    </li>
                                    {/* <li >{item.price}</li> */}
                                </ul>
                            </div>
                            <div className="address-pd product-image">
                                <img
                                    src={BASE_URL + "/storage/" + item.product.image}
                                    width={110}
                                />
                            </div>
                        </div>
                        {item.order_product_review ? (
                            <div className="address-pd">
                                <h6>Your Review</h6>
                                <div>
                                    <Rating
                                        value={item.order_product_review.rating}
                                        initialValue={item.order_product_review.rating}
                                        readonly={true}
                                    />
                                </div>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                    style={{ marginTop: "10px" }}
                                >
                                    <Form.Control
                                        type="text"
                                        value={item.order_product_review.review}
                                        placeholder="Write your review"
                                        disabled={true}
                                    />
                                </Form.Group>

                            </div>
                        ) : (
                            <div className="address-pd">
                                {/* <h6>Submit Your Review</h6>
                                <div>
                                    <Rating onClick={handleRating} initialValue={5} />
                                </div>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                    style={{ marginTop: "10px" }}
                                >
                                    <Form.Control
                                        type="text"
                                        onChange={(e) => setMessage(e.target.value)}
                                        placeholder="Write your review"
                                    />
                                </Form.Group>
                                <button
                                    className="review-submit"
                                    onClick={() => OrderReviewSubmit(item.id)}
                                >
                                    Submit
                                </button> */}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
};

export default CancelOrderDetails;
