import React from 'react';
import '../../../styles/Address.css';
import Checkbox from '@mui/material/Checkbox';

export default function Address() {
    return (
        <div>
            <div className='col-md-12 col-xs-12 nopadding'>
                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                    <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                    <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">Address </li>
                    </ol>
                </nav>
            </div>
            <div className='col-md-12 col-xs-12 addressBox '>
                <div className='col-md-6'>
                    <div className={`col-md-12 border margintop10`}>
                        <h3>Billing Address</h3>
                        <div className="borderBottom"></div>
                        <br />
                        <div className="col-md-12 nopadding">
                            <div className="col-md-6 paddingLeft">
                                <input className="input" placeholder="Enter First Name" />
                            </div>
                            <div className="col-md-6 paddingRight">
                                <input className="input" placeholder="Enter Last Name" />
                            </div>
                        </div>
                        <select className="input selectMargin" placeholder="Select Business Type" >
                            <option >Select Business Type</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                        <input className="input" placeholder="Enter Tax / Registration Detail" />
                        <input className="input" placeholder="Enter Full Address" />
                        <div className="col-md-12 nopadding">
                            <div className="col-md-6 paddingLeft">
                                <input className="input" placeholder="Country" />
                            </div>
                            <div className="col-md-6 paddingRight">
                                <input className="input" placeholder="State" />
                            </div>
                        </div>
                        <div className="col-md-12 nopadding">
                            <div className="col-md-6 paddingLeft">
                                <input className="input" placeholder="City" />
                            </div>
                            <div className="col-md-6 paddingRight">
                                <input className="input" placeholder="Zip / Postal Code" />
                            </div>
                            <br />
                            <div className="col-md-12 nopadding flex">
                                <Checkbox
                                    sx={{
                                        color: '#B12926',
                                        '&.Mui-checked': {
                                            color: '#B12926',
                                        },
                                        '& .MuiSvgIcon-root': { fontSize: 20 }
                                    }}
                                />
                                <div className='marginTop'>Shipping Address</div>
                            </div>
                            <div className="col-md-12 col-xs-12 nopadding">
                                <button className='address-button hover'>continue</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='col-md-12 border margintop10'>
                        <h3>Shipping Address</h3>
                        <div className='borderBottom'></div>
                        <br />
                        <div className="col-md-12 nopadding">
                            <div className="col-md-6 paddingLeft">
                                <input className="input" placeholder="Enter First Name" />
                            </div>
                            <div className="col-md-6 paddingRight">
                                <input className="input" placeholder="Enter Last Name" />
                            </div>
                        </div>
                        <select className="input selectMargin" placeholder="Select Business Type">
                            <option >Select Business Type</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                        <input className="input" placeholder="Enter Tax / Registration Detail" />
                        <input className="input" placeholder="Enter Full Address" />
                        <div className="col-md-12 nopadding">
                            <div className="col-md-6 paddingLeft">
                                <input className="input" placeholder="Country" />
                            </div>
                            <div className="col-md-6 paddingRight">
                                <input className="input" placeholder="State" />
                            </div>
                        </div>
                        <div className="col-md-12 nopadding">
                            <div className="col-md-6 paddingLeft">
                                <input className="input" placeholder="City" />
                            </div>
                            <div className="col-md-6 paddingRight">
                                <input className="input" placeholder="Zip / Postal Code" />
                            </div>
                            <br />
                            <div className="col-md-12 nopadding flex">
                                <Checkbox
                                    sx={{
                                        color: '#B12926',
                                        '&.Mui-checked': {
                                            color: '#B12926',
                                        },
                                        '& .MuiSvgIcon-root': { fontSize: 20 }
                                    }}
                                />
                                <div className='marginTop'>Shipping Address</div>
                            </div>
                            <div className="col-md-12 nopadding">
                                <button className='address-button hover'>continue</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}