import React, { useEffect, useState, useContext } from 'react';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SliderwithoutMenu from '../../SliderwithoutMenu';
import { Link } from 'react-router-dom';
import style from '../../../styles/maxDiscount.module.css';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../App';

export default function MaximunDiscount() {

  const [products, setProducts] = useState([]);
  const [error, setError] = useState([]);
  const token = localStorage.getItem('token');
  const [homeData, setHomeData] = useState([]);
  const navigate = useNavigate();
  const { item, setItem } = useContext(UserContext);
  let machineId = localStorage.getItem('MachineId');
  //console.log(machineId)
 const [cartdata, setcartdata] = useState([]);
  useEffect(() => {
    fetchMaximunProducts(0);
    getMachineId();
    fetchCount();
    FetchItemsAuth();
    fetchHomeWidgets();
  }, [])

  const fetchCount = async () => {
    if (!token) {
      const data = { "device_id": machineId }
      await axios.post(BASE_URL + '/api/get-cart-unauth', data).then(({ data }) => {
        setItem(data.data.cart.item)
        setcartdata(data.data.cart_details)
      })
    }
  }

  const FetchItemsAuth = async () => {
    if (token) {
      axios.get(BASE_URL + '/api/get-cart', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        setItem(data.data.cart.item)
        setcartdata(data.data.cart_details)
      })
    }
  }


  const fetchMaximunProducts = async () => {
    await axios.get(BASE_URL + '/api/max-discount-product/').then(({ data }) => {
      setProducts(data.data);
      // console.log(data.data)
    })
  }

  function handleSubmit(id, optionId) {
    setError('');
    axios.post(BASE_URL + '/api/store-cart', { "product_id": id, "product_option_id": optionId },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        FetchItemsAuth()
        if (data.success === true) {
          setError('')
          Swal.fire({
            text: data.meassge,
            icon: "success"
          })
          document.getElementById("loginpage").reset();
        }
        if (data.status === "error") {
          Swal.fire({
            text: data.meassge,
            icon: "error"
          })
        }
      }).catch(({ response }) => {
        setError(response.data.message)
        if (response.status_code === "422") {
          setError(response.data.message)
        }
      });
  }

  function getMachineId() {

    let machineId = localStorage.getItem('MachineId');

    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem('MachineId', machineId);
    }
  }

  function handleSubmitOffline(id, optionId) {

    setError('');
    axios.post(BASE_URL + '/api/store-cart-unauth', { "product_id": id, "product_option_id": optionId, "device_id": machineId }, {}).then(({ data }) => {
      fetchCount()
      if (data.success === true) {
        setError('')
        Swal.fire({
          text: data.meassge,
          icon: "success"
        })

        document.getElementById("loginpage").reset();
      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data.message)
      if (response.status_code === "422") {
        setError(response.data.message)
      }
    });
  }

  const fetchHomeWidgets = async () => {
    await axios.get(BASE_URL + '/api/homepagewidget').then(({ data }) => {
      setHomeData(data.data.data[0]);
      // console.log(data.data)
    })
  }
  const filtercartdata = (id) => {
    let d = cartdata.filter((e) => e.product_id === id);
    if (d.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
    {products.length ? 
    <div className={`col-md-12 col-sm-12 col-xs-12 ${style.container}`}>
      <div className='col-md-3 col-sm-12 col-xs-12 center'>
        <div className={style.discountCard} style={{ backgroundImage: `url(${BASE_URL+'/storage/'+homeData.image})`, width:'302px' }}>
          <div className={style.cardPadding}>
            <div className={style.cardinnerCard}>
              <div className={style.cardText}>
                <div className={style.BoldText}>{homeData && homeData.heading}</div><br />
                <div className={style.discountText} dangerouslySetInnerHTML={{ __html:homeData.content&&homeData.content}}></div>
               {homeData.url_txt && homeData.url && (
                <a href={homeData.url}><button className={`hover ${style.shopButton}`}>{homeData && homeData.url_txt} <img src="/Icons/blackarrow_right.png" className={style.arrow} />  </button> </a>
               )} 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`col-md-9 col-sm-12 col-xs-12 nopadding  ${style.productBox}`}>
        <SliderwithoutMenu name="Maximum" boldname="Discount">
          {products && products.map((data) => (
            <div>
             { console.log(data.short_description)}
              <Box
                detail={() => navigate('/product-details/' + data.slug)}
                name={data.name && data.name}
                img={BASE_URL + '/storage/' + data.image}
                discount={data.product_options && data.product_options[0].discount_percentage}
                actualPrice={data.product_options && data.product_options[0].mrp}
                discountPrice={data.product_options && data.product_options[0].price}
                discountpercentage={data.product_options && data.product_options[0].discount_percentage}
                onClick={() => token ? handleSubmit(data.product_options[0].product_id && data.product_options[0].product_id, data.product_options[0].id && data.product_options[0].id) : handleSubmitOffline(data.product_options[0].product_id && data.product_options[0].product_id, data.product_options[0].id && data.product_options[0].id)}
                exist={filtercartdata(data.product_options && data.product_options[0].product_id)}
              />
            </div>
          ))}
        </SliderwithoutMenu>
      </div>
    </div>
    : ''}
    </>
  )
}

export function Box({ discount, name, actualPrice, discountPrice, img, onClick, detail,exist,discountpercentage }) {
  // console.log(discription)
  return (

    <div className="col-md-9 col-sm-12 col-xs-12 nopadding-mobile" >
      <div className={`${style.card}`}>
        <div className={style.innerCard}>
          <div className={style.outerCard}>
            {/* <div className={style.mobileHidden}>
              {discount == '0' ? <br /> :
                <div className={style.discount}>{discount} %</div>
              } </div> */}

            <div className={style.imgContainer} onClick={detail}>
              <img src={img} className={style.img} />
            </div>

            <h4 className={style.name}>{name}</h4>
            {/* <p className={style.name}>{discription}</p> */}
            <div className={style.mobilename}>{name.length > 10 ? name.substring(0, 10) + "..." : name}</div>
            <div className={style.price}>
              <h5 className={style.actualPrice}> ₹ {actualPrice} </h5>
              <h5 className={style.discountPrice}> ₹ {discountPrice}</h5>
              
            </div>
            <h5 className={style.discountPrice1} >You Save {discountpercentage}% </h5>
            <div className={style.mobilePro}>
              <div className={style.discountPrice}>₹ {discountPrice}</div>
              <div className={style.mobilePrice}>MRP : <span className={style.actualPrice}>₹ {actualPrice}</span> {discount == '0' ? "" : <span>({discount}%off)</span>} </div>
            </div>

            <button className={`hover ${style.cartButton}`} onClick={onClick}>{exist == true ? "Added to Cart" :"Add to Cart"}</button>

          </div>
        </div>
      </div>
    </div>
  )
}