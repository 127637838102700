// import React, { useState,useContext } from "react";
// import { Tabs, Tab } from "@mui/material";
// import style from '../../styles/Sidebar.module.css'
// import { Nav } from "react-bootstrap";
// import HomeIcon from '@mui/icons-material/Home';
// import { useNavigate, Link } from "react-router-dom";
// import { UserContext } from "../../App";

// export default function App() {
//     const [tabvalue, setTabValue] = useState(0);
//     const {setorderdetailop, setorderdetailop1} = useContext(UserContext)
//     const handleTabsChange = (event, newValue) => {
//         setTabValue(newValue);
//     };

//     function Logout() {
//         localStorage.removeItem("token");
//         localStorage.removeItem("userdata");
//         navigate('/')
//     }

//     const navigate = useNavigate();
    
//     return (
//         <div>

//             <div className={`col-md-12 col-xs-12 nopadding ${style.container}`}>
//                 <div className="col-md-12 col-xs-12 col-sm-12 nopadding">
//                     <div
//                         className={`card`}
//                     >
//                         <Link className="sidebar-links" to="/dashboard" onClick={()=>setorderdetailop(false)}>
//                             <i class="fa fa-tachometer" aria-hidden="true" style={{ marginRight: '10px' }}></i> Dashboard
//                         </Link>
//                         <Link className="sidebar-links" to="/my-orders" onClick={()=>setorderdetailop1(false)} >
//                             <i class="fa fa-first-order" aria-hidden="true" style={{ marginRight: '10px' }}></i> My Orders
//                         </Link>

//                         <Link className="sidebar-links" to="/my-wishList">
//                             <i class="fa fa-heart" aria-hidden="true" style={{ marginRight: '10px' }}></i> My Wish List
//                         </Link>

//                         <Link className="sidebar-links" to="/my-address">
//                             <i className="fa fa-book" aria-hidden="true" style={{ marginRight: '10px' }}></i> My Address Book
//                         </Link>

//                         <Link className="sidebar-links" to="/change-password">
//                             <i class="fa fa-key" aria-hidden="true" style={{ marginRight: '10px' }}></i> Change Password
//                         </Link>
//                         <Link className="sidebar-links" to="/settings">
//                             <i class="fa fa-cog" aria-hidden="true" style={{ marginRight: '10px' }}></i> My Account Setting
//                         </Link>
//                         <Nav.Link className="sidebar-links" onClick={() => Logout()}>
//                             <i class="fa fa-arrow-circle-left" aria-hidden="true" style={{ marginRight: '10px' }}></i> Logout
//                         </Nav.Link>                       
//                     </div>

//                 </div>


//             </div>
//         </div>
//     );
// }
import React, { useState, useContext } from "react";
import { Tabs, Tab } from "@mui/material";
import style from '../../styles/Sidebar.module.css';
import { Nav } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from "../../App";
import HomeIcon from '@mui/icons-material/Home';

export default function App() {
    const [tabValue, setTabValue] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to toggle sidebar visibility
    const { setorderdetailop, setorderdetailop1 } = useContext(UserContext);
    
    const navigate = useNavigate();

    const handleTabsChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function Logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("userdata");
        navigate('/');
    }

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
    };

    return (
        <div className="mobile-view-drawer">
            {/* <button onClick={toggleSidebar}>Menu</button>  */}

            {/* {isSidebarOpen && ( // Conditionally render the sidebar */}
                <div className={`col-md-12 col-xs-12 nopadding ${style.container}`}>
                    <div className="col-md-12 col-xs-12 col-sm-12 nopadding">
                        <div className="card">
                            <Link className="sidebar-links" to="/dashboard" onClick={() => setorderdetailop(false)}>
                                <i className="fa fa-tachometer" aria-hidden="true" style={{ marginRight: '10px' }}></i> Dashboard
                            </Link>
                            <Link className="sidebar-links" to="/my-orders" onClick={() => setorderdetailop1(false)}>
                                <i className="fa fa-first-order" aria-hidden="true" style={{ marginRight: '10px' }}></i> My Orders
                            </Link>
                            <Link className="sidebar-links" to="/my-wishList">
                                <i className="fa fa-heart" aria-hidden="true" style={{ marginRight: '10px' }}></i> My Wish List
                            </Link>
                            <Link className="sidebar-links" to="/my-address">
                                <i className="fa fa-book" aria-hidden="true" style={{ marginRight: '10px' }}></i> My Address Book
                            </Link>
                            <Link className="sidebar-links" to="/change-password">
                                <i className="fa fa-key" aria-hidden="true" style={{ marginRight: '10px' }}></i> Change Password
                            </Link>
                            <Link className="sidebar-links" to="/settings">
                                <i className="fa fa-cog" aria-hidden="true" style={{ marginRight: '10px' }}></i> My Account Setting
                            </Link>
                            <Nav.Link className="sidebar-links" onClick={() => Logout()}>
                                <i className="fa fa-arrow-circle-left" aria-hidden="true" style={{ marginRight: '10px' }}></i> Logout
                            </Nav.Link>
                        </div>
                    </div>
                </div>
            {/* )} */}
        </div>
    );
}
