import React from 'react';
import style from '../../../styles/review.module.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone';
import { Tooltip } from "@mui/material";
import Radio from '@mui/material/Radio';


export default function Review() {
    return (
        <div>
            <div className='col-md-12 col-xs-12 nopadding'>
                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">Review order details </li>
                    </ol>
                </nav>
            </div>
            <div className={`col-md-12 col-xs-12 ${style.container}`}>
                <div className='col-md-8 col-md-12'>
                    <div className={`col-md-12 col-xs-12 ${style.ReviewHeading} `}>
                        Review
                    </div>
                    <div className='col-md-12 col-xs-12'>
                        <div className='col-md-12 col-xs-12'>
                            <div className='col-md-2 col-xs-2'>
                                <img src="/Image/Perfume1.jpg" alt="perfume" className={style.img} />
                            </div>
                            <div className='col-md-10 col-xs-10'>
                                <br />
                                <div className='flex space-between'>
                                    <div>
                                        <div className={style.name}>Perfume</div>
                                        <div className={style.quantity}>Product Qty  1</div>
                                    </div>
                                    <div className={style.price}>
                                        $30.00
                                    </div>
                                </div>
                                <div className={style.border}></div>
                                <div>Review</div>
                                <div className='flex'>
                                        <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                                        <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                                        <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                                        <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                                        <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                                    </div>
                                    <br />
                                    <label for="exampleFormControlTextarea1">Review</label>
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Give Review'></textarea>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='col-md-4 col-md-12'>
                    <div className={style.DetailsContainer}>
                    <h4>Order Details</h4>
                        <div className={style.border}></div>

                        <div className={`flex space-between ${style.black}`}>
                            <div>Subtotal:</div>
                            <div>₹160.00</div>
                        </div>
                        <div className={style.border}></div>
                        <div>Shipping:</div>
                        <br />
                        <div className="flex space-between">
                            <FormControlLabel value="Free Shipping" control={<Radio sx={{ padding: 1 }} />} label={
                                <div className="flex"><h6>Free Shipping</h6>
                                    <Tooltip title={<h6>Delivery in 7-10 Days</h6>} arrow>
                                        <div className={style.questionIcon}><HelpOutlineTwoToneIcon /></div>
                                    </Tooltip></div>
                            } />
                            <div>₹160.00</div>
                        </div>
                        <div className="flex space-between">
                            <FormControlLabel value="Standard" control={<Radio sx={{ padding: 1 }} />} label={
                                <div className="flex"><h6>Standard</h6>
                                <Tooltip title={<h6>Delivery in 3-5 Days"</h6>} arrow>
                                    <div className={style.questionIcon}><HelpOutlineTwoToneIcon /></div>
                                </Tooltip></div>
                            } />
                            <div>₹160.00</div>
                        </div>
                        <div className="flex space-between">
                            <FormControlLabel value="Express" control={<Radio sx={{ padding: 1 }} />} label={
                                <div className="flex"><h6>Express</h6>
                                <Tooltip title={<h6>Delivery in 2-3 Days"</h6>} arrow>
                                    <div className={style.questionIcon}><HelpOutlineTwoToneIcon /></div>
                                </Tooltip></div>
                            } />
                            <div>₹160.00</div>
                        </div>
                    
                        <div >
                            <div className={style.marginTop}>Estimate for your Country</div>
                            <a className='small'>change address</a>
                            </div>
                        <div className={style.border}></div>
                        <div className="flex space-between">
                            <div>Total</div>
                            <div>₹160.00</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}