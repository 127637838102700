import React,{useState, useEffect} from 'react';
import style from '../../../styles/footerlinks.module.css';
import axios from 'axios';
import { BASE_URL } from '../../../config';

function CookiePolicy(){
  
  const [ policy, setPolicy ] = useState('')

  useEffect(() => {
    fetchPolicy()
  },[])

  const fetchPolicy = async() => {
    await axios.get(BASE_URL+"/api/policy/cookie_policy").then(({data}) => {
      setPolicy(data.data)
    })
  }

    return(
        <div>
            <div className='col-md-12 col-xs-12 nopadding'>
            <nav aria-label="breadcrumb" >
          <ol className='breadcrumb'>
          <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li className="breadcrumb-item-active" aria-current="page">Cookie Policy</li>
          </ol>
        </nav>
                </div>
                <br /><br />
        <div className={style.padding30}>
            <h1 className={style.heading}>COOKIE POLICY</h1> <br />
            <div className="terms-condition-content" dangerouslySetInnerHTML={{__html: policy.content && policy.content}}></div>
        </div>
        </div>
    )
}

export default CookiePolicy;