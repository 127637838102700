import React, { useEffect, useState, useContext } from 'react';
import style from '../../../styles/Banner.module.css';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import { Link } from 'react-router-dom';


function IzharsonBanner() {

  const [homeData, setHomeData] = useState([]);

  useEffect(() => {
    fetchHomeWidgets();
  }, [])


  const fetchHomeWidgets = async () => {
    await axios.get(BASE_URL + '/api/homepagewidget').then(({ data }) => {
      setHomeData(data.data.data[1]);
      // console.log(data.data)
    })
  }


  return (
    <div className='col-md-12 col-xs-12 nopadding'>
      <div className={`col-md-12 col-xs-12 mt-2 ${style.bannerContainer}`} style={{ backgroundImage: `url(${BASE_URL + '/storage/' + homeData.image})` }}>
        <div className='col-md-6 col-xs-12'>
          <div className={style.mensPerfume}><h2>{homeData.heading && homeData.heading}</h2></div>
          <div className={style.bannerDetail} dangerouslySetInnerHTML={{ __html: homeData.content && homeData.content }}></div>
          <div className='bannerBtn'>
            {/* <a href={homeData.url}> <button className={`hover ${style.button}`}>{homeData.url_txt}</button> </a> */}
            <Link to="/our-products" state={{ data: 0 }}><button className={`hover center ${style.buttonBanner2}`}>Shop Now
              {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 15, position: 'relative', top: '5px' }}>
                <path d="M14.0575 4.74121L13.1737 5.62508L16.9236 9.37496H0.625V10.625H16.9234L13.1737 14.3748L14.0575 15.2586L19.3163 9.99992L14.0575 4.74121Z" fill="white" />
              </svg> */}
            </button></Link>
            {/* <button className={`hover ${style.button}`}>{homeData.url_txt}<img src="/Icons/whitearrow_right.png" className='arrow-icon' style={{ height: '10px', width: '20px', marginLeft: 20, marginTop: 0 }} /></button>  */}

          </div>
        </div>
      </div>
    </div>
  )
}

export default IzharsonBanner;