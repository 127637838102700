import React, { useState, useEffect } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import style from '../../../styles/Banner.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../config';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, EffectCube } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import Loader from '../../Loader';
function IzharsonBanner() {

    const [slider, setSlider] = useState([]);
    const navigate = useNavigate();
    const [loading,setloading] = useState(true)

    useEffect(() => {
        getSliderData();
    }, [])

    const getSliderData = async () => {
        await axios.get(BASE_URL + '/api/sliders').then(({ data }) => {
            setSlider(data.data);
            setloading(false)
            // console.log('gyfgeyfuh', data.data)
        })
    }


    return (
        <>
        {loading ?(
<Loader />
        ):(
            <>
            <div className='col-md-12 col-xs-12 nopadding hiddenMobile'>
                <Carousel
                    showThumbs={false}
                    // autoPlay={true}
                    interval={2500}
                    showStatus={false}

                >
                    {slider && slider.map((data) => (
                        <div>
                            <div className={`col-md-12 col-sm-12 col-xs-12 nopadding`} style={{
                                backgroundImage: `url(${BASE_URL + '/storage/' + data.image})`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                height: "480px",
                                opacity: 0.9,
                            }}>
                                <div className={`col-md-12 col-sm-12 col-xs-12 nopadding ${style.bannerCont}`}>
                                    <div className={style.textNewHead} style={{ color: data.color }}><h1>{data.title && data.title}</h1></div>
                                    <div className={style.textNew} style={{ color: data.color }}><h2>{data.sub_title && data.sub_title}</h2></div>
                                    <div className={style.bannerNewDetails} style={{ color: data.color }}>{data.content && data.content} </div>
                                    <div className='col-md-12 col-xs-12 bannerBtn nopadding mt-2'>
                                        <Link to={data.button_link} state={{ data: 0 }}><button className={`hover center ${style.button}`}>Shop Now
                                            {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 15, position: 'relative', top: '5px' }}>
                                                <path d="M14.0575 4.74121L13.1737 5.62508L16.9236 9.37496H0.625V10.625H16.9234L13.1737 14.3748L14.0575 15.2586L19.3163 9.99992L14.0575 4.74121Z" fill="white" />
                                            </svg> */}
                                        </button></Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
            <div className='hiddenDesktop'>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, EffectCube]}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    effect={"cube"}
                    cubeEffect={{
                    }}
                    style={{ padding: 0, margin: 0 }}
                >
                    {slider.map((data) => (
                        <SwiperSlide key={data.image}>
                            <div>
                                <div className={`col-md-12 col-sm-12 col-xs-12 nopadding mt-2`} style={{
                                    backgroundImage: `url(${BASE_URL + '/storage/' + data.image})`,
                                    // backgroundPosition: 'right 80px',
                                    
                                    backgroundRepeat: 'round',
                                    backgroundSize: 'cover'
                                }}>
                                    <div className={`col-md-12 col-sm-12 col-xs-12 nopadding ${style.bannerCont}`}>
                                        <div className={style.textNewHead} style={{ color: data.color }}>{data.title && data.title}</div>
                                        <div className={style.textNew} style={{ color: data.color }}>
                                            {data.sub_title && data.sub_title.length > 30 ? data.sub_title.substring(0, 25) + '...' : data.sub_title}
                                        </div>
                                        <div className={style.bannerNewDetails} style={{ color: data.color }}>
                                            {data.content && data.content.length > 50 ? data.content.substring(0, 50) + '...' : data.content}
                                        </div>
                                        <div className='col-md-12 col-xs-12 bannerBtn nopadding'>
                                            <Link to={data.button_link} state={{ data: 0 }}><button className={`hover center ${style.button}`}>Shop Now
                                                <svg width="10" height="10" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 5, position: 'relative', top: '2px' }}>
                                                    <path d="M14.0575 4.74121L13.1737 5.62508L16.9236 9.37496H0.625V10.625H16.9234L13.1737 14.3748L14.0575 15.2586L19.3163 9.99992L14.0575 4.74121Z" fill="white" />
                                                </svg>
                                            </button></Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            </>
        )}
            
        </>
    )
}

export default IzharsonBanner;