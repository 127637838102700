import React, { useRef, useEffect, useState } from 'react';
import ProductBox from './ProductBox/itemBox';
import style from '../styles/NewSlider.module.css';
import { BASE_URL } from '../config';
import axios from 'axios';

const sideScroll = (
  element,
  speed,
  distance,
  step
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

const dataScroll = (
  element,
  speed,
  distance,
  step
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

export default function Slider({ product, children, name, boldName,productData }) {


  const contentWrapper = useRef(null);

  const dataWrapper = useRef(null);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchproductcategory()
  }, [])

  async function fetchproductcategory() {
    axios.get(BASE_URL + '/api/product-categories/', {
    }).then(({ data }) => {
      setCategories(data.data);
    })
  }


  return (
    <div className='col-md-12 col-xs-12 '>
      <div className='col-md-12 col-xs-12 nopadding new-arrivals-slider'>
        <div className='col-md-7 col-xs-12 nopadding '>
          <div className='flex'>
            <div className={style.name}> {name} </div>
            <div className={style.boldName} >  {boldName}</div>
          </div>
        </div>
        <div className='col-md-5 col-xs-12 nopadding'>
          <div className='flex mt-1'>
            <div className={`pointer hover ${style.ScrollButton}`} onClick={() => { dataScroll(dataWrapper.current, 25, 100, -10); }}>
              <img src="/Icons/left-arrow.svg" />
            </div>
            <div className={`new-slider-scroll ${style.mt} ${style.contentWrapper}`} ref={dataWrapper}>
              <div className="flex">
                <div className={style.AllCat} onClick={() => product(0)} >All</div>
                {categories.map((data) => (
                  <div className={`hover ${style.menuItem}`} eventKey={data.id && data.id} onClick={() => product(data.id)}>
                    {data.name && data.name}
                  </div>
                ))}
              </div>
            </div>
            <div className={`pointer hover ${style.ScrollButton}`} onClick={() => {
              dataScroll(dataWrapper.current, 25, 100, 10);
            }}>
              <img src="/Icons/right-arrow.svg" />

            </div>
          </div>
        </div>
      </div>
      <div className='col-md-12 col-xs-12 nopadding'>
        {productData && productData.length ? 
        <div className="flex mt-2">
          <div onClick={() => { sideScroll(contentWrapper.current, 25, 100, -10); }}>
            <img src="/Image/LeftArrow.png" className={`pointer hover ${style.Scroller}`} />
          </div>

          <div className={style.contentWrapper} ref={contentWrapper}>
            {children}
          </div>
          <div onClick={() => {
            sideScroll(contentWrapper.current, 25, 100, 10);
          }}>
            <img src="/Image/RightArrow.png" className={`pointer hover ${style.Scroller}`} />
          </div>

        </div>
        : 
        <h1 className='center mt-5'>No Products Available for this Category</h1>
        }
      </div>
    </div>
  )
}