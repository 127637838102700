import React, { useState, useEffect } from 'react';
import style from '../../../styles/aboutus.module.css'
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Testimonial from '../Homepage/Testimonial';
import About from './About';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import { Helmet } from 'react-helmet';


function AboutUsDetail() {

  const [expanded, setExpanded] = React.useState(false);
  const [aboutdata, setaboutdata] = useState('');
  const [faqs, setFaqs] = useState();
  const [faqs2, setFaqs2] = useState();
  const [count, setCount] = useState();


  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    fetchaboutus();
    fetchFaqs();
    fetchFaqs2();
  }, [])

  const fetchaboutus = async () => {
    await axios.get(BASE_URL + '/api/about-us').then(({ data }) => {
      setaboutdata(data.data);

    })
  }

  const fetchFaqs = async () => {
    await axios.get(BASE_URL + '/api/faqs').then(({ data }) => {
      setFaqs(data.data);
      console.log(data.data)
      var leftSide = data.data.splice(0, Math.floor(data.data.length / 2));
      setCount(leftSide)
    })
  }

  const fetchFaqs2 = async () => {
    await axios.get(BASE_URL + '/api/faqs').then(({ data }) => {
      // setFaqs2(data.data);
      console.log(data.data)
      var leftSide = data.data.splice(count, Math.floor(data.data.length / 2));
      // console.log()
      setFaqs2(leftSide);
    })
  }



  return (
    <div className='col-md-12 col-xs-12 nopadding'>
      <Helmet>
                <title>About Izharsons Perfumers - Leading Luxury Perfume Makers in India</title>
                <meta name="description" content="Read the history of Izharsons Perfumers, India's premier luxury perfume manufacturer. Explore our dedication to creating excellent, timeless perfumes through a combination of tradition and innovation." data-react-helmet="true" />
                <meta name="keywords" content="Luxury perfumes online in India, buy luxury perfumes India, premium fragrances India, online perfume store India, best luxury perfumes, Izharsons Perfumers, designer perfumes India, perfume shop online India, luxury fragrance brands lucknow " data-react-helmet="true" />
                <link rel="canonical" href="https://izharsonperfumers.com/about" />      
            </Helmet>
       <div className='col-md-12 col-xs-12 nopadding'>
            <nav aria-label="" >
              <ol className='breadcrumb'>
                <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                <li className="breadcrumb-item-active" aria-current="page">About Us</li>
              </ol>
            </nav>
          </div>
      
      <div className={`col-md-12 col-xs-12 ${style.content}`}>
        <div className={`col-md-12 col-xs-12 ${style.aboutHeading}`}><h1 style={{fontWeight:"700"}}>{aboutdata.title && aboutdata.title}</h1></div>
        <div className='col-md-12 col-xs-12 mt-4'>

          <div className={`col-md-6 col-xs-12 col-sm-12 p-0 ${style.contentData}`} style={{marginTop:"0px", padding:"0px", paddingRight:"15px"}}>
            <h2 style={{fontWeight:"600", marginTop:"0px"}}>Our Story and Values</h2>
            {aboutdata.description && aboutdata.description}

          </div>
          <div className='col-md-6'>
            <img src={BASE_URL + '/storage/' + aboutdata.image} className={style.aboutImg} />
          </div>
        </div>

        <div className={`col-md-12 col-xs-12 col-sm-12 ${style.contentData} ${style.p15}`} dangerouslySetInnerHTML={{ __html: aboutdata.content && aboutdata.content }}></div>
        <div>
        </div>


      </div>
      {/* <div className={`col-md-6 col-xs-12 ${style.content}`} style={{paddingRight:"0px"}}>
        {faqs && faqs.map(accordion => {
          const { id, question, answer } = accordion;
          return (
            <Accordion
              expanded={expanded === id}
              key={id}
              onChange={handleChange(id)}
              className={style.AccordionMargin}
              style={{boxShadow:'none', borderRadius:"10px", border:"1px solid #80808040"}}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <h5 className="about-accordian">{question && question}</h5>
              </AccordionSummary>
              <AccordionDetails >
                <div className="about-accordian-content">{answer && answer}</div>
              </AccordionDetails>
            </Accordion>
          );
        })}

      </div>
      <div className={`col-md-6 col-xs-12 ${style.content}`} style={{paddingLeft:"0px"}}>
        {faqs2 && faqs2.map(accordion => {
          const { id, question, answer } = accordion;
          return (
            <Accordion
              expanded={expanded === id + 1 -1}
              key={id}
              onChange={handleChange(id + 1 -1)}
              className={style.AccordionMargin}
              style={{boxShadow:'none', borderRadius:"10px", border:"1px solid #80808040"}}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <h5 className="about-accordian">{question && question}</h5>
              </AccordionSummary>
              <AccordionDetails >
                <div className="about-accordian-content">{answer && answer}</div>
              </AccordionDetails>
            </Accordion>
          );
        })}

      </div> */}
      <Testimonial />
      <About />
    </div>
  )
}

export default AboutUsDetail;