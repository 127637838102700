import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import style from '../../../styles/emailbanner.module.css';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import Swal from 'sweetalert2';
import { Form } from 'react-bootstrap'

function EmailBanner() {

  const [homeData, setHomeData] = useState([]);
  const [EmailData, setEmailData] = useState([]);
  const [email, setemail] = useState('');
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    fetchHomeWidgets();
  }, [])

  const fetchHomeWidgets = async () => {
    await axios.get(BASE_URL + '/api/homepagewidget').then(({ data }) => {
      setHomeData(data.data.data[2]);
      setEmailData(data.data.data[6])
      // console.log(data.data)
    })
  }

  function submitSubscribe() {
    axios.post(BASE_URL + '/api/subscribers', { email: email }).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: data.message
      });
      document.getElementById("subscribe").reset();
    }).catch(({ response }) => {
      setErrors(response.data.message.email)
      if (response.status === 422) {
        Swal.fire({
          text: response.data.message.email,
          icon: "error"
        })
      }
    })
  }

  const OnEmailValidation = e => {
    const { value } = e.target;

    const re = /^[A-Za-z@.1-9]+$/;
    if (value === "" || re.test(value)) {
      setemail(value);
    }
  }


  return (
    <div className={`col-md-12 col-xs-12 col-sm-12 ${style.container}`}>
      {/* <div className={`col-md-6 col-xs-12 col-sm-12  ${style.mtmobile}`}>
        <div className={style.banner2} style={{ backgroundImage: `url(${BASE_URL + '/storage/' + homeData.image})` }}>
          <div className={style.bannerPadding}>
            <div className={`whiteText ${style.dealText}`}>{homeData.heading && homeData.heading}</div>
           
            <div className={`whiteText mt-1 ${style.Emailtext}`} dangerouslySetInnerHTML={{ __html: homeData.content && homeData.content.length > 150 ? homeData.content.substring(0, 150) + "..." : homeData.content }}></div>
            <div className={style.center}>
                       <a href={homeData.url} className='hover'> <button className={style.learnMoreButton}>Learn More</button> </a>
                    </div>
          </div>
        </div>
      </div> */}
      <div className={`col-md-6 col-xs-12 col-sm-12 `}>
        <div className={style.banner2} style={{ backgroundImage: `url(${BASE_URL + '/storage/' + homeData.image})`,height:'300px',backgroundRepeat:'no-repeat', backgroundSize:'cover' }}>
         <div className={style.backgroundcolorset}>
         <div className='vertically-center-email'>
            <div className={`whiteText ${style.dealText}`}><h3>{homeData.heading && homeData.heading}</h3></div>
           
            <div className={`whiteText mt-1 ${style.Emailtext}`} dangerouslySetInnerHTML={{ __html: homeData.content }}></div>
            {/* <div className={`whiteText ${style.Emailtext}`}></div> */}
            {/* <div className={style.center}>
                       <a href={homeData.url} className='hover'> <button className={style.learnMoreButton}>Learn More</button> </a>
                    </div> */}
          </div>
         </div>
          
        </div>
      </div>
      <div className={`col-md-6 col-xs-12 col-sm-12  ${style.mtmobile}`}>
        <div className={style.banner2} style={{ backgroundImage: `url(${BASE_URL + '/storage/' + EmailData.image})`,height:'300px',backgroundRepeat:'no-repeat', backgroundSize:'cover' }}>
        <div className={style.backgroundcolorset}>
          <div className='vertically-center'>
            <div className={`whiteText ${style.dealText}`}><h3>{EmailData.heading && EmailData.heading}</h3></div>
            
            <div className={`whiteText mt-1 ${style.Emailtext}`} dangerouslySetInnerHTML={{ __html: EmailData.content && EmailData.content }}></div>
            {/* <div className={`whiteText ${style.Emailtext}`}></div> */}
            <Form id="subscribe">
              <div className={style.inputContainer}>
                <input placeholder="Write your email" className={style.input} onChange={OnEmailValidation} value={email} />
                <div className={`pointer ${style.mailCon}`} onClick={submitSubscribe}><MailOutlineRoundedIcon sx={{ fontSize: 25, marginTop: '4px', color: '#fff' }} /></div>
              </div>
            </Form>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailBanner;