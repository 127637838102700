import React from 'react';
import style from '../../../styles/Invitefriend.module.css';
import Sidebar from '../../Sidebar/Sidebar';



export default function Invite() {
    return (
        <div className={`col-md-12 col-xs-12 nopadding ${style.container}`}>
            <div className='col-md-12 col-xs-12 nopadding'>
                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">

                            Invite Friend

                        </li>
                    </ol>
                </nav>
            </div>
            <div className={`col-md-3 col-xs-12 nopadding`}>
        <Sidebar />
      </div>
            <div className={`col-md-9 col-xs-12 nopadding ${style.header}`}>
                <h3 className={style.heading}>
                    Invite Friends
                </h3>
            </div>
            <div className='col-md-9 col-xs-12 nopadding'>
                <div className='col-md-6 col-xs-12'>
                    <img src="/Image/aw.png" className={style.img} />
                </div>
                <div className='col-md-6 col-xs-12'>
                    <div className={`flex ${style.referal}`}>
                        <div className={style.referalText}>
                            Your referal code is :
                        </div>
                        <div className={style.referalCode}>
                            ASDFGHJKL
                        </div>
                    </div>
                    <div className={style.codeBox}>
                        <input className={style.input} />
                        <div className={style.copyBotton}> Copy Code </div>
                    </div>
                </div>
            </div>
        </div>
    )
}