import React, { useEffect, useState } from 'react';
import style from '../../../styles/aboutus.module.css';
import { BASE_URL } from '../../../config';
import axios from 'axios';


function About() {
    const [news, setNews] = useState([]);
    const [support, setSupport] = useState([]);
    const [store, setStore] = useState([]);
    const [imageUrl, setImagaUrl] = useState([]);

    useEffect(() => {
        fetchHomeWidgets();
    }, [])


    const fetchHomeWidgets = async () => {
        await axios.get(BASE_URL + '/api/homepagewidget').then(({ data }) => {
            setNews(data.data.data[8]);
            setSupport(data.data.data[3]);
            setStore(data.data.data[7]);
            setImagaUrl(data.data.image_base_url)
        })
    }
    return (
        <div className={`col-md-12 col-xs-12 ${style.AboutContainer}`}>
            <div className="col-md-4 col-sm-4 col-xs-12">
                <div className={style.box}>
                    <img src={imageUrl + store.image} className={style.storeImg} />
                    <div className={`mt-1 ${style.headingAbout}`}><h4>{store.heading && store.heading}</h4></div>
                    <div className={`mt-1  ${style.data}`} dangerouslySetInnerHTML={{ __html: store.content && store.content }}></div>
                    <a  href={store.url}> <div className={`hover ${style.directionText}`}>{store && store.url_txt}</div></a>
                </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
                <div className={style.box}>
                    <img src={imageUrl + news.image} className={style.newsImg} />
                    <div className={`mt-1 ${style.headingAbout}`}><h4>{news.heading && news.heading}</h4></div>
                    <div className={`mt-1 ${style.data}`} dangerouslySetInnerHTML={{ __html: news.content && news.content }}></div>
                    <a href={news.url}><div className={`hover ${style.directionText}`}>{news && news.url_txt}</div></a>
                </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
                <div className={style.box}>
                    <img src={imageUrl + support.image} className={style.supportImg} />
                    <div className={`mt-1 ${style.headingAbout}`}><h4>{support.heading && support.heading}</h4></div>
                    <div className={`mt-1 ${style.data}`} dangerouslySetInnerHTML={{ __html: support.content && support.content }}></div>
                    <a href={support.url}><div className={`hover ${style.directionText}`}>{support && support.url_txt}</div></a>
                </div>
            </div>
        </div>
    )
}

export default About;