import React, { useState, useEffect,useContext } from 'react';
// import style from '../../../styles/Changepassword.module.css';
import { Row, Container, Col, Form, Dropdown, ProgressBar } from 'react-bootstrap';
import Slider from "react-slick";
import { BASE_URL } from '../../../config';
import axios from 'axios';
import Swal from "sweetalert2";
import ReactStars from "react-stars";
import style from '../../../styles/home.module.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Tab, Tabs, Modal, Box, Button } from '@mui/material';
import Sidebar from '../../Sidebar/Sidebar'
import MainOrderDetail from './MainOrderDetails';
import DeliveredOrderDetail from './DeliveredOrderDetails';
import CancelOrderDetails from './CancelOrderDetails';
import { UserContext } from '../../../App';

const Star = ({ starId, marked }) => {
    const [orderDetails, setOrderDetails] = useState(false);
   
    return (
        <span
            star-id={starId}
            role="button"
            className="star-style"
        >
            {marked ? "\u2605" : "\u2606"}
        </span>
    );
};



const reasonData = [
    {
        id: '1',
        reason: 'Reason 1'
    },
    {
        id: '2',
        reason: 'Reason 2'
    },
    {
        id: '3',
        reason: 'Reason 3'
    },
    {
        id: '4',
        reason: 'Reason 4'
    },
    {
        id: '5',
        reason: 'Reason 5'
    }
]

const reasonCategoryData = [
    {
        id: '1',
        reason: 'Category 1'
    },
    {
        id: '2',
        reason: 'Category 2'
    },
    {
        id: '3',
        reason: 'Category 3'
    },
    {
        id: '4',
        reason: 'Category 4'
    },
    {
        id: '5',
        reason: 'Category 5'
    }
]

const Style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    // bgcolor: 'background.paper',
    fontSize: 15,
    boxShadow: 24,
    p: 2,
};



export default function AccountOrder(props) {
    const { MyOrderClose } = useParams();
    const [tabvalue, setTabValue] = useState(0);
    const location = useLocation();
    console.log(location, "=============")
    const handleTabsChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const { state: state1 } = useLocation();

    console.log(state1, "state ---->");
    return (
        <div className={`col-md-12 col-xs-12 nopadding `}>
            <div className='col-md-12 col-xs-12 nopadding'>
                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">
                            My Orders
                        </li>
                    </ol>
                </nav>
            </div>
            <div className={`col-md-3 col-xs-12 nopadding `}>
                <Sidebar />
            </div>
            <div className={`col-md-9 col-xs-12 col-sm-12 no-border ${style.container}`} >
                <div className={`col-md-12 col-xs-12 nopadding ${style.header}`}>
                    <h3 className={style.heading}>
                        My Order
                    </h3>
                </div>
                <div className='col-md-12 col-xs-12 col-sm-12 pd-1'>
                    <Tabs
                        onChange={handleTabsChange}
                        value={tabvalue}
                        indicatorColor="red"
                        textColor="secondary"
                        className={style.TabBorder}
                    >
                        <Tab label="Active Orders" sx={{ fontSize: 14, fontWeight: 'bold' }} />
                        <Tab className="Delivered Orders" label="Delivered Orders" sx={{ fontSize: 14, fontWeight: 'bold' }} />
                        <Tab className="Cancelled Orders" label="Cancelled Orders" sx={{ fontSize: 14, fontWeight: 'bold' }} />
                    </Tabs>
                    {tabvalue === 0 && <div className={style.TabContent}><ActiveOrder /></div>}
                    {tabvalue === 1 && <div className={style.TabContent}><DeliveredOrder /></div>}
                    {tabvalue === 2 && <div className={style.TabContent}><CancelledOrder /></div>}
                </div>
            </div>
        </div>
    )
}


export const ActiveOrder = (props) => {

    const [activeData, setActiveData] = useState([]);
    const token = localStorage.getItem('token');
    const [orderDetails, setOrderDetails] = useState(false);
    const [orderDetailsData, setOrderDeatilsData] = useState({})
    const [cancelID, setCancelID] = useState({});
    const [reload, setreload] = useState(true);
    const {orderdetailop1, setorderdetailop1} = useContext(UserContext)

    const handleOrderDetails = (i) => {
        setOrderDetails(true)
        setorderdetailop1(true)
        setOrderDeatilsData(i);
        console.log(i, "order details ");
    }

    const cancelOrder = (id) => {
        setCancelID(id)
    }

    const handleOrdertable = () => {
        setOrderDetails(false)
        setorderdetailop1(false)
    }
    console.log(props, "state");

    useEffect(() => {
        ActiveOrderData();
        handleOrdertable();
    }, [reload])

    const ActiveOrderData = async () => {
        axios.post(BASE_URL + '/api/orders', { "order_status": "active" }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setActiveData(data.data);
        })
    }

    console.log(activeData, "order data today");
    return (
        <div>
            {activeData && activeData.length ?
                <div className='col-md-12 col-xs-12 col-sm-12 pd-1'>
                    {orderdetailop1 ?
                        <div className='order-details-section'>
                            <div className='flex cancel-order-section'>
                                <h4>
                                    <i class="fa fa-arrow-left" aria-hidden="true" onClick={handleOrdertable}></i> Back</h4>
                            </div>
                            <MainOrderDetail
                                orderDetailsData={orderDetailsData}
                                setreload={setreload}
                                reload={reload}
                                handleOrdertable={handleOrdertable}
                            />
                        </div>
                        :
                        <div className="orders-grid order-details-section">
                            <div className='table-responsive mt-2 table-scroll-y-axis'>
                                <table className="table order-table-scroll">
                                    <thead className="table-header">
                                        <tr>
                                            <th scope="col"> Date & Time </th>
                                            <th scope="col"> Image </th>
                                            <th scope="col">Shipping To</th>
                                            <th scope="col">Order Amount</th>
                                            <th scope="col">Order ID</th>
                                            <th scope="col"> Payment Status </th>
                                            <th scope="col"> Order Status </th>
                                            <th scope="col"> Action </th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-body table-bg'>
                                        {activeData && activeData.map(i => (
                                            <tr>
                                                <th><div className={style.dateTime}>{i.order_date_time && i.order_date_time}</div></th>
                                                <td><img src={BASE_URL + '/storage/' + i?.order_details[0]?.product.image} width={40} /></td>
                                                <td><div className={style.order}>{i.address && i.address}</div></td>
                                                <td><div className={style.invoice}>&#8377; {i.order_amount && i.order_amount}</div></td>
                                                <td><div className={style.bill}>{i.order_number && i.order_number}</div></td>
                                                <td><div className={style.bill}>{i.payment_status && i.payment_status}</div></td>
                                                <td><div className={style.bill}>{i.order_status && i.order_status}</div></td>
                                                <td><i class="fa fa-eye" onClick={() => handleOrderDetails(i)} ></i></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div> : <h1 className='center p100' >No Bookings Available</h1>}
        </div>
    )
}

export const DeliveredOrder = () => {
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [trackButton, setTrackButton] = useState("");
    const [requestButton, setRequestButton] = useState("Request Cancellation");
    const [returnButton, setReturnButton] = useState("Return");
    const [deliveredData, setDeliveredData] = useState([]);
    const token = localStorage.getItem('token');
    const [showModal, setShowModal] = useState();
    const [canReason, setCanReason] = useState();
    const [error, setError] = useState([]);
    const [detailedReason, setDetailedReason] = useState();
    const [reasonCategory, setReasonCategory] = useState("");
    const [returnReason, setReturnReason] = useState("");
    const [returnModal, setReturnModal] = useState();
    const [trackModal, setTrackModal] = useState();
    const [trackData, setTrackData] = useState();
    const [trackStatus, setTrackStatus] = useState();
    const [productReview, setProductReview] = useState();
    const [orderid, setOrderId] = useState();
    const [orderdetailid, setOrderDetailId] = useState();
    const [cancelReason, setCancelReason] = useState([]);
    const [rating, setRating] = useState();
    const [selection, setSelection] = useState(0);
    const [DeliveredDetails, setDeliveredDetails] = useState(false);
    const [DeliveredDetails1, setDeliveredDetails1] = useState({});
    const [orderDetailsData, setOrderDeatilsData] = useState({})


    const handleOrderDetails = (i) => {
        setDeliveredDetails(true)
        setDeliveredDetails1(i);
        console.log(i, DeliveredDetails, DeliveredDetails1, "order details true");
    }

    const handleOrdertable = () => {
        setDeliveredDetails(false) 
    }

    const settings = {
        dots: true,
        infinite: false,
        autoplay: false,
        arrows: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 400,
            breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false
            }
        }]
    };

    console.log(orderid, "order");
    const hoverOver = event => {
        let starId = 0;
        if (event && event.target && event.target.getAttribute("star-id")) {
            starId = event.target.getAttribute("star-id");
        }
        setSelection(starId);
    };
    useEffect(() => {
        DeliveredOrderData()
        featchCancelData();
    }, [])

    const DeliveredOrderData = async () => {
        axios.post(BASE_URL + '/api/orders', { "order_status": "Delivered" }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setDeliveredData(data.data);
            // console.log("de", data.data)
        })
    }

    const featchCancelData = async () => {
        await axios.post(BASE_URL + '/api/reason', {
            "type": "cancelled",
            "category": "e-commerce"
        })
            .then(({ data }) => {
                setCancelReason(data.data);
            })
    }


    function trackOrder(id) {
        setTrackModal(true)
        setError('');
        axios.get(BASE_URL + '/api/track-order-status/' + id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setTrackData(data.data)
            if (data.status === "success") {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                document.getElementById("otherService").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    function OrderCancel(id) {
        setError('');
        axios.post(BASE_URL + '/api/cancel-order', {

            "order_id": id,
            "reason": detailedReason,
            "reason_id": reasonCategory

        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setShowModal(false)
            if (data.status === "success" && true) {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                document.getElementById("otherService").reset();
            }
            if (data.status === false) {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    const [file, setFile] = useState([]);

    const handleOnChange = e => {
        setFile([...file, ...e.target.files]);
    };

    function OrderReturn(id, detailId) {
        setError('');
        const formData = new FormData();
        file.forEach((image) => {
            formData.append('image[]', image);
        });
        formData.append('order_id', id);
        formData.append('return_reason', returnReason);
        formData.append('reason_id', reasonCategory);
        formData.append('order_detail_id', detailId);
        axios.post(BASE_URL + '/api/return-order', formData, {
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {

            setShowModal(false)
            if (data.status === true) {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                // navigate("/")
                // document.getElementById("otherService").reset();
            }
            if (data.status === false) {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            if (response && response.data && response.data.status_code == 422) {
                setError(response.data.message)
            }
        });
    }

    function OrderReviewSubmit() {
        setError('');
        axios.post(BASE_URL + '/api/submit-order-review', {
            "order_id": orderid,
            "rating": rating,
            "review": message,
            "order_detail_id": orderdetailid
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {

            setProductReview(false)
            if (data.success === true) {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                // navigate("/")
                document.getElementById("otherService").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    const proceedOrderDetails = (id) => {
        navigate('/order-detail',
            {
                state: {
                    OrderID: id
                }
            }
        )
    }
    console.log(deliveredData, "delivered ordes");
    return (
        <div >
            {deliveredData && deliveredData.length ?
                <>
                    {deliveredData && deliveredData.map((data) => (
                        <>
                            <div className="mobile-active-order">
                                <div className='col-md-12 col-xs-12 col-sm-12 mt-4'>
                                    {DeliveredDetails ? " " :
                                        <div>
                                            <div className='col-md-3 col-xs-12 col-sm-12 nopadding' ><b>Date :</b> {data.estimated_delivery_date && data.estimated_delivery_date}</div>
                                            <div className='col-md-4 col-xs-12 col-sm-12 nopadding'><b>Shipping :</b> {data.address}</div>
                                            <div className='col-md-3 col-xs-12 col-sm-12 nopadding'>  <b>OrderID :</b> #{data.order_number && data.order_number}</div>
                                            <div className='col-md-2 col-xs-12 col-sm-12 '>
                                                <div className='mobile-rate-us hide-in-desktop'>
                                                    <div>
                                                    </div>
                                                    {/* <div>
                                                    <button>Rate Us</button>
                                                </div> */}
                                                </div>
                                                <a onClick={() => handleOrderDetails(data)} className={"hide-in-mobile " + style.textDecor} ><b>Order Details</b></a>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="card-body-blk">
                                <div>
                                    {DeliveredDetails ?
                                        <div className='order-details-section'>
                                            <div className=' cancel-order-section '>
                                                <h4 className='mt-4'>
                                                    <i class="fa fa-arrow-left" aria-hidden="true" onClick={handleOrdertable}></i> Back</h4>
                                            </div>
                                            <DeliveredOrderDetail
                                                orderDetailsData={DeliveredDetails1}
                                            />
                                        </div>
                                        :
                                        <div className='col-md-12 col-xs-12 col-sm-12 nopadding delivered-slider'>
                                            <div >
                                                <Slider {...settings}>
                                                    {data && data.order_details.map((item) => (
                                                        <div className={`col-md-4 col-xs-12 col-sm-12 mt-2 mb-2 delivered-orders-cols ${style.orderCont}`}>
                                                            <div className='col-md-12 col-sm-12 col-xs-12 order-padding delivered-order'>
                                                                <div >
                                                                    <img src={BASE_URL + '/storage/' + item.product.image} className="active-order-img" alt="" />
                                                                </div>
                                                                <div >
                                                                    <div className=''>
                                                                        <div>
                                                                            <p><b>{item.product && item.product.name}</b></p>
                                                                            <p className="order-price-detail"><b>Price:</b> <span className='rupees-font'>₹</span> {item.price && item.price}</p>
                                                                            <p className="order-price-detail"><b>Quantity:</b> {item.quantity && item.quantity}</p>
                                                                            {item.returnorder ? (
                                                                                <button variant="" className={`hover ${style.req}`} onClick={() => alert("You Already Request to returning Your Product")}>Return Order Requested</button>
                                                                            ) : (
                                                                                <button variant="" className={`hover ${style.req}`} onClick={() => { setShowModal(true); setOrderId(data.id); setOrderDetailId(item.id); }}>Return Order</button>
                                                                            )}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    ))}
                </>
                :
                <h1 className='center p100' >No Bookings Available</h1>}

            <Modal open={showModal} onClose={() => setShowModal(false)}>
                <Box sx={Style} className='white-background cancellation-modal'>
                    <div onClick={() => setShowModal(false)} className='pointer flex space-between   border-bottom'>
                        <div className='cancel-order'>Return Order</div>
                        <CloseIcon sx={{ marginBottom: '5px', height: '25px', width: '25px' }} /> </div>
                    <Form.Select defaultValue={0} className="mt-2 input" size="md" onChange={(e) => setReasonCategory(e.target.value)}>
                        <option value="0">Select Reason for Return </option>
                        {cancelReason.map((item) => (
                            <option value={item.id}> {item.title}</option>
                        ))}
                    </Form.Select>
                    {error.reason_id && (
                        <span className='text-danger'>{error.reason_id}</span>
                    )}
                    <Form.Group className='mt-2' controlId="messageForm.ControlTextarea1">
                        <Form.Control type="file" multiple onChange={handleOnChange} placeholder='Enter Full Detail' />
                    </Form.Group>
                    {error.image && (
                        <span className='text-danger'>{error.image}</span>
                    )}
                    <Form.Group className='mt-2' controlId="messageForm.ControlTextarea1">
                        <Form.Control onChange={(e) => setReturnReason(e.target.value)} placeholder='Enter Full Detail' as="textarea" rows={3} />
                    </Form.Group>
                    {error.return_reason && (
                        <span className='text-danger'>{error.return_reason}</span>
                    )}
                    <div className='mt-2 italic'>Note: All payment refunds will be done on the same source account</div>
                    <div className='mt-2 border-top' ></div>
                    <div className='mt-1 text-right' >
                        <Button variant="contained" className='hover' sx={{ fontSize: 12 }} onClick={() => OrderReturn(orderid, orderdetailid)}>Sumbit</Button>
                    </div>
                </Box>
            </Modal>

            <Modal open={trackModal} onClose={() => setTrackModal(false)}>
                <Box sx={Style} className="track-modal" >
                    <div onClick={() => setTrackModal(false)} className="pointer border-bottom flex space-between"> <div className="cancel-order">Track Order</div> <CloseIcon sx={{ marginBottom: '5px', height: '25px', width: '25px' }} /> </div>
                    <div className='mt-4'>
                        <ProgressBar variant='success' now={trackData && trackData.length === 1 ? 8 : trackData && trackData.length === 2 ? 25 : trackData && trackData.length === 3 ? 40 : trackData && trackData.length === 4 ? 58 : trackData && trackData.length === 5 ? 75 : trackData && trackData.length === 6 ? 100 : 0} />
                    </div>
                    <div className='mt-4 flex'>
                        {trackData && trackData.map((item) => (
                            <Col md={2} xs={2} sm={2} className="track-order">{item.order_status && item.order_status}<br /> <span className='track-display'>{item.order_status_date && item.order_status_date}</span></Col>
                        ))}
                    </div>
                </Box>
            </Modal>
            <Modal open={productReview} onClose={() => setProductReview(false)}>
                <Box sx={Style} className="review-modal">
                    <div onClick={() => setProductReview(false)} className='pointer border-bottom flex space-between'> <div className="cancel-order">Product Rating</div> <CloseIcon sx={{ marginBottom: '5px', height: '25px', width: '25px' }} /> </div>
                    <Form.Group className='mt-4 flex' as={Col} controlId="formGridPhone">
                        <span className='font18'>Select Rating :</span>
                        <div
                            className='product-review'
                            onMouseOver={hoverOver}
                            onMouseOut={() => hoverOver(null)}
                            onClick={event => setRating(event.target.getAttribute("star-id"))}
                        >
                            {Array.from({ length: 5 }, (v, i) => (
                                <Star starId={i + 1} marked={selection ? selection > i : rating > i} />

                            ))}
                        </div>
                    </Form.Group>

                    <div className="mb-4">
                        <div className='font18'>Write a Review</div>
                        <Form.Group controlId="messageForm.ControlTextarea1">

                            <Form.Control onChange={(e) => setMessage(e.target.value)} placeholder="Message" as="textarea" rows={3} />

                        </Form.Group>
                    </div>
                    <div className="mt-2 border-top"></div>
                    <div className="mt-2 text-right">
                        <Button variant="contained" className='hover' sx={{ fontSize: 12 }} onClick={() => OrderReviewSubmit()}>Sumbit</Button>
                    </div>
                </Box>
            </Modal>

            {/* Return section hello  */}
            <Modal open={returnModal} onClose={() => setReturnModal(false)}>
                <Box sx={Style} className="review-modal">
                    <div onClick={() => setReturnModal(false)} className="pointer border-bottom flex space-between"> <div className="cancel-order">Return Product</div> <CloseIcon sx={{ marginBottom: '5px', height: '25px', width: '25px' }} /> </div>
                    <Form.Select defaultValue={0} className="mt-2 input" size="md" onChange={(e) => setReasonCategory(e.target.value)}>
                        <option value="0">Select Reason for Cancellation </option>
                        {cancelReason.map((item) => (
                            <option value={item.id}> {item.title}</option>
                        ))}
                    </Form.Select>
                    <Form.Group className='mt-2' controlId="messageForm.ControlTextarea1">
                        <Form.Control type="file" placeholder='Enter Full Detail' multiple onChange={handleOnChange} />
                    </Form.Group>
                    <Form.Group controlId="messageForm.ControlTextarea1">

                        <Form.Control onChange={(e) => setReturnReason(e.target.value)} placeholder="Message" as="textarea" rows={3} />

                    </Form.Group>
                    <div className='mt-2'>Upload Image</div>
                    <input type="file" className='mt-2' multiple onChange={handleOnChange} />
                    <div className="mt-2 border-top"></div>
                    <div className='mt-4 text-right'>
                        <Button variant="contained" className='hover' sx={{ fontSize: 12 }} onClick={() => OrderReturn(orderid, orderdetailid)}>Sumbit Return</Button>
                    </div>
                </Box>
            </Modal>
        </div>

    )
}

export const CancelledOrder = () => {
    const [reload, setreload] = useState(true);
    const [cancelledData, setCancelledData] = useState([]);
    const token = localStorage.getItem('token');
    const [cancelDetails, setCancelDetails] = useState(false);
    const [cancelDetailsData, setCancelDetailsData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        DeliveredOrderData()
    }, [])

    const handleCancelDetails = (i) => {
        setCancelDetails(true)
        setCancelDetailsData(i);
        console.log(i, "order details ");
    }

    const handleCancelDetailsClose = () => {
        setCancelDetails(false)
    }

    const DeliveredOrderData = async () => {
        axios.post(BASE_URL + '/api/orders', { "order_status": "Cancelled" }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setCancelledData(data.data);
        })
    }

    const proceedOrderDetails = (id) => {
        navigate('/order-detail',
            {
                state: {
                    OrderID: id
                }
            }
        )
    }


    return (
        <div className='mt-4'>
            {cancelledData && cancelledData.length ?
                <>
                    <>
                        {cancelDetails ?
                            <div className='order-details-section'>
                                <div className='flex cancel-order-section'>
                                    <h4>
                                        <i class="fa fa-arrow-left" aria-hidden="true" onClick={handleCancelDetailsClose}></i> Back</h4>
                                </div>
                                <CancelOrderDetails
                                    cancelDetailsData={cancelDetailsData}
                                    setreload={setreload}
                                    reload={reload}
                                    handleCancelDetailsClose={handleCancelDetailsClose}
                                />
                            </div>
                            :
                            <div className="orders-grid order-details-section">
                                <div className='table-responsive mt-2 table-scroll-y-axis'>
                                    <table className="table cancelled-table">
                                        <thead className="table-header">
                                            <tr>
                                                <th scope="col">Order ID</th>
                                                <th scope="col">Order Date & Time</th>
                                                <th scope="col">Total Products</th>
                                                <th scope="col">Payment Status</th>
                                                <th scope="col">Billed Amount</th>
                                                <th scope="col">Payment Method </th>
                                                <th scope="col">Order Current Status</th>
                                                <th scope="col">Payment Refund Status</th>
                                                <th scope="col">Reason for Cancellation</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className='table-body table-bg'>
                                            {cancelledData && cancelledData.map(data => (
                                                <tr>
                                                    <th><div className={style.dateTime}># {data.order_number && data.order_number}</div></th>
                                                    <td><div>{data.order_date_time && data.order_date_time}</div></td>
                                                    <td><div className={style.order}>{data.total_item_count && data.total_item_count}</div></td>
                                                    <td><div className={style.invoice}>{data.payment_status && data.payment_status}</div></td>
                                                    <td><div className={style.bill}>{data.order_amount && data.order_amount}</div></td>
                                                    <td><div className={style.bill}>{data.payment_method && data.payment_method}</div></td>
                                                    <td><div className={style.bill}>{data.order_status && data.order_status}</div></td>
                                                    <td><div className={style.bill}>{data.order_status && data.order_status}</div></td>
                                                    <td><div className={style.bill}>{data.cancelorder && data.cancelorder.cancellation_reason}</div></td>
                                                    <td><i class="fa fa-eye" onClick={() => handleCancelDetails(data)}></i></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </>
                </>
                : <h1 className='center p100' >No Bookings Available</h1>}
        </div>
    )
}


