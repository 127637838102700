import React, { useEffect, useState, useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ItemBox from '../../ProductBox/itemBox';
import style from '../../../styles/categoryPage.module.css';
import '../../../styles/blogpage.css';
import ListIcon from '@mui/icons-material/List';
import AppsSharpIcon from '@mui/icons-material/AppsSharp';
import ReactStars from "react-stars";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Dropdown } from "react-bootstrap";
import axios from 'axios';
import { BASE_URL } from '../../../config';
import { useLocation } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Drawer, Modal } from '@mui/material';
import { Form } from 'react-bootstrap';
import { UserContext } from '../../../App';
import { faL } from '@fortawesome/free-solid-svg-icons';
import Nav from 'react-bootstrap/Nav';
// import Tab from 'react-bootstrap/Tab';
import { Button, Collapse } from "react-bootstrap";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../Loader'
import LoadingOverlay from 'react-loading-overlay';
import { CiFilter, CiSquareCheck } from "react-icons/ci";
import { BiDetail, BiSortAlt2 } from "react-icons/bi";
import DialogTitle from '@mui/joy/DialogTitle';
import ModalClose from '@mui/joy/ModalClose';


import DialogContent from '@mui/joy/DialogContent';

import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import Stack from '@mui/joy/Stack';

import Sheet from '@mui/joy/Sheet';
import zIndex from '@mui/material/styles/zIndex';
import { Helmet } from 'react-helmet';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const Style = {
  position: 'absolute',
  top: '300px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};


export default function CategoryPage() {

  const [expanded, setExpanded] = useState(false);
  const [title, setTitle] = useState("Show 15");
  const [sort, setSort] = useState("Default Sorting");
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [packing, setPacking] = useState([]);
  const [cartdata, setcartdata] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const location = useLocation();
  const [error, setError] = useState([]);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  let machineId = localStorage.getItem('MachineId');
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(true);
  const [modalData, setModalData] = useState()
  const [searchCategory, setsearchCategory] = useState(null);
  const [filterDiscount, setFilterDiscount] = useState();
  const [search, setSearch] = useState();
  const [pricerange, setpricerange] = useState("");
  const [filterRating, setFilterRating] = useState();
  const { item, setItem } = useContext(UserContext);
  const [filterQuan, setFilterQuan] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategoriesArray, setSelectedCategoriesArray] = useState([]);
  const [filteredData, setFilteredData] = useState(product);
  const { state } = useLocation();
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  console.log(state)

  const handleSort = () => {
    setSort(true)
    setFilter(null)
  }
  const handleFilter = () => {
    setFilter(true)
    setSort(null)
  }

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleState = () => {
    if (state == null) {
      fetchAllProduct();
    }
  }

  useEffect(() => {
    fetchProduct();
    fetchCategory();
    fetchPaking();
    getMachineId();
    fetchCount();
    FetchItemsAuth();
    handleState();
    fetchFilterWithSubCategoryProduct();
    fetchpricerange();
  }, [state])

  const fetchCount = async () => {
    if (!token) {
      const data = { "device_id": machineId }
      await axios.post(BASE_URL + '/api/get-cart-unauth', data).then(({ data }) => {
        setItem(data.data.cart.item)
        setcartdata(data.data.cart_details)

      })
    }
  }

  const FetchItemsAuth = async () => {
    if (token) {
      axios.get(BASE_URL + '/api/get-cart', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        setItem(data.data.cart.item)
        setcartdata(data.data.cart_details)
      })
    }
  }

  const fetchProduct = async () => {
    if (state != null) {
      await axios.get(BASE_URL + '/api/manage-product-with-category/' + state.data).then(({ data }) => {
        setProduct(data.data);
        setloading(false)
        console.log(data.data)
      })
    }
  }

  const fetchAllProduct = async () => {
    await axios.get(BASE_URL + '/api/manage-product-with-category/' + 0).then(({ data }) => {
      setProduct(data.data);
      setloading(false)
    })
  }

  function fetchFilterProduct(id) {
    axios.get(BASE_URL + '/api/manage-product-with-category/' + id).then(({ data }) => {
      setProduct(data.data);
      setloading(false)
      //console.log(data.data)
    })
  }


  function fetchFilterSubCategoryProduct(SelectedCategories) {
    const data = { "subcategory_id": [SelectedCategories] }
    axios.post(BASE_URL + '/api/filter-product/', data)
      .then(({ data }) => {
        setProduct(data.data);
        //console.log(data.data)
      })
  }
  // function fetchFilterSubCategoryProduct(selectedCategoriesArray) {
  //   const data = { "subcategory_id": selectedCategoriesArray }; // Sending the array in the payload
  //   axios.post(BASE_URL + '/api/filter-product/', data)
  //     .then(({ data }) => {
  //       setProduct(data.data);
  //     });
  // }

  const handleCheckboxChange = (categoryId) => {
    const updatedCategories = selectedCategories.includes(categoryId)
      ? selectedCategories.filter((id) => id !== categoryId)
      : [...selectedCategories, categoryId];
    fetchFilterWithSubCategoryProduct(updatedCategories);
    setSelectedCategories(updatedCategories);
    // filteredData(updatedCategories);
  };



  function fetchFilterWithSubCategoryProduct(updatedCategories) {
    if (updatedCategories) {
      const data = { "subcategory_id": updatedCategories }
      axios.post(BASE_URL + '/api/filter-product/', data)
        .then(({ data }) => {
          setProduct(data.data);
          console.log(data.data, "searched products");
        })
    }
  }

  const [value, setValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };



  const [selectPrice1, setselctedPrice1] = useState(false)
  const [selectPrice2, setselctedPrice2] = useState(false)
  const [selectPrice3, setselctedPrice3] = useState(false)
  const [selectPrice4, setselctedPrice4] = useState(false)
  const [selectPrice5, setselctedPrice5] = useState(false)

  const handlePrice1 = () => {
    setselctedPrice1(true)
    setselctedPrice2(false)
    setselctedPrice3(false)
    setselctedPrice4(false)
    setselctedPrice5(false)
  }
  const handlePrice2 = () => {
    setselctedPrice1(false)
    setselctedPrice2(true)
    setselctedPrice3(false)
    setselctedPrice4(false)
    setselctedPrice5(false)
  }
  const handlePrice3 = () => {
    setselctedPrice1(false)
    setselctedPrice2(false)
    setselctedPrice3(true)
    setselctedPrice4(false)
    setselctedPrice5(false)
  }
  const handlePrice4 = () => {
    setselctedPrice1(false)
    setselctedPrice2(false)
    setselctedPrice3(false)
    setselctedPrice4(true)
    setselctedPrice5(false)
  }
  const handlePrice5 = () => {
    setselctedPrice1(false)
    setselctedPrice2(false)
    setselctedPrice3(false)
    setselctedPrice4(false)
    setselctedPrice5(true)
  }

  function fetchFilterPriceProduct(minPr, maxPr) {
    // setselctedPrice(null)
    const data = { "min_price": minPr, "max_price": maxPr }
    axios.post(BASE_URL + '/api/filter-product/', data).then(({ data }) => {
      // setselctedPrice(true)
      setProduct(data.data);
      //console.log(data.data)
    })
  }


  const fetchCategory = async () => {
    await axios.get(BASE_URL + '/api/product-categories').then(({ data }) => {
      setCategory(data.data);
    })
  }

  const fetchPaking = async () => {
    await axios.get(BASE_URL + '/api/manage-volume').then(({ data }) => {
      setPacking(data.data)
    })
  }
  const fetchpricerange = async () => {
    await axios.get(BASE_URL + '/api/price-range').then(({ data }) => {
      setpricerange(data.data)
    })
  }



  const sortByPriceAscending = () => {
    const sorted = [...product].sort((a, b) => a.min_price - b.min_price);
    setProduct(sorted);
};
const sortByPriceAscending1 = () => {
    const sorted = [...product].sort((a, b) => b.min_price - a.min_price);
    setProduct(sorted);
};
const sortByrating = () => {
  const sorted = [...product].sort((a, b) => b.rating - a.rating);
  setProduct(sorted);
};
const sortByDateDescending = () => {
  const sorted = [...product].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  setProduct(sorted);
};
const sortByNameAscending = () => {
  const sorted = [...product].sort((a, b) => a.name.localeCompare(b.name));
  setProduct(sorted);
};
const sortByNameAscending1 = () => {
  const sorted = [...product].sort((a, b) => b.name.localeCompare(a.name));
  setProduct(sorted);
};

  function handleSubmit(id, optionId) {
    setOpen(true)
    setError('');
    axios.post(BASE_URL + '/api/store-cart', { "product_id": id, "product_option_id": optionId },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        FetchItemsAuth()
        if (data.success === true) {
          setError('')
          //   //console.log('userdata',JSON.stringify(data.data))
          Swal.fire({
            text: data.meassge,
            icon: "success"
          })
          navigate("")
          document.getElementById("loginpage").reset();
        }
        if (data.success === false) {
          Swal.fire({
            text: data.meassge,
            icon: "error"
          })
        }
      }).catch(({ response }) => {
        setError(response.data.meassge)
        if (response.status_code === "422") {
          setError(response.data.meassge)
        }
      });
  }

  function getMachineId() {

    let machineId = localStorage.getItem('MachineId');

    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem('MachineId', machineId);
    }
  }

  function handleSubmitOffline(id, optionId) {
    setOpen(true)
    setError('');
    axios.post(BASE_URL + '/api/store-cart-unauth', { "product_id": id, "product_option_id": optionId, "device_id": machineId }, {}).then(({ data }) => {
      fetchCount();
      if (data.success === true) {
        // setModalData(data)
        setError('')

        //   //console.log('userdata',JSON.stringify(data.data))
        Swal.fire({
          text: data.meassge,
          icon: "success"
        })
        navigate("")
        document.getElementById("loginpage").reset();
      }
      if (data.success === false) {
        Swal.fire({
          text: data.meassge,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data.meassge)
      if (response.status_code === "422") {
        setError(response.data.meassge)
      }
    });
  }

  const bySearch = (user, search) => {
    if (search) {
      return user.name.toLowerCase().includes(search.toLowerCase());
    } else return user;
  };



  const byCategorySearch = (user, searchCategory) => {
    if (searchCategory) {
      return user.subcategory_id == searchCategory;
    }
    else return user;
  };

  const byDiscount = (user, filterDiscount) => {
    if (filterDiscount) {
      return user.product_options[0].discount_percentage >= filterDiscount;
    }
    else return user;
  };

  const byRating = (user, filterRating) => {
    if (filterRating) {
      return user.rating == filterRating;
    }
    else return user;
  };

  const byquantity = (user, filterQuan) => {
    // console.log(user.product_options[0].packaging && user.product_options[0].packaging.quantity+user.product_options[0].packaging.quantity_in)
    // console.log(user.product_options.packaging.filter((e)=>e.quantity+""+e.quantity_in == filterQuan))
    if (filterQuan) {
      var data = user.product_options[0].packaging && user.product_options[0].packaging.quantity + user.product_options[0].packaging.quantity_in == filterQuan;
      if (data) {
        return data;
      }
      var data1 = user.product_options[1] && user.product_options[1].packaging && user.product_options[1].packaging.quantity + user.product_options[1].packaging.quantity_in == filterQuan
      if (data1) {
        return data1;
      }
      var data2 = user.product_options[2] && user.product_options[2].packaging && user.product_options[2].packaging.quantity + user.product_options[2].packaging.quantity_in == filterQuan
      if (data2) {
        return data2;
      }
      var data3 = user.product_options[3] && user.product_options[3].packaging && user.product_options[3].packaging.quantity + user.product_options[3].packaging.quantity_in == filterQuan
      if (data3) {
        return data3;
      }
      var data4 = user.product_options[4] && user.product_options[4].packaging && user.product_options[4].packaging.quantity + user.product_options[4].packaging.quantity_in == filterQuan
      if (data4) {
        return data4;
      }
      var data5 = user.product_options[5] && user.product_options[5].packaging && user.product_options[5].packaging.quantity + user.product_options[5].packaging.quantity_in == filterQuan
      if (data5) {
        return data5;
      }
      var data6 = user.product_options[6] && user.product_options[6].packaging && user.product_options[6].packaging.quantity + user.product_options[6].packaging.quantity_in == filterQuan
      if (data6) {
        return data6;
      }
      var data7 = user.product_options[7] && user.product_options[7].packaging && user.product_options[7].packaging.quantity + user.product_options[7].packaging.quantity_in == filterQuan
      if (data7) {
        return data7;
      }
      var data8 = user.product_options[8] && user.product_options[8].packaging && user.product_options[8].packaging.quantity + user.product_options[8].packaging.quantity_in == filterQuan
      if (data8) {
        return data8;
      }
      var data9 = user.product_options[9] && user.product_options[9].packaging && user.product_options[9].packaging.quantity + user.product_options[9].packaging.quantity_in == filterQuan
      if (data9) {
        return data9;
      }
      var data10 = user.product_options[10] && user.product_options[10].packaging && user.product_options[10].packaging.quantity + user.product_options[10].packaging.quantity_in == filterQuan
      if (data10) {
        return data10;
      }
    }
    else {
      return user;
    }

  };

  const filteredList = (product, search) => {
    return product
      .filter(user => bySearch(user, search))
      .filter((user) => byCategorySearch(user, searchCategory))
      .filter((user) => byDiscount(user, filterDiscount))
      .filter((user) => byRating(user, filterRating))
      .filter((user) => byquantity(user, filterQuan))
  };
console.log(product)
  const filtercartdata = (id) => {
    let d = cartdata.filter((e) => e.product_id === id);
    if (d.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(categoryId.id);
    fetchFilterProduct(categoryId.slug);
  };
  const [state1, setState1] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState1({ ...state1, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350 }}
      role="presentation"

    >
      <div className="bottomsorting-drawer"  >
        <div className="bottomsorting_top">

          <h3>Sorting</h3>

          <ModalClose onClick={toggleDrawer(anchor, false)} style={{ marginTop: "8px" }} />
        </div>
        <div className="mt-3 sorting_btn_mobile">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" onClick={sortByPriceAscending}/>
            <label class="form-check-label" for="exampleRadios1">
            Price Low to High
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" onClick={sortByPriceAscending1} />
            <label class="form-check-label" for="exampleRadios2">
            Price High to Low
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3" onClick={sortByDateDescending}/>
            <label class="form-check-label" for="exampleRadios3">
            Recently Added
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option4" onClick={sortByNameAscending}/>
            <label class="form-check-label" for="exampleRadios4">
              A - Z
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option5" onClick={sortByNameAscending1}/>
            <label class="form-check-label" for="exampleRadios5">
              Z - A
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option5" onClick={sortByrating} />
            <label class="form-check-label" for="exampleRadios5">
            Top Rated First
            </label>
          </div>
        </div>

      </div>
    </Box>
  );
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (

        <>
        <Helmet>
                <title>Premium  Perfumes for Men & Women - Izharsons Perfumers</title>
                <meta name="description" content="Find Izharsons Perfumers' excellent assortment of premium fragrances for both men and women. Shop for quality smells that express elegance and sophistication while making a lasting impact." data-react-helmet="true" />
                <meta name="keywords" content="premium fragrances for men, luxury perfumes for women, Izharsons Perfumers collection, buy designer perfumes, high-end perfumes online, best perfumes for men, best perfumes for women, online perfume shopping, sophisticated fragrances India." data-react-helmet="true" />
                <link rel="canonical" href="https://izharsonperfumers.com/our-products" />      
            </Helmet>
          <div className='col-md-12 col-xs-12 nopadding'>
            <nav aria-label="" >
              <ol className='breadcrumb'>
                <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                <li className="breadcrumb-item-active" aria-current="page">Shop</li>
              </ol>
            </nav>
          </div>

          <div className={`col-md-12 col-xs-12 ${style.container}`}>
            <div className="filter_in_mobile mb-2">

              <div className="filterinmobile_left" onClick={() => setOpen(true)}>
                <h3 className="m-0"><CiFilter style={{ marginTop: "-4px" }} /> Filter</h3>
              </div>
              <div className="filterinmobile_line">

              </div>
              <div className="filterinmobile_left" onClick={toggleDrawer("bottom", true)}>
                <h3 className="m-0"><BiSortAlt2 style={{ marginTop: "-4px" }} /> Sort By</h3>
              </div>

            </div>
            <div className='col-md-3 col-xs-12'>
              <div className="hide-in-desktop">
                {/* <button variant="primary" onClick={() => setShow(true)} className="burger-btn hover">
                  Filter / Sort By
                </button> */}
                <Drawer open={show} onClose={handleClose} >
                  <div >
                    <div className="filter-sidebar-title">
                      <div>Filter / Sort By</div>
                      <div onClick={() => setShow(false)}><CloseIcon /></div></div>
                  </div>
                  <div className="mobile-sorting-pd" style={{ minWidth: '350px' }}>

                    <div className='col-xs-12 nopadding'>
                      <div className='col-xs-5' >
                        <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example" orientation="vertical" >
                          <Tab label={<div style={{ fontSize: 12, paddingRight: "5px", textAlign: "start" }} className='filter-tabs'>Categories</div>} {...a11yProps(0)} />
                          <Tab label={<div style={{ fontSize: 12, paddingRight: "5px", textAlign: "start" }} className='filter-tabs'>Price</div>} {...a11yProps(1)} />
                          <Tab label={<div style={{ fontSize: 12, paddingRight: "5px", textAlign: "start" }} className='filter-tabs'>Discount</div>} {...a11yProps(2)} />
                          <Tab label={<div style={{ fontSize: 12, paddingRight: "5px", textAlign: "start" }} className='filter-tabs'>Packing</div>} {...a11yProps(3)} />
                          <Tab label={<div style={{ fontSize: 12, paddingRight: "5px", textAlign: "start" }} className='filter-tabs'>Rating</div>} {...a11yProps(4)} />
                        </Tabs>
                      </div>
                      <div className='col-xs-7'>
                        <TabPanel value={value} index={0} style={{ padding: 0 }} >
                          <div className='filter-in-mobile'>
                            {category.map((category, key) => (
                              <Accordion defaultActiveKey="0" flush className='category-accordian'>
                                <Accordion.Item eventKey={key} onChange={category && category.direct_childs.length == 0 ? "" : handleChange(category.id)}>
                                  <Accordion.Header onClick={() => fetchFilterProduct(category.slug)}>{category.name} {category && category.direct_childs.length == 0 ? "" : <i class="fa fa-chevron-down" aria-hidden="true" style={{ float: "right" }}></i>} </Accordion.Header>

                                  <Accordion.Body >
                                    {category.direct_childs.map((subcategory, index) => (
                                      <div style={{ display: "flex", gap: '10px' }} className='filter-border-bottem'>
                                        <p >
                                          <input
                                            key={subcategory.id}
                                            value={subcategory.id}
                                            type='checkbox'
                                            checked={selectedCategories.includes(subcategory.id)}
                                            onChange={() => handleCheckboxChange(subcategory.id)}
                                          />
                                        </p>
                                        <p onClick={() => fetchFilterSubCategoryProduct([...selectedCategories, subcategory.id])} style={{ textTransform: "capitalize", cursor: "pointer" }}>{subcategory.name}</p>
                                      </div>
                                    ))}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            ))}
                          </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <div className='filter-in-mobile'>
                            {pricerange.max_price >= 100 && (
                              <div className='filter-border-bottem dashed-border-padding'>
                                <input type="checkbox" id="Rating5" name="Brands" onClick={() => fetchFilterPriceProduct('100', '1000')} className={style.autoWidth} />
                                <label for="Rating5" className="price-filter" style={{ marginLeft: 10 }}>
                                  Rs.100 - Rs.1000
                                </label> <br />
                              </div>
                            )}
                            {pricerange.max_price > 1000 && (
                              <div className='filter-border-bottem dashed-border-padding'>
                                <input type="checkbox" id="Rating4" name="Rating" className={style.autoWidth} onClick={() => fetchFilterPriceProduct('1001', '3000')} />
                                <label for="Rating4" className="price-filter" style={{ marginLeft: 10 }}>
                                  Rs.1001 - Rs.3000
                                </label> <br />
                              </div>
                            )}
                            {pricerange.max_price > 3000 && (
                              <div className='filter-border-bottem dashed-border-padding'>
                                <input type="checkbox" id="Rating3" name="Rating" className={style.autoWidth} onClick={() => fetchFilterPriceProduct('3001', '5000')} />
                                <label for="Rating3" className="price-filter" style={{ marginLeft: 10 }}>
                                  Rs.3001 - Rs.5000
                                </label> <br />
                              </div>
                            )}
                            {pricerange.max_price >= 5000 && (
                              <div className='filter-border-bottem dashed-border-padding'>
                                <input type="checkbox" id="Rating2" name="Rating" className={style.autoWidth} onClick={() => fetchFilterPriceProduct('5001', '10000')} />
                                <label for="Rating2" className="price-filter" style={{ marginLeft: 10 }}>
                                  Rs.5001 - Rs.10000
                                </label> <br />
                              </div>
                            )}

                            {pricerange.max_price >= 10000 && (
                              <div className='filter-border-bottem dashed-border-padding'>
                                <input type="checkbox" id="Rating1" name="Rating" className={style.autoWidth} onClick={() => fetchFilterPriceProduct('10000', '100000')} />
                                <label for="Rating1" className="price-filter" style={{ marginLeft: 10 }}>
                                  Rs.10000 & above
                                </label> <br />
                              </div>
                            )}
                          </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <div className='filter-in-mobile'>
                            <div className='filter-border-bottem'>
                              <input type="checkbox" value="10" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                              <label for="10%" className={style.marginLeft} style={{ marginLeft: 10 }}>10% and more</label>
                            </div>
                            <br />
                            <div className='filter-border-bottem'>
                              <input type="checkbox" value="20" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                              <label for="20%" className={style.marginLeft} style={{ marginLeft: 10 }}>20% and more</label>
                            </div> <br />
                            <div className='filter-border-bottem'>
                              <input type="checkbox" value="30" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                              <label for="30%" className={style.marginLeft} style={{ marginLeft: 10 }}>30% and more</label></div> <br />
                            <div className='filter-border-bottem'>
                              <input type="checkbox" value="40" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                              <label for="40%" className={style.marginLeft} style={{ marginLeft: 10 }}>40% and more</label></div> <br />
                            <div className='filter-border-bottem'>
                              <input type="checkbox" value="50" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                              <label for="50%" className={style.marginLeft} style={{ marginLeft: 10 }}>50% and more</label></div> <br />
                          </div>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                          <div className='filter-in-mobile'>
                            {packing && packing.map((item) => (
                              <div className='filter-border-bottem dashed-border-padding'>
                                <input type="checkbox" id="500gm" name="Packing" className={style.autoWidth} value={item.quantity + "" + item.quantity_in} onChange={(e) => setFilterQuan(e.target.value)} />
                                <label for="500gm" className={style.marginLeft}>{item && item.quantity} {item && item.quantity_in}</label> <br />
                              </div>
                            ))}
                          </div>
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                          <div className='filter-in-mobile'>
                            <div className='filter-border-bottem'>
                              <input type="checkbox" id="Rating5" name="Brands" onChange={() => setFilterRating(5)} className={style.autoWidth} />
                              <label for="Rating5" style={{ marginLeft: 10 }}>
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                              </label>
                            </div> <br />
                            <div className='filter-border-bottem'>
                              <input type="checkbox" id="Rating4" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(4)} />
                              <label for="Rating4" style={{ marginLeft: 10 }}>
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/star.svg" alt="rating" className={style.marginLeftRight} />
                              </label>
                            </div> <br />
                            <div className='filter-border-bottem'>
                              <input type="checkbox" id="Rating3" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(3)} />
                              <label for="Rating3" style={{ marginLeft: 10 }}>
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/star.svg" alt="rating" className={style.marginLeftRight} />
                              </label>
                            </div><br />
                            <div className='filter-border-bottem'>
                              <input type="checkbox" id="Rating2" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(2)} />
                              <label for="Rating2" style={{ marginLeft: 10 }}>
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/star.svg" alt="rating" className={style.marginLeftRight} />
                              </label>
                            </div> <br />
                            <div className='filter-border-bottem'>
                              <input type="checkbox" id="Rating1" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(1)} />
                              <label for="Rating1" style={{ marginLeft: 10 }}>
                                <img src="/Icons/rating-star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/star.svg" alt="rating" className={style.marginLeftRight} />
                                <img src="/Icons/star.svg" alt="rating" className={style.marginLeftRight} />
                              </label>
                            </div> <br />
                          </div>
                        </TabPanel>
                      </div>
                    </div>

                  </div>
                </Drawer>
              </div>
              <Form className={`d-flex searchproduct mobile-hidden ${style.space}`}>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className={`input-drawer`}
                  onChange={(e) => { setSearch(e.target.value) }}
                />

              </Form>
              <div className={`mobile-hidden ${style.categoryFrame}`}>
                <div className={style.categHeading}> Categories</div>
                <div className={style.border}></div>
                {category.map((category, key) => (
                  category && category.direct_childs.length === 0 ?
                    <p
                      key={category.id}
                      className={`${style.categorynametext} m-0`}
                      style={{
                        fontSize: "16px",
                        paddingTop: selectedCategoryId === category.id ? "10px" : "",
                        fontWeight: selectedCategoryId === category.id ? "500" : "600",
                        borderRadius: selectedCategoryId === category.id ? "10px" : "",
                        color: selectedCategoryId === category.id ? "#fff" : "#000",
                        backgroundColor: selectedCategoryId === category.id ? "#9F7D2D" : "#fff", // Change color if selected
                        fontFamily: "Inter, sans-serif"
                      }}
                      onClick={() => handleCategoryClick(category)}
                    >
                      {category.name}
                    </p> :
                    <Accordion defaultActiveKey="0" flush className='category-accordian' key={key}>
                      <Accordion.Item eventKey={key} onChange={category && category.direct_childs.length === 0 ? "" : handleChange(category.id)}>
                        <Accordion.Header onClick={() => handleCategoryClick(category)} className='m-0' >
                          <p className={`${style.categorynametext1} m-0`} style={{
                            fontSize: "16px",
                            fontWeight: selectedCategoryId === category.id ? "500" : "600",
                            borderRadius: selectedCategoryId === category.id ? "10px" : "",
                            color: selectedCategoryId === category.id ? "#fff" : "#000",
                            backgroundColor: selectedCategoryId === category.id ? "#9F7D2D" : "#fff", // Change color if selected
                            fontFamily: "Inter, sans-serif"
                          }}> {category.name} </p>

                        </Accordion.Header>
                        <Accordion.Body style={{ marginLeft: "25px", borderBottom: "1px solid gray", marginBottom: "10px" }}>
                          {category.direct_childs.map((subcategory, index) => (
                            <div style={{ display: "flex", gap: '10px' }} key={subcategory.id}>
                              <p>
                                <input
                                  value={subcategory.id}
                                  type='checkbox'
                                  checked={selectedCategories.includes(subcategory.id)}
                                  onChange={() => handleCheckboxChange(subcategory.id)}
                                />
                              </p>
                              <p
                                onClick={() => fetchFilterSubCategoryProduct(subcategory.id)}
                                style={{
                                  textTransform: "capitalize",
                                  color: selectedCategoryId === subcategory.id ? "blue" : "#000" // Change color if selected
                                }}
                              >
                                {subcategory.name}
                              </p>
                            </div>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                ))}
              </div>

              <div className={`mobile-hidden ${style.categoryFrame}`}>
                <div className={style.categHeading}> Price</div>
                <div className={style.border}></div>
                <div className={style.flexWrap}>
                  {pricerange.max_price >= 100 && (
                    <div className={selectPrice1 ? ` nopadding pointer  ${style.priceFrameAfter}` : ` nopadding pointer  ${style.priceFrame}`} onClick={() => { fetchFilterPriceProduct('100', '1000'); handlePrice1() }}>
                      Rs.100 - Rs.1000
                    </div>
                  )}
                  {pricerange.max_price >= 1001 && (
                    <div className={selectPrice2 ? ` nopadding pointer  ${style.priceFrameAfter}` : ` nopadding pointer  ${style.priceFrame}`} onClick={() => { fetchFilterPriceProduct('1001', '3000'); handlePrice2() }}>
                      Rs.1001 - Rs.3000
                    </div>
                  )}
                  {pricerange.max_price >= 3001 && (
                    <div className={selectPrice3 ? `nopadding pointer  ${style.priceFrameAfter}` : ` nopadding pointer  ${style.priceFrame}`} onClick={() => { fetchFilterPriceProduct('3001', '5000'); handlePrice3() }}>
                      Rs.3001 - Rs.5000
                    </div>
                  )}
                  {pricerange.max_price >= 5001 && (
                    <div className={selectPrice4 ? `nopadding pointer  ${style.priceFrameAfter}` : `nopadding pointer  ${style.priceFrame}`} onClick={() => { fetchFilterPriceProduct('5001', '10000'); handlePrice4() }}>
                      Rs.5001 - Rs.10000
                    </div>
                  )}
                  {pricerange.max_price >= 10000 && (
                    <div className={selectPrice5 ? ` nopadding pointer  ${style.priceFrameAfter}` : ` nopadding pointer  ${style.priceFrame}`} onClick={() => { fetchFilterPriceProduct('10000', '100000'); handlePrice5() }}>
                      Rs.10000 & above
                    </div>
                  )}
                </div>


              </div>

              <div className={`mobile-hidden ${style.categoryFrame}`}>
                <div className={style.categHeading}> Discount</div>
                <div className={style.border}></div>
                <div>
                  <div>
                    {pricerange.discount_percentage >= 10 && (
                      <>
                        <input type="checkbox" value="10" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                        <label for="10%" className={style.marginLeft}>10% and more</label> <br />
                      </>
                    )}
                    {pricerange.discount_percentage >= 20 && (
                      <>
                        <input type="checkbox" value="20" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                        <label for="20%" className={style.marginLeft}>20% and more</label> <br />
                      </>
                    )}
                    {pricerange.discount_percentage >= 30 && (
                      <>
                        <input type="checkbox" value="30" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                        <label for="30%" className={style.marginLeft}>30% and more</label> <br />
                      </>
                    )}
                    {pricerange.discount_percentage >= 40 && (
                      <>
                        <input type="checkbox" value="40" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                        <label for="40%" className={style.marginLeft}>40% and more</label> <br />
                      </>
                    )}
                    {pricerange.discount_percentage >= 50 && (
                      <>
                        <input type="checkbox" value="50" name="discount" className={style.autoWidth} onChange={(e) => setFilterDiscount(e.target.value)} />
                        <label for="50%" className={style.marginLeft}>50% and more</label> <br />
                      </>
                    )}
                  </div>
                </div>

              </div>

              <div className={`mobile-hidden ${style.categoryFrame}`}>
                <div className={style.categHeading}> Packing </div>
                <div className={style.border}></div>
                <div>
                  <div>
                    {packing && packing.map((item) => (
                      <>
                        <input type="checkbox" id="500gm" name="Packing" className={style.autoWidth} value={item.quantity + "" + item.quantity_in} onChange={(e) => setFilterQuan(e.target.value)} />
                        <label for="500gm" className={style.marginLeft}>{item && item.quantity} {item && item.quantity_in}</label> <br />
                      </>
                    ))}
                  </div>
                </div>

              </div>
              {pricerange.rating != 0 && (
                <div className={`mobile-hidden ${style.categoryFrame}`}>
                  <div className={style.categHeading}> Rating</div>
                  <div className={style.border}></div>
                  <div>
                    <div>
                      {pricerange.rating == 5 && (
                        <>
                          <input type="checkbox" id="Rating5" name="Brands" onChange={() => setFilterRating(5)} className={style.autoWidth} />
                          <label for="Rating5">
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                          </label> <br />
                        </>
                      )}
                      {pricerange.rating >= 4 && (
                        <>
                          <input type="checkbox" id="Rating4" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(4)} />
                          <label for="Rating4">
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                          </label> <br />
                        </>
                      )}
                      {pricerange.rating >= 3 && (
                        <>
                          <input type="checkbox" id="Rating3" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(3)} />
                          <label for="Rating3">
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                          </label> <br />
                        </>
                      )}
                      {pricerange.rating >= 2 && (
                        <>
                          <input type="checkbox" id="Rating2" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(2)} />
                          <label for="Rating2">
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                          </label> <br />
                        </>
                      )}
                      {pricerange.rating >= 1 && (
                        <>
                          <input type="checkbox" id="Rating1" name="Rating" className={style.autoWidth} onChange={() => setFilterRating(1)} />
                          <label for="Rating1">
                            <img src="/Icons/entypo_star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                            <img src="/Icons/star.png" alt="rating" className={style.marginLeftRight} />
                          </label> <br />
                        </>
                      )}

                    </div>
                  </div>

                </div>
              )}
            </div>

            <div className="col-md-9 col-xs-12" >
              {/* <div className={`col-md-12 col-xs-12 ${style.Pangination}`}>
            <div className="col-md-6 col-xs-12 " >
            </div>
            <div className={`col-md-6 col-xs-6 flex ${style.right}`}>
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  {sort}
                </Dropdown.Toggle>

                <Dropdown.Menu className={style.dropdownPadding}>
                  <Dropdown.Item onClick={() => setSort("Sort1")} href="#/action-1">
                    Sort1
                  </Dropdown.Item> <br />
                  <Dropdown.Item onClick={() => setSort("Sort2")} href="#/action-2">
                    Sort2
                  </Dropdown.Item> <br />
                  <Dropdown.Item onClick={() => setSort("Sort3")} href="#/action-3">
                    Sort1
                  </Dropdown.Item> <br />
                </Dropdown.Menu>
              </Dropdown>
          \

            </div>

          </div> */}

              <div className='col-md-12 col-xs-12 nopadding'>
                {product && product.length > 0 && filteredList(product, search).length == 0 ? <h1 className='p100 center'> No Product Found </h1> :
                  filteredList(product, search).map(user => (
                    <div className={`col-md-4 col-xs-6 col-sm-6 ${style.marginTopBottom}`} >

                      <ItemBox
                        ProductDetail={() => navigate('/product-details/' + user.slug)}
                        name={user.name && user.name}
                        exist={filtercartdata(user.id)}
                        img={BASE_URL + '/storage/' + user.image}
                        discount={user.product_options && user.product_options[0].discount_percentage}
                        actualPrice={user.product_options && user.product_options[0].mrp}
                        discountPrice={user.product_options && user.product_options[0].price}
                        discountpercentage={user.product_options && user.product_options[0].discount_percentage}
                        onClick={() => token ? handleSubmit(user.product_options[0].product_id && user.product_options[0].product_id, user.product_options[0].id && user.product_options[0].id) : handleSubmitOffline(user.product_options[0].product_id && user.product_options[0].product_id, user.product_options[0].id && user.product_options[0].id)}
                      />
                    </div>
                  ))}
              </div>

              {/* <Modal open={open} onClose={() => setOpen(false)} >
            
            <Box sx={{ ...Style, width: 200 }}>
            <div>{modalData ? modalData.meassge && modalData.meassge : ''}</div>              
            </Box>

          </Modal> */}
            </div>

          </div>
        </>
      )}

      <React.Fragment>

        <Drawer
          size="md"
          variant="plain"
          open={open}
          onClose={() => setOpen(false)}
          slotProps={{
            content: {
              sx: {
                bgcolor: 'transparent',
                p: { md: 3, sm: 4 },
                boxShadow: 'none',
                zIndex: 100000

              },
            },
          }}
        >
          <Sheet
            sx={{
              borderRadius: 'md',
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,

              height: '100%',
              overflow: 'auto',
            }}
          >
            <DialogTitle style={{fontSize:"18px"}}>Filter</DialogTitle>
            <ModalClose onClick={() => setOpen(false)} />
            <Divider sx={{ mt: 'auto' }} />
            <DialogContent sx={{ gap: 2 }}>
              <FormControl>

                {category.map((category, key) => (
                  category && category.direct_childs.length === 0 ?
                    <p
                      key={category.id}
                      className={`${style.categorynametext2} m-0`}
                      style={{
                        fontSize: "16px",
                        paddingTop: selectedCategoryId === category.id ? "10px" : "",
                        fontWeight: selectedCategoryId === category.id ? "500" : "500",
                        borderRadius: selectedCategoryId === category.id ? "10px" : "",
                        color: selectedCategoryId === category.id ? "#9F7D2D" : "#000",
                        // backgroundColor: selectedCategoryId === category.id ? "#9F7D2D" : "#fff", // Change color if selected
                        fontFamily: "Inter, sans-serif"
                      }}
                      onClick={() => handleCategoryClick(category)}
                    >
                      {category.name}
                    </p> :
                    <Accordion defaultActiveKey="0" flush className='category-accordian' key={key}>
                      <Accordion.Item eventKey={key} onChange={category && category.direct_childs.length === 0 ? "" : handleChange(category.id)}>
                        <Accordion.Header onClick={() => handleCategoryClick(category)} className='m-0' >
                          <p className={`${style.categorynametext3} m-0`} style={{
                            fontSize: "16px",
                            fontWeight: selectedCategoryId === category.id ? "500" : "500",
                            borderRadius: selectedCategoryId === category.id ? "10px" : "",
                            color: selectedCategoryId === category.id ? "#9F7D2D" : "#000",
                            // backgroundColor: selectedCategoryId === category.id ? "#9F7D2D" : "#fff", // Change color if selected
                            fontFamily: "Inter, sans-serif"
                          }}>{category.name}  </p>

                        </Accordion.Header>
                        <Accordion.Body style={{ marginLeft: "25px", borderBottom: "1px solid gray", marginBottom: "10px" }}>
                          {category.direct_childs.map((subcategory, index) => (
                            <div style={{ display: "flex", gap: '10px' }} key={subcategory.id}>
                              <p>
                                <input
                                  value={subcategory.id}
                                  type='checkbox'
                                  checked={selectedCategories.includes(subcategory.id)}
                                  onChange={() => handleCheckboxChange(subcategory.id)}
                                />
                              </p>
                              <p
                                onClick={() => fetchFilterSubCategoryProduct(subcategory.id)}
                                style={{
                                  textTransform: "capitalize",
                                  color: selectedCategoryId === subcategory.id ? "blue" : "#000" // Change color if selected
                                }}
                              >
                                {subcategory.name}
                              </p>
                            </div>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                ))}
              </FormControl>


            </DialogContent>

            <Divider sx={{ mt: 'auto' }} />
            <Stack
              direction="row"
              justifyContent="space-between"
              useFlexGap
              spacing={1}
            >
              <Button
                variant="outlined"
                color="neutral"

                style={{ fontSize: "12px" }}
              >
                Clear
              </Button>
              <Button style={{ fontSize: "12px", marginLeft: "80px" }} onClick={() => setOpen(false)}>Show Papers</Button>
            </Stack>
          </Sheet>
        </Drawer>
      </React.Fragment>
      <div>
        {['left', 'right', 'top', 'bottom'].map((anchor) => (
          <React.Fragment key={anchor}>

            <Drawer
              anchor={anchor}
              open={state1[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </div >
  )
}