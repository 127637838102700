import React, { useState, useEffect } from 'react';
import style from '../../styles/footer.module.css';
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../config';
import axios from 'axios';
import { Nav } from 'react-bootstrap';

function Footer() {

    const [footerData, setFooterData] = useState('')
    const [links, setLinks] = useState('')
    const [isReadMore, setIsReadMore] = useState(true);
    useEffect(() => {
        FetchFooterData();
        FetchSocialLinks();
    }, [])

    const FetchFooterData = async () => {
        await axios.get(BASE_URL + '/api/header-settings').then(({ data }) => {
            setFooterData(data.data);
        })
    }

    const FetchSocialLinks = async () => {
        await axios.get(BASE_URL + '/api/social-settings').then(({ data }) => {
            setLinks(data.data);
        })
    }

    return (
        <div className={`col-md-12 col-sm-12 col-xs-12 ${style.container}`}>

            {/* <div className={`col-md-12 col-xs-12 col-sm-12 ${style.container}`}> */}
            <div className='col-md-12 col-xs-12 col-sm-12'>
                <div className={`col-md-3 col-xs-12 col-sm-6 ${style.center}`}>
                    <img src={BASE_URL + '/storage/' + footerData.footer_logo} alt="IZHARSONS_logo" className={style.logo} />
                    <br /><br />
                    {/* <div className={style.text}>
                        {footerData.short_description && isReadMore ? footerData.short_description.slice(0, 140) : footerData.short_description}
                        <span onClick={() => setIsReadMore(!isReadMore)} style={{ color: "#b12926", cursor: "pointer" }}>
                            {isReadMore ? "Read more" : " Show less"}
                        </span>
                    </div> */}
                    <br />
                    <div className='col-md-12 col-xs-12 col-sm-12 nopadding'>
                        <div className='col-md-3 col-xs-3 col-sm-3 nopadding'>
                            <img src="/Icons/pay1.svg" alt="Maestro" className={style.paylogo} />
                        </div>
                        <div className='col-md-3 col-xs-3 col-sm-3 nopadding'>
                            <img src="/Icons/pay2.svg" alt="Maestro" className={style.paylogo} />
                        </div>
                        <div className='col-md-3 col-xs-3 col-sm-3 nopadding'>
                            <img src="/Icons/pay3.svg" alt="Maestro" className={style.paylogo} />
                        </div>
                        <div className='col-md-3 col-xs-3 col-sm-3 nopadding'>
                            <img src="/Icons/pay4.svg" alt="Maestro" className={style.paylogo} />
                        </div>
                    </div>
                </div>
                <div className={`col-md-3 col-xs-6 col-sm-6 ${style.aboutUs}`}>
                    <div className={style.text}>
                        <div className={style.heading}>About Company</div>
                        
                        <Link to="/About"><div className={style.links}>About Us </div></Link >
                        <Link to="/FAQs"><div className={style.links}>FAQ</div></Link >
                        <Link to="/blog"><div className={style.links}>Blog &amp; Articles</div></Link >
                        <Link to="/Contact"><div className={style.links}>Contact Us</div></Link >
                    </div>
                </div>
                <div className={`col-md-3 col-xs-6 col-sm-6 ${style.aboutUs}`}>
                    <div className={style.text}>
                        <div className={style.heading}>Importent Links</div>

                        <Link to="/terms-and-condition"><div className={style.links}>Terms &amp; Conditions </div></Link >
                        <Link to="/privacy-policy"><div className={style.links}>Privacy Policy </div></Link >
                        <Link to="/Refund-and-cancellation"><div className={style.links}>Refund &amp; Cancellation </div></Link >
                        <Link to="/cookie-policy"><div className={style.links}>Cookie Policy </div></Link >
                        <Link to="/shipping-policy"><div className={style.links}>Shipping Policy </div></Link >
                    </div>  
                </div>

                <div className={`col-md-3 col-xs-12 col-sm-6 ${style.aboutUs}`}>
                    <div className={style.text}>
                        <div className={style.heading}>Contact Us</div>
                       
                        <div className="flex" style={{marginTop: "10px"}}>
                            <div><LocationOnIcon sx={{ fontSize: 25 }} /></div>
                            <div className={style.marginLeft}>{footerData.address && footerData.address}</div>
                        </div>
                        <br />
                        <div className="flex">
                            <div><PhoneInTalkRoundedIcon sx={{ fontSize: 25 }} /></div>
                            <div className={style.marginLeft}>{footerData.tollfree_number && footerData.tollfree_number}</div>
                        </div>
                        <br />
                        <div className="flex">
                            <div><MailOutlineRoundedIcon sx={{ fontSize: 25 }} /></div>
                            <div className={style.marginLeft}>{footerData.email && footerData.email}</div>
                        </div>
                        <br />
                        <div className="col-md-12 col-xs-12 col-sm-12 nopadding">
                            <div className="col-md-2 col-xs-2 ">
                                <a href={links.fb_name} target="_blank"> <img src="/Icons/facebook.svg" className={style.socialMedia} /> </a>
                            </div>
                            <div className="col-md-2 col-xs-2">
                                <a href={links.twit_name} target="_blank"> <img src="/Icons/twitter.svg" className={style.socialMedia} /> </a>
                            </div>
                            <div className="col-md-2 col-xs-2">
                                <a href={links.linkedin_name} target="_blank"> <img src="/Icons/linkedin.svg" className={style.socialMedia} /> </a>
                            </div>
                            <div className="col-md-2 col-xs-2">
                                <a href={links.youtube_name} target="_blank"><img src="/Icons/youtube.svg" className={style.socialMedia} /> </a>
                            </div>
                            <div className="col-md-2 col-xs-2">
                                <a href={links.insta_name} target="_blank"><img src="/Icons/instagram.png" className={style.socialMedia} /> </a>
                            </div>
                            <div className="col-md-2 col-xs-2">
                                <a href={`https://wa.me/${footerData.whatsapp_number}`} target="_blank"> <img src="/Icons/whatsapp.svg" className={style.socialMedia} /> </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='col-md-12 col-xs-12'>
                <div className={style.copyrightText}>
                    <p className={style.reserve}>Copyright ©</p><p className={style.Izharson}>Izharson Perfumers.</p> <p className={style.reserve}>All Right Reserved. </p>
                </div>
            </div>
            {/* </div> */}

        </div>
    )
}

export default Footer;







