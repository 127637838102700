// import React from 'react';
// import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
// import style from '../../../styles/Changepassword.module.css';


// const quesData1 = [
//     {
//         heading: '01. The Order',
//         ques1: 'When do I receive my order? ',
//         ans1: 'When placing the order, a day of shipment is indicated. After the order has been placed, the same delivery time will also be stated on the order confirmation. It is therefore never possible that during the order, the shipping day on the website, is different than on the order confirmation.',
//         ques2: 'I now see the longer delivery time of (a part of) my order. How can I cancel it? ',
//         ans2: 'If the order has a longer delivery time than you had previously seen, it is of course possible to cancel (a part of) the order. For this you can contact our customer service. They will cancel the order for you. The purchase amount will be back on your bank account within two working days. When an order has already been shipped, it can no longer be cancelled. ',
//         ques3: 'When will I receive the invoice for my order? ',
//         ans3: 'When you have paid for the order, you will not automatically receive an invoice for your order. If you wish to receive an invoice, this can be done in two ways.The first way is through your account at our store. When you log in to your account you can see you orders and download the invoice. '
//     },
//     {
//         heading: '02. Shipment',
//         ques1: 'When do I receive my order? ',
//         ans1: 'When placing the order, a day of shipment is indicated. After the order has been placed, the same delivery time will also be stated on the order confirmation. It is therefore never possible that during the order, the shipping day on the website, is different than on the order confirmation.',
//         ques2: 'I now see the longer delivery time of (a part of) my order. How can I cancel it? ',
//         ans2: 'If the order has a longer delivery time than you had previously seen, it is of course possible to cancel (a part of) the order. For this you can contact our customer service. They will cancel the order for you. The purchase amount will be back on your bank account within two working days. When an order has already been shipped, it can no longer be cancelled. ',
//         ques3: 'When will I receive the invoice for my order? ',
//         ans3: 'When you have paid for the order, you will not automatically receive an invoice for your order. If you wish to receive an invoice, this can be done in two ways.The first way is through your account at our store. When you log in to your account you can see you orders and download the invoice. ',
//     },
//     {
//         heading: '03. The Order',
//         ques1: 'When do I receive my order? ',
//         ans1: 'When placing the order, a day of shipment is indicated. After the order has been placed, the same delivery time will also be stated on the order confirmation. It is therefore never possible that during the order, the shipping day on the website, is different than on the order confirmation.',
//         ques2: 'I now see the longer delivery time of (a part of) my order. How can I cancel it? ',
//         ans2: 'If the order has a longer delivery time than you had previously seen, it is of course possible to cancel (a part of) the order. For this you can contact our customer service. They will cancel the order for you. The purchase amount will be back on your bank account within two working days. When an order has already been shipped, it can no longer be cancelled. ',
//         ques3: 'When will I receive the invoice for my order? ',
//         ans3: 'When you have paid for the order, you will not automatically receive an invoice for your order. If you wish to receive an invoice, this can be done in two ways.The first way is through your account at our store. When you log in to your account you can see you orders and download the invoice. ',
//     },
//     {
//         heading: '04. Returns, Exchanges and Complaints',
//         ques1: 'When do I receive my order? ',
//         ans1: 'When placing the order, a day of shipment is indicated. After the order has been placed, the same delivery time will also be stated on the order confirmation. It is therefore never possible that during the order, the shipping day on the website, is different than on the order confirmation.',
//         ques2: 'I now see the longer delivery time of (a part of) my order. How can I cancel it? ',
//         ans2: 'If the order has a longer delivery time than you had previously seen, it is of course possible to cancel (a part of) the order. For this you can contact our customer service. They will cancel the order for you. The purchase amount will be back on your bank account within two working days. When an order has already been shipped, it can no longer be cancelled. ',
//         ques3: 'When will I receive the invoice for my order? ',
//         ans3: 'When you have paid for the order, you will not automatically receive an invoice for your order. If you wish to receive an invoice, this can be done in two ways.The first way is through your account at our store. When you log in to your account you can see you orders and download the invoice. ',
//     }

// ]

// console.log(quesData1, "quesData1");

// function FAQ() {
//     return (
//         <div>
//             <div className='col-md-12 col-xs-12 nopadding'>

//                 <nav aria-label="breadcrumb" >
//                     <ol className='breadcrumb'>
//                         <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
//                         <li className="breadcrumb-item-active" aria-current="page">FAQ</li>
//                     </ol>
//                 </nav>
//             </div>
//             <div className={`col-md-12 col-xs-12 ${style.margin}`}>
//                 <div className={style.center}>
//                     {/* <HelpOutlineOutlinedIcon sx={{ fontSize: 50 }} /> */}
//                     <h1 className="faq-heading">Frequently Asked Question</h1>
//                 </div>

//                 {quesData1.map(i => (
//                     <div className={`col-md-12 col-xs-12 padding-faq`}>
//                         <h3 className='faq-points' style={{borderBottom:"1px solid gray",paddingBottom:"10px"}}>{i.heading}</h3><br />
//                         <div className='faq-headings'>{i.ques1}</div>
//                         <div className='faq-content'>{i.ans1}</div><br />
//                         <div className='faq-headings'>{i.ques2}</div>
//                         <div className='faq-content'>{i.ans2}</div><br />
//                         <div className='faq-headings'>{i.ques3}</div>
//                         <div className='faq-content'>{i.ans3}</div>
//                     </div>
//                 ))}

//             </div>
//         </div>
//     )
// }

// export default FAQ


import React, { useState, useEffect } from 'react';
import style from '../../../styles/aboutus.module.css'
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import axios from 'axios';
import { BASE_URL } from '../../../config';


function FAQ() {

  const [expanded, setExpanded] = React.useState(false);
  const [aboutdata, setaboutdata] = useState('');
  const [faqs, setFaqs] = useState();
  const [faqs2, setFaqs2] = useState();
  const [count, setCount] = useState();


  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
   
    fetchFaqs();
    fetchFaqs2();
  }, [])

 

  const fetchFaqs = async () => {
    await axios.get(BASE_URL + '/api/faqs').then(({ data }) => {
      setFaqs(data.data);
      console.log(data.data)
      var leftSide = data.data.splice(0, Math.floor(data.data.length / 2));
      setCount(leftSide)
    })
  }

  const fetchFaqs2 = async () => {
    await axios.get(BASE_URL + '/api/faqs').then(({ data }) => {
      // setFaqs2(data.data);
      console.log(data.data)
      var leftSide = data.data.splice(count, Math.floor(data.data.length / 2));
      // console.log()
      setFaqs2(leftSide);
    })
  }



  return (
    <div className='col-md-12 col-xs-12 nopadding'>
       <div className='col-md-12 col-xs-12 nopadding'>
            <nav aria-label="" >
              <ol className='breadcrumb'>
                <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                <li className="breadcrumb-item-active" aria-current="page">FAQ</li>
              </ol>
            </nav>
          </div>
          <div className={style.center}>
                   
                    <h2 className="faq-heading text-center">Frequently Asked Question</h2>
                </div>
      <div className={`col-md-6 col-xs-12 ${style.content}`} style={{paddingRight:"0px"}}>
        {faqs && faqs.map(accordion => {
          const { id, question, answer } = accordion;
          return (
            <Accordion
              expanded={expanded === id}
              key={id}
              onChange={handleChange(id)}
              className={style.AccordionMargin}
              style={{boxShadow:'none', borderRadius:"10px", border:"1px solid #80808040"}}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <h5 className="about-accordian">{question && question}</h5>
              </AccordionSummary>
              <AccordionDetails >
                <div className="about-accordian-content">{answer && answer}</div>
              </AccordionDetails>
            </Accordion>
          );
        })}

      </div>
      <div className={`col-md-6 col-xs-12 ${style.content}`} style={{paddingLeft:"0px"}}>
        {faqs2 && faqs2.map(accordion => {
          const { id, question, answer } = accordion;
          return (
            <Accordion
              expanded={expanded === id + 1 -1}
              key={id}
              onChange={handleChange(id + 1 -1)}
              className={style.AccordionMargin}
              style={{boxShadow:'none', borderRadius:"10px", border:"1px solid #80808040"}}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <h5 className="about-accordian">{question && question}</h5>
              </AccordionSummary>
              <AccordionDetails >
                <div className="about-accordian-content">{answer && answer}</div>
              </AccordionDetails>
            </Accordion>
          );
        })}

      </div>
    
    </div>
  )
}

export default FAQ;