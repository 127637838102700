import React from 'react';
import Header1 from './infoheader';
import Header2 from './searchHeader';
import Header3 from './menuHeader';
import MobileViewHeader from './MobileHeader';
import Dashboardheader from './Dashboardheader';

function MainHeader({ location }) {
    const hideHeaderPaths = ['/dashboard', '/my-orders', '/my-wishList', '/my-address', '/change-password', '/settings', '/settings']; // Paths to hide the header
    const shouldHideHeader = hideHeaderPaths.includes(location.pathname);

    if (shouldHideHeader) {
        return <div> <div className='dashboard-mobile-view'> <Dashboardheader /></div>
        <div className='desktop-view-sidebar'>
            <Header1 />
            <Header2 />
            <Header3 />
            <MobileViewHeader />
        </div></div>
    }
    return (
        <div>
            <Header1 />
            <Header2 />
            <Header3 />
            <MobileViewHeader />
        </div>
    )
}

export default MainHeader;