import React, { useState, useEffect,useContext } from 'react';
import style from '../../../styles/dashboard.module.css';
import { BASE_URL } from '../../../config';
import axios from 'axios';
import Sidebar from '../../Sidebar/Sidebar'
import DashOrderDetail from './DashOrderDetails';
import { UserContext } from '../../../App';

export default function Dashboard() {
const {orderdetailop, setorderdetailop} = useContext(UserContext)
  const [activeData, setActiveData] = useState([]);
  const [activeData1, setActiveData1] = useState([]);
  const token = localStorage.getItem('token');
  const [orderDetails, setOrderDetails] = useState(false);
  const [orderDetailsData, setOrderDeatilsData] = useState({})
  const [reload, setreload] = useState(true)

  useEffect(() => {
    ActiveOrderData()
  }, [reload, orderDetails])

  const handleOrderDetails = (i) => {
    setOrderDetails(true)
    setorderdetailop(true)
    setActiveData1(i);
    console.log(i, "order details ");
  }

  const handleOrdertable = () => {
    setOrderDetails(false)
    setorderdetailop(false)
  }

  const ActiveOrderData = async () => {
    axios.post(BASE_URL + '/api/orders', { "order_status": "active" }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setActiveData(data?.data);
    })
  }


  return (

    <div className={`col-md-12 col-xs-12 nopadding`}>
      <div className='col-md-12 col-xs-12 nopadding'>
        <nav aria-label="breadcrumb" >
          <ol className='breadcrumb'>
            <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li className="breadcrumb-item-active" aria-current="page">
              Dashboard
            </li>
          </ol>
        </nav>
      </div>
      <div className={`col-md-3 col-xs-12 nopadding `}>
        <Sidebar
          handleOrdertable={handleOrdertable}
        />
      </div>
      <div className={`col-md-9 col-xs-12 col-sm-12 ${style.container}`} >
        <div className={`col-md-12 col-xs-12 nopadding ${style.header}`}>
          <h3 className={style.heading}>
            Dasboard
          </h3>
          <div className='black-border-Bottom'></div>
          <div className={style.padding20}>
            {orderdetailop ?
              <div className='order-details-section'>
                <div className='flex cancel-order-section'>
                  <h4>
                    <i class="fa fa-arrow-left" aria-hidden="true" onClick={handleOrdertable}></i> Order Details</h4>
                </div>
                <DashOrderDetail
                  orderDetailsData={activeData1}
                  setreload={setreload}
                  reload={reload}
                  handleOrdertable={handleOrdertable}
                />
              </div>
              :
              <div>
                <div className={style.headingText}> Recent Order List </div>
                <div className='table-responsive mt-2 table-scroll-y-axis'>
                  <table className="table">
                    <thead className="table-header">
                      <tr>
                        <th scope="col"> Date & Time </th>
                        <th scope="col">Order Id</th>
                        <th scope="col">Invoice No</th>
                        <th scope="col"> Bill Amount</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody className='table-body table-bg'>
                      {activeData && activeData.length > 0 && activeData ?.map(i => (
                        <tr>
                          <th><div className={style.dateTime}>{i.order_date_time} </div></th>
                          <td><div className={style.order} onClick={() => handleOrderDetails(i)}>{i.order_number && i.order_number}</div></td>
                          <td><div className={style.invoice}><a
                            href={BASE_URL + "/storage/" + i?.invoice_url}
                            download={BASE_URL + "/storage/" + i?.invoice_url}
                            target="_blank"
                            className='dashboard-invoice'
                          >
                            <i class="fa fa-download"></i> {i.invoice_number && i.invoice_number}</a></div></td>
                          <td><div className={style.bill}>&#8377; {i.order_amount && i.order_amount}</div></td>
                          <td><div className={style.bill}>{i.payment_status && i.payment_status}</div></td>
                          <td><div className={style.bill}>{i.order_status && i.order_status}</div></td>
                          <td><i class="fa fa-eye" onClick={() => handleOrderDetails(i)}></i></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            }


          </div>
        </div>
      </div>

    </div>
  )
}