import React, { useEffect, useState, useContext } from 'react';
import ProductBox from '../../ProductBox/itemBox';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Slider from '../../NewSlider';
import { Modal,Box } from '@mui/material';
import { UserContext } from '../../../App';

const Style = {
  position: 'absolute',
  top: '300px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function NewArrivals() {

  const [products, setProducts] = useState([]);
  const [error, setError] = useState([]);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  let machineId = localStorage.getItem('MachineId');
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const {item,setItem} = useContext(UserContext);
  const [cartdata, setcartdata] = useState([]);

  useEffect(() => {
    fetchproduct(0);
    getMachineId();
    fetchCount();
    FetchItemsAuth();
  }, [])

  const fetchproduct = async (id) => {
    await axios.get(BASE_URL + '/api/new-arrival-product/' + id).then(({ data }) => {
      setProducts(data.data)
      
    })
  }

  const fetchCount = async () => {
    if(!token){
    const data = { "device_id": machineId }
    await axios.post(BASE_URL + '/api/get-cart-unauth', data).then(({ data }) => {
        setItem(data.data.cart.item)
        setcartdata(data.data.cart_details)
        
    })
  }
}

const FetchItemsAuth = async () => {
  if(token){
  axios.get(BASE_URL + '/api/get-cart', {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      }
  }).then(({ data }) => {
      setItem(data.data.cart.item)
      setcartdata(data.data.cart_details)
  })
}
}

  function handleSubmit(id, optionId) {
    setOpen(true)
    setError('');
    axios.post(BASE_URL + '/api/store-cart', { "product_id": id, "product_option_id": optionId },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        setModalData(data)
        FetchItemsAuth()
        if (data.success === true) {
          setError('')
      
          Swal.fire({
            text: data.meassge,
            icon: "success"
          })
          
        }
        if (data.status === "error") {
          Swal.fire({
            text: data.message,
            icon: "error"
          })
        }
      }).catch(({ response }) => {
        setError(response.data.message)
        if (response.status_code === "422") {
          setError(response.data.message)
        }
      });
  }

  function getMachineId() {

    let machineId = localStorage.getItem('MachineId');

    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem('MachineId', machineId);
    }
  }

  function handleSubmitOffline(id, optionId) {
    setOpen(true)
    setError('');
    axios.post(BASE_URL + '/api/store-cart-unauth', { "product_id": id, "product_option_id": optionId, "device_id": machineId }, {}).then(({ data }) => {
      fetchCount();
      if (data.success === true) {
        setModalData(data)
        setError('')
        //   console.log('userdata',JSON.stringify(data.data))
        Swal.fire({
          text: data.meassge,
          icon: "success"
        })
      
        document.getElementById("loginpage").reset();
      }
      if (data.status === "error") {
        Swal.fire({
          text: data.meassge,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data.message)
      if (response.status_code === "422") {
        setError(response.data.message)
      }
    });
  }
  const filtercartdata = (id) => {
    let d = cartdata.filter((e) => e.product_id === id);
    if (d.length > 0) {
      return true;
    } else {
      return false;
    }
  }
 
  
  return (
    <div className='col-md-12 col-sm-12 col-xs-12 nopadding mt-2 hello'>
      <Slider name="New" boldName="Arrivals" product={fetchproduct} productData={products} className="hello">
       
        {products && products.map((data) => (
          <div className='col-md-3 col-sm-6 col-xs-6 nopadding-mobile hell'>
            <ProductBox
              ProductDetail={() => navigate('/product-details/' + data.slug)}
              name={data.name && data.name}
              img={BASE_URL + '/storage/' + data.image}
              discount={data.product_options && data.product_options[0].discount_percentage}
              actualPrice={data.product_options && data.product_options[0].mrp}
              discountPrice={data.product_options && data.product_options[0].price}
              discountpercentage={data.product_options && data.product_options[0].discount_percentage}
              onClick={() => token ? handleSubmit(data.product_options[0].product_id && data.product_options[0].product_id, data.product_options[0].id && data.product_options[0].id) : handleSubmitOffline(data.product_options[0].product_id && data.product_options[0].product_id, data.product_options[0].id && data.product_options[0].id)}
              exist={filtercartdata(data.product_options && data.product_options[0].product_id)}
            />
          </div>
        ))
      }
      </Slider>
    </div>
  )
}

export default NewArrivals;