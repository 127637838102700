import style from '../../styles/header.module.css';
import axios from 'axios';
import { BASE_URL } from '../../config';
import { useState, useEffect } from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 280,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [expanded, setExpanded] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = panel => (event, isExpanded) => {

    setExpanded(isExpanded ? panel : false);
  };

  const [categories, setCategories] = useState([])

  useEffect(() => {
    FetchCategories();
  }, [])

  const FetchCategories = async () => {
    await axios.get(BASE_URL + '/api/product-categories').then(({ data }) => {
      setCategories(data.data)
      // console.log(data.data)
    })
  }

  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (itemIndex) => {
    setActiveItem((prevActiveItem) => (prevActiveItem === itemIndex ? null : itemIndex));
  };


  return (
    <div>
      <div className={` pointer ${style.perfumerButton}`}>
        <li onClick={handleClick} className={style.listStyleNone}>
          <div >
            <div className={`flex ${style.space}`}>
              <div className='flex'>
                <div className={style.buttonContent}><img src="/Icons/feather_bar-chart-2.png" /></div>
                <div className={style.buttonText}> Our Products </div>
              </div>
              <div>
                <div className={style.downArrow}><img src="/Icons/downArrow.png" /></div>

              </div>
            </div>
          </div>
        </li>
      </div>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{maxHeight:'500px'}}
      >
        {categories && categories.map((data, key, index) => (
          <>
            <div style={{ fontFamily: 'Montserrat', fontWeight: 600 }}>

             
              <div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: 10, border: '1px solid #EEEEEE', background:activeItem === data.id ? '#9F7D2D' : '' , color:activeItem === data.id ? '#fff' : ''}}
              onClick={data.direct_childs.length ? "" : handleClose}
              >
                 <Link to= {data && data.direct_childs.length == 0 ? `/${data.slug}` : ""} state={{ data: data.slug }}>
                <div style={{ fontSize: '14px' }}> {data.name && data.name}</div>
                </Link>
                {data && data.direct_childs.length == 0 ? "" :
                <div onClick={() => handleItemClick(data.id)}>
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.5L6 6.5L11 1.5" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                }
              </div>
             
              {activeItem === data.id &&
                <>
                  {data && data.direct_childs && data.direct_childs.map((subcategory, keym) => (
                    <Link to={`/${subcategory.parent_slug}`} state={{ data: subcategory.parent_slug }}>
                      {console.log(subcategory)}
                      <div className='pointer' style={{padding: 10, border: '1px solid #EEEEEE',paddingLeft:30 }}>
                        <div onClick={handleClose}> <Link to={`/${subcategory.parent_slug}`} state={{ cate: subcategory.parent_slug }} style={{ fontSize: '14px' }}> {subcategory.name && subcategory.name} </Link> </div>
                      </div>
                    </Link>
                  ))}
                </>}
            </div>


           
          </>
        ))}

      </StyledMenu>
    </div>
  );
}

