import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { BASE_URL } from "../../../config";
import { Tab, Tabs, Modal, Box, Button } from "@mui/material";
import Swal from "sweetalert2";
import style from "../../../styles/home.module.css";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { Rating } from "react-simple-star-rating";
import { useNavigate } from "react-router-dom";
const Star = ({ starId, marked }) => {
  return (
    <span star-id={starId} role="button" className="star-style">
      {marked ? "\u2605" : "\u2606"}
    </span>
  );
};
const DashOrderDetail = (props) => {
  const [showModal, setShowModal] = useState();
  const [cancelID, setCancelID] = useState({});
  const [detailedReason, setDetailedReason] = useState();
  const [reasonCategory, setReasonCategory] = useState();
  const token = localStorage.getItem("token");
  const [error, setError] = useState();
  const [orderid, setOrderId] = useState();
  const [canReason, setCanReason] = useState();
  const [cancelReason, setCancelReason] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [rating, setRating] = useState();
  const [selection, setSelection] = useState(0);
  const [message, setMessage] = useState("");
  const [orderdetailid, setOrderDetailId] = useState("");
  const [productReview, setProductReview] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    featchCancelData();
  }, []);

  const featchCancelData = async () => {
    await axios
      .post(BASE_URL + "/api/reason", {
        type: "cancelled",
        category: "e-commerce",
      })
      .then(({ data }) => {
        setCancelReason(data.data);
      });
  };

  console.log(cancelReason, "hello reasonssss");

  const handleImageChange = (e) => {
    setSelectedImages([...selectedImages, ...e.target.files]);
  };

  console.log(selectedImage, "hello ");

  function OrderCancel(id) {
    const formData = new FormData();
    selectedImages.forEach((image) => {
      formData.append("image[]", image);
    });
    formData.append("order_id", id);
    formData.append("reason", detailedReason);
    formData.append("reason_id", reasonCategory);
    console.log(id, "cancelaion id");
    setError("");
    axios
      .post(BASE_URL + "/api/cancel-order", formData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setShowModal(false);
        if (data.status === "success" && true) {
          setError("");
          Swal.fire({
            text: data.message,
            icon: "success",
          });
          document.getElementById("otherService").reset();
        }
        if (data.status === false) {
          Swal.fire({
            text: data.message,
            icon: "error",
          });
        }
      })
      .catch(({ response }) => {
        setError(response.data);
        if (response.status === "422") {
          setError(response.data.message);
        }
      });
  }

  const Style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: 15,
    boxShadow: 24,
    p: 2,
  };
  const handleRating = (rate) => {
    setRating(rate);

    // other logic
  };
  function OrderReviewSubmit(id) {
    setError("");
    axios
      .post(
        BASE_URL + "/api/submit-order-review",
        {
          order_id: props.orderDetailsData.id,
          rating: rating,
          review: message,
          order_detail_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(({ data }) => {
        setProductReview(false);
        if (data.success === true) {
          setError("");
          Swal.fire({
            text: data.message,
            icon: "success",
          });
          props.setreload(!props.reload);
          props.handleOrdertable();
          navigate("/my-orders");
          // document.getElementById("otherService").reset();
        }
        if (data.status === "error") {
          Swal.fire({
            text: data.message,
            icon: "error",
          });
        }
      });
  }
  return (
    <>
      <div className="order-date order-id">
        <p className="hide-in-mobile">
          Ordered on {props.orderDetailsData.order_date_time}
        </p>
        <p> Order# {props.orderDetailsData.order_number}</p>
        <p>
          Order Status:{" "}
          <span style={{ color: "#1CA301" }}>
            {props.orderDetailsData.order_status}
          </span>
        </p>
        <button
          onClick={() => {
            setShowModal(true);
            setOrderId(props.orderDetailsData.id);
          }}
        >
          Cancel order
        </button>
      </div>
      <div className="shipping-address">
        <div className="right-border address-pd">
          <h6>Shipping Address</h6>
          <ul>
            <li>{props.orderDetailsData.name}</li>
            <li style={{ color: "#555" }}>
              {props.orderDetailsData.address},{" "}
              <span>{props.orderDetailsData && props.orderDetailsData.citys.name}</span>
            </li>
            <li style={{ color: "#555" }}>
              {props.orderDetailsData && props.orderDetailsData.states.name},{" "}
              <span>{props.orderDetailsData && props.orderDetailsData.countries.name}</span>,{" "}
              <span>{props.orderDetailsData && props.orderDetailsData.pincode}</span>
            </li>
            <li style={{ color: "#555" }}>
              {props.orderDetailsData && props.orderDetailsData.mobile_number},{" "}
              <span>{props.orderDetailsData && props.orderDetailsData.email}</span>
            </li>
          </ul>
        </div>
        <div className="right-border address-pd">
          <h6>Payment</h6>
          <div className="order-date">
            <ul>
              <li>Payment Status</li>
              {/* <h6>Transaction Id:</h6> */}
              <li>Invoice Number</li>
            </ul>
            <ul>
              <li style={{ color: "#1CA301" }} className="payment-pera">
                {props.orderDetailsData.payment_status}
              </li>
              {/* <h6 style={{ color: '#555' }}>TID:78887997</h6> */}
              <li className="payment-pera">
                {props.orderDetailsData.invoice_number}
              </li>
            </ul>
          </div>
          <a
            href={BASE_URL + "/storage/" + props.orderDetailsData?.invoice_url}
            download={
              BASE_URL + "/storage/" + props.orderDetailsData?.invoice_url
            }
            target="_blank"
          >
            <h5 className="download-invoice">
              <img src="/Icons/download-file.png" />
              Download Invoice
            </h5>
          </a>
        </div>
        <div className="address-pd">
          <h6>Order Summary</h6>
          <div className="order-date order-details-mt-1">
            <div>
              <p>Item(s) Subtotal:</p>
              <p>Shipping:</p>
              <p>Promotion Applied</p>
              <p>Total:</p>
              <p className="grant-total">Grand Total:</p>
            </div>
            <div>
              <p>
                <span className="rupees-font">₹</span>{" "}
                {props.orderDetailsData.subtotal}
              </p>
              <p>
                <span className="rupees-font">₹</span>{" "}
                {props.orderDetailsData.shipping_price}
              </p>
              <p>
                <span className="rupees-font">₹</span>{" "}
                {props.orderDetailsData.discount_amount}
              </p>
              <p>
                <span className="rupees-font">₹</span>{" "}
                {props.orderDetailsData.order_amount_with_shipping}
              </p>
              <p className="grant-total">
                <span className="rupees-font">₹</span>{" "}
                {props.orderDetailsData.order_amount}
              </p>
            </div>
          </div>
        </div>
      </div>
      {props.orderDetailsData.order_details.map((item, index) => (
        <div>
          <div
            className="shipping-address hide-in-mobile"
            style={{ marginTop: "20px" }}
          >
            <div className="address-pd">
              <h6>{item.product.name}</h6>
              <ul>
                <li>
                  <b>Size:</b>{" "}
                  <span style={{ color: "#555" }}> {item.size}</span>
                </li>
                <li>
                  <b>Quantity:</b> <span style={{ color: "#555" }}> </span>
                  {item.quantity} x <span className="rupees-font">₹</span>{" "}
                  <span className="product-price">{item.price}</span>
                </li>
                {/* <li >{item.price}</li> */}
              </ul>
            </div>
            <div className="address-pd product-image">
              <img
                src={BASE_URL + "/storage/" + item.product.image}
                width={110}
              />
            </div>
            {item.order_product_review ? (
              <div className="address-pd">
                <h6>Your Review</h6>
                <div>
                  <Rating
                    value={item.order_product_review.rating}
                    initialValue={item.order_product_review.rating}
                    readonly={true}
                  />
                  {/* <img src="/Icons/rating_star.svg" />
    <img src="/Icons/rating_star.svg" />
    <img src="/Icons/rating_star.svg" />
    <img src="/Icons/rating_star.svg" />
    <img src="/Icons/rating_star.svg" /> */}
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                  style={{ marginTop: "10px" }}
                >
                  <Form.Control
                    type="text"
                    value={item.order_product_review.review}
                    placeholder="Write your review"
                    disabled={true}
                  />
                </Form.Group>
              </div>
            ) : (
              <div className="address-pd">
                <h6>Submit Your Review</h6>
                <div>
                  <Rating onClick={handleRating} initialValue={5} />
                  {/* <img src="/Icons/rating_star.svg" />
    <img src="/Icons/rating_star.svg" />
    <img src="/Icons/rating_star.svg" />
    <img src="/Icons/rating_star.svg" />
    <img src="/Icons/rating_star.svg" /> */}
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                  style={{ marginTop: "10px" }}
                >
                  <Form.Control
                    type="text"
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Write your review"
                  />
                </Form.Group>
                <button
                  className="review-submit"
                  onClick={() => OrderReviewSubmit(item.id)}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
          <div className="mobile-order-border hide-in-desktop">
            <div className="mobile-order-details">
              <div className="address-pd">
                <h6>{item.product.name}</h6>
                <ul>
                  <li>
                    <b>Size:</b>{" "}
                    <span style={{ color: "#555" }}> {item.size}</span>
                  </li>
                  <li>
                    <b>Category:</b>{" "}
                    <span style={{ color: "#555" }}>Attar</span>
                  </li>
                  <li>
                    <b>Quantity:</b> <span style={{ color: "#555" }}> </span>
                    {item.quantity} x <span className="rupees-font">₹</span>{" "}
                    <span className="product-price">{item.price}</span>
                  </li>
                  {/* <li >{item.price}</li> */}
                </ul>
              </div>
              <div className="address-pd product-image">
                <img
                  src={BASE_URL + "/storage/" + item.product.image}
                  width={110}
                />
              </div>
            </div>
            {item.order_product_review ? (
              <div className="address-pd">
                <h6>Your Review</h6>
                <div>
                <Rating
                    value={item.order_product_review.rating}
                    initialValue={item.order_product_review.rating}
                    readonly={true}
                  />
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                  style={{ marginTop: "10px" }}
                >
                  <Form.Control
                    type="text"
                    value={item.order_product_review.review}
                    placeholder="Write your review"
                    disabled={true}
                  />
                </Form.Group>
                
              </div>
            ) : (
              <div className="address-pd">
                <h6>Submit Your Review</h6>
                <div>
                  <Rating onClick={handleRating} initialValue={5} />
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                  style={{ marginTop: "10px" }}
                >
                  <Form.Control
                    type="text"
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Write your review"
                  />
                </Form.Group>
                <button
                  className="review-submit"
                  onClick={() => OrderReviewSubmit(item.id)}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box sx={Style} className="white-background cancellation-modal">
          <div
            onClick={() => setShowModal(false)}
            className="pointer flex space-between   border-bottom"
          >
            {" "}
            <div className="cancel-order">Cancel Order</div>{" "}
            <CloseIcon
              sx={{ marginBottom: "5px", height: "25px", width: "25px" }}
            />{" "}
          </div>
          <Form.Select
            defaultValue={0}
            className="mt-2 input"
            size="md"
            onChange={(e) => setReasonCategory(e.target.value)}
          >
            <option value="0">Select Reason for Cancellation </option>
            {cancelReason.map((item) => (
              <option value={item.id}> {item.title}</option>
            ))}
          </Form.Select>

          <Form.Group className="mt-2" controlId="messageForm.ControlTextarea1">
            <Form.Control
              onChange={(e) => setDetailedReason(e.target.value)}
              placeholder="Enter Full Detail"
              as="textarea"
              rows={3}
            />
          </Form.Group>
          <Form.Group className="mt-2" controlId="messageForm.ControlTextarea1">
            <Form.Control
              type="file"
              multiple
              onChange={handleImageChange}
              placeholder="Enter Full Detail"
            />
          </Form.Group>
          <div className="mt-2 italic">
            Note: All payment refunds will be done on the same source account
          </div>
          <div className="mt-2 border-top"></div>
          <div className="mt-1 text-right">
            <Button
              variant="contained"
              className="hover"
              sx={{ fontSize: 12 }}
              onClick={() => OrderCancel(orderid)}
            >
              Sumbit
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DashOrderDetail;
