import React, { useState,useEffect } from 'react';
import { Button, Checkbox } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import FormControlLabel from '@mui/material/FormControlLabel';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import style from '../../../styles/login.module.css';
import { Link, useNavigate } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import { BASE_URL } from '../../../config';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Login = () => {
    console.log(window.location.pathname);
  let machineId = localStorage.getItem('MachineId');
  let { slug } = useParams();
  const navigate = useNavigate();
  const [urlMsg, setUrlMsg] = useState();
  const [trueCon, setTrueCon] = useState();

  useEffect(()=>{
    axios.post(`${BASE_URL}/api/email-verification`,{'token':slug}).then((res)=>{
      if (res.data.status === true) {
        setTrueCon(res.data.message)
      }
    }).catch((error)=>{
      if (error.response.data.status === false) {
        setUrlMsg(error.response.data.message)
      }
      if (error.response.data.errors) {
        setUrlMsg(error.response.data.errors.token)
      }
    })
  },[])


    return (
        <>
            <div className={`col-md-12 col-xs-12 col-sm-12 center ${style.container}`}>
                <div className={`card ${style.card}`} >
                  <div className='ptb-100'>
                {trueCon ?
                    <div className={`verify-success-text`}>
                 
                    Your email Id is successfully verified, please <span className='verify-link-text' onClick={() => navigate('/login')}>click here</span> to login to your Dashboard
                      
                    </div> 
                    : <div className='verify-success-text'>
                      {urlMsg} <br />
                      <span className='verify-link-text' onClick={() => navigate('/login')}>click here</span> to login to your Dashboard
                      
                      </div>}
  
                      </div>
                </div>
            </div>
        </>
    )
}

export default Login;