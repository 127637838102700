import { RotatingLines } from "react-loader-spinner";
const Loader = () => {

    return (
        <div style={{ marginLeft: "50%", marginTop: "15%" }}>
            <RotatingLines
                height="80"
                width="80"
                radius="9"
                color="#B12926"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
            />
        </div>


    );
}
export default Loader;