import React,{useEffect, useState} from 'react';
import style from '../../../styles/footerlinks.module.css';
import axios from 'axios';
import { BASE_URL } from '../../../config';

// const policies = [
//     {
//         id: '1',
//         data: 'This article introduces what payment method is applied to pay invoices in the subscribe'
//     },
//     {
//         id: '1',
//         data: 'This article introduces what payment method is applied to pay invoices in the subscribe'
//     },
//     {
//         id: '1',
//         data: 'This article introduces what payment method is applied to pay invoices in the subscribe'
//     },
//     {
//         id: '1',
//         data: 'This article introduces what payment method is applied to pay invoices in the subscribe'
//     },
//     {
//         id: '1',
//         data: 'This article introduces what payment method is applied to pay invoices in the subscribe'
//     }
// ]

function PrivacyPolicy() {

    const [policy, setPolicy] = useState([])

    useEffect(() => {
      fetchListing();
    },[])
  
    const fetchListing = async() => {
      await axios.get(BASE_URL+'/api/policy/privacy_policy').then(({data}) => {
        setPolicy(data.data)
      })
    }


    return (
        <div>
            <div className='col-md-12 col-xs-12 nopadding'>
                <nav aria-label="breadcrumb" >
                    <ol className='breadcrumb'>
                    <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
                        <li className="breadcrumb-item-active" aria-current="page">{policy.title && policy.title}</li>
                    </ol>
                </nav>
            </div>
            <div className={style.div}>
            <h1 className={style.heading}>{policy.title && policy.title} </h1> <br />
            <div className="terms-condition-content" dangerouslySetInnerHTML={{__html: policy.content && policy.content}}></div>
               
            </div>
        </div>
    )
}

export default PrivacyPolicy