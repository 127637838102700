
import '../../styles/blogpage.css'

import { BASE_URL } from '../../config';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import style from '../../styles/Sidebar.module.css';
import { Nav } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from "../../App";
// drawer
import { RiMenu3Fill } from "react-icons/ri";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

function Dashboardheader() {
    const [headerData, setHeaderData] = useState('')
    useEffect(() => {
        featchHeaderData();

    }, [])
    const featchHeaderData = async () => {
        await axios.get(BASE_URL + '/api/header-settings').then(({ data }) => {
            setHeaderData(data.data);
        })
    }

    const [tabValue, setTabValue] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to toggle sidebar visibility
    const { setorderdetailop, setorderdetailop1 } = useContext(UserContext);

    const navigate = useNavigate();

    const handleTabsChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function Logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("userdata");
        navigate('/');
    }

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
    };


    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };
    
      const list = (anchor) => (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <List>
          <div className='ispen-togle'>
                <div className={`col-md-12 col-xs-12 nopadding ${style.container}`}>
                    <div className="col-md-12 col-xs-12 col-sm-12 nopadding">
                        <div className="card">
                            <Link className="sidebar-links" to="/dashboard" onClick={() => setorderdetailop(false)}>
                                <i className="fa fa-tachometer" aria-hidden="true" style={{ marginRight: '10px' }}></i> Dashboard
                            </Link>
                            <Link className="sidebar-links" to="/my-orders" onClick={() => setorderdetailop1(false)}>
                                <i className="fa fa-first-order" aria-hidden="true" style={{ marginRight: '10px' }}></i> My Orders
                            </Link>
                            <Link className="sidebar-links" to="/my-wishList">
                                <i className="fa fa-heart" aria-hidden="true" style={{ marginRight: '10px' }}></i> My Wish List
                            </Link>
                            <Link className="sidebar-links" to="/my-address">
                                <i className="fa fa-book" aria-hidden="true" style={{ marginRight: '10px' }}></i> My Address Book
                            </Link>
                            <Link className="sidebar-links" to="/change-password">
                                <i className="fa fa-key" aria-hidden="true" style={{ marginRight: '10px' }}></i> Change Password
                            </Link>
                            <Link className="sidebar-links" to="/settings">
                                <i className="fa fa-cog" aria-hidden="true" style={{ marginRight: '10px' }}></i> My Account Setting
                            </Link>
                            <Nav.Link className="sidebar-links" onClick={() => Logout()}>
                                <i className="fa fa-arrow-circle-left" aria-hidden="true" style={{ marginRight: '10px' }}></i> Logout
                            </Nav.Link>
                        </div>
                    </div>
                </div>
                </div>
          </List>
         
        </Box>
      );
    
    return (
        <div className='dashboardheader'>


            <img src={BASE_URL + '/storage/' + headerData.header_logo} alt="IZHARSONS_logo" />

            <div className='togle-menu-item'>
                <Link to={'/'}>
                <p className='m-0'>Go to website</p>
                </Link>
            </div>
              {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><RiMenu3Fill style={{fontSize:"26px", color:"gray"}}/></Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}

           
          
        </div>
    )
}

export default Dashboardheader;