import React, { useState, useEffect } from 'react';
import style from '../../../styles/Changepassword.module.css';
import axios from "axios";
import { BASE_URL } from "../../../config";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from '../../Sidebar/Sidebar';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import { Button } from 'react-bootstrap';

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  fontFamily: 'montserrat',
  p: 3,
};

export default function Password() {

  const [oldpassword, setOldPassword] = useState('');
  const [newpassword, setNewPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmnewpassword, setNewconfirmPassword] = useState('');
  const [error, setError] = useState([]);
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const path = window.location.origin;
  const user = JSON.parse(localStorage.getItem("userdata"))
  console.log(user)

  function handleSubmit(e) {
    e.preventDefault();
    setError('');
    const data = { 'old_password': oldpassword, 'new_password': newpassword, 'new_password_confirmation': confirmnewpassword };
    axios.post(BASE_URL + '/api/update-password', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      if (data.status === "success") {
        setError('')
        localStorage.setItem('token', data.token)
        localStorage.setItem('userdata', JSON.stringify(data.data))
        Swal.fire({
          text: data.message,
          icon: "success"
        })
        localStorage.removeItem("token");
        localStorage.removeItem("userdata");
        navigate("/")

        document.getElementById("changepassword").reset();
      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data.message)
      if (response.status_code === "422") {
        setError(response.data.message)
      }
    });
  }

  function handleForgotPassword(e) {

    e.preventDefault();
    setError('');
    const data = {
      'email': user.email,
      'url': path + '/reset-password/'
    }

    axios.post(BASE_URL + '/api/forget-password', data).then(({ data }) => {
      if (data.status === true) {
        setShowModal(false)
        setError('')
        Swal.fire({
          text: data.meassge,
          icon: "success"
        })
      }
      if (data.success === false) {
        setError('')
        Swal.fire({
          text: data.errors.email,
          icon: "success"
        })
      }
    }).catch(({ response }) => {
      setError(response.data.message)
      if (response.data.code == 422) {
        Swal.fire({
          text: 'hdgvu'
        })
        setError(response.data.message)
      }
    });
  }


  return (
    <div className={`col-md-12 col-xs-12 nopadding`}>
      <div className='col-md-12 col-xs-12 nopadding'>
        <nav aria-label="breadcrumb" >
          <ol className='breadcrumb'>
            <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Home</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li className="breadcrumb-item-active" aria-current="page">
              Change Password
            </li>
          </ol>
        </nav>
      </div>
      <div className={`col-md-3 col-xs-12 nopadding`}>
        <Sidebar />
      </div>
      <div className={`col-md-9 col-xs-12 col-sm-12 ${style.container}`} >
        <div className={`col-md-12 col-xs-12 nopadding ${style.header}`}>
          <h3 className={style.heading}>
            Change Password
          </h3>
        </div>
        <div className={`col-md-12 col-xs-12 nopadding`}>
          <div className={`col-md-4 col-xs-12 nopadding-mobile`}>
            <input placeholder='Enter your current password' className="input" onChange={(e) => setOldPassword(e.target.value)} />
          </div>
          <div className='col-md-4 col-xs-12 mobile-mt-1 nopadding-mobile'>
            <input placeholder='Enter New Password' className="input" onChange={(e) => setNewPassword(e.target.value)} />
            
          </div>
          <div className='col-md-4 col-xs-12 mobile-mt-1 nopadding-mobile'>
            <input placeholder='Confirm New Password' className="input" onChange={(e) => setNewconfirmPassword(e.target.value)} />
           
          </div>
          <button className={`hover ${style.button} nomargin-left `} onClick={handleSubmit}>Update Password</button>
        </div>
        <div className={`col-md-12 col-xs-6 ${style.update}`}>
        </div>
      </div>
      <div>
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Box sx={styles}>
            <h4 className=''>Enter your email</h4>
            <input placeholder='Enter your email' value={user.email} />
            <div className='mt-2' style={{ textAlign: 'right' }}>
              <Button onClick={handleForgotPassword}>Send Link</Button>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  )
}