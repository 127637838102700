import React, {useState, useEffect} from 'react';
import style from '../../../styles/footerlinks.module.css';
import axios from 'axios';
import { BASE_URL } from '../../../config';

function TermsAndConditions(){
  const[condition, setCondition] = useState('')
  useEffect(() => {
    fetchTemsCondition();
  },[])

  const fetchTemsCondition = async() => {
    await axios.get(BASE_URL+'/api/policy/terms_and_condition').then(({data}) => {
      setCondition(data.data)
    })
  }

    return(
        <div>
            <div className='col-md-12 col-xs-12 nopadding'>
            <nav aria-label="breadcrumb" >
          <ol className='breadcrumb'>
          <li class="breadcrumb-item  breadcrumb-padding"><a href="#"><span>Shop</span></a><img src="/Icons/SigleSideArrow.png" className="breadcrumb-icon" /> </li>
            <li className="breadcrumb-item-active" aria-current="page">Terms of Use</li>
          </ol>
        </nav>
                </div>
                <br />
        <div className={style.padding30}>
            <h1 className={style.heading}>TERMS & CONDITIONS </h1> <br />
            <div className="terms-condition-content" dangerouslySetInnerHTML={{__html:condition.content && condition.content}}></div>
        </div>
        </div>
    )
}

export default TermsAndConditions;